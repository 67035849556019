import React from "react";
import { Helmet } from 'react-helmet';
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
// import contact22 from "../../images/LandingPageImage/contact_us_1x.webp";
import contact22 from "../../images/Contact22.png";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";

import signup from "../../images/signup.svg";

const Contact = ({ onLoginClick }) => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.anlook.com/contact"
    },
    "name": "Contact Anlook",
    "description": "Have questions about Anlook's WhatsApp automation tool? Contact our support team for assistance.",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+91-9958853167",
        "contactType": "Customer Support"
      },
      {
        "@type": "ContactPoint",
        "email": "hello@anlook.com",
        "contactType": "General Inquiries"
      },
      {
        "@type": "ContactPoint",
        "email": "support@anlook.com",
        "contactType": "Technical Support"
      }
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Ithum Tower, Tower-B, 218, Second Floor, Sector 62",
      "addressLocality": "Noida",
      "postalCode": "201309",
      "addressCountry": "IN"
    }
    
  };

  return (
    <>
      <Helmet>
        <title>Contact Anlook - Get in Touch for WhatsApp Automation Support</title>
        <meta name="description" content="Have questions about Anlook's WhatsApp automation tool? Contact our support team for assistance. We're here to help with any inquiries about our features, pricing, or technical support. Reach out to Anlook today!" />
        <meta name="keywords" content="Contact Anlook, Anlook support, WhatsApp automation support, Anlook technical assistance, Pricing policy anlook, Anlook help, Get in touch, customer support anlook" />
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      
      <div className="bg-green-removed">
        <LandingNav onLoginClick={onLoginClick} />
        <div className="row col-12 padding-5-rem">
          <div className="col-12 col-lg-6 d-flex align-items-center pe-lg-5 pe-0 pt-3 pt-lg-0">
            <div>
              <div className="text-2-2 text-center">
                Get in touch!
              </div>
              <div className="pb-1 text-center text-text-2 pt-4">
                To contact us, simply click on the WhatsApp button located at the bottom right of any page on our website. Alternatively, you can also reach out to us via email.
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-end align-items-center mt-4">
            <img src={contact22} alt=""
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover"
              }}
            />
          </div>
        </div>

        {/* Second Section */}
        <div className="d-flex justify-content-center">
          <div className="row col-12">
            <div className="col-12 col-lg-6 bg-dark-green py-5">
              <div className="padding-5-rem pb-4">
                <div className="text-2-2 text-white pb-2">
                  Our Offices
                </div>
                <p>
                  Ithum Tower, Tower-B, 218, Second Floor, Sector 62 Noida-201309
                </p>
              </div>
              <div className="padding-5-rem">
                <div className="text-2-2 text-white pb-2">
                  Contact Us
                </div>
                <p>
                  +91-9899880056
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6 bg-brown py-5">
              <div className="padding-5-rem pb-4">
                <div className="text-2-2 pb-2">
                  For general inquiries:
                </div>
                <p>
                  hello@anlook.com
                </p>
              </div>
              <div className="padding-5-rem">
                <div className="text-2-2 pb-2">
                  For Support queries:
                </div>
                <p>
                  support@anlook.com
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Third Section */}
        <div className="d-flex justify-content-center bg-light-brown py-5">
          <div className="row col-12 responsive-container shadow bg-white border-redius-20">
            <div className="col-12 col-lg-7">
              <div className="p-4">
                <div className="text-5-2">Supercharge your ad investments,</div>
                <div className="text-5-2">
                  Turn clicks into engaging conversations!
                </div>
                <div className="py-2">
                  Unlock Quality Leads, Nurture, and Engage with Post-Click WhatsApp Interactions!
                </div>
                <div className="pt-4">
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="shadow btn p-2 px-4 me-5 text-white d-flex align-items-center"
                    // onClick={onLoginClick}
                  >
                    Talk to an Expert
                    <img src={signup} className="ps-2" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
              <img src={callImage} alt="" className="image-cover" />
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
}

export default Contact;
