// src/analytics.js
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-30F3TSBYM1');
  };
  
  export const logPageView = (url) => {
    window.gtag('config', 'G-30F3TSBYM1', {
      page_path: url,
    });
  };