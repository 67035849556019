import React, { useState, useEffect } from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import { Link } from "react-router-dom";

import {
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Table,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";

const ProfileDashboard = ({ profiles }) => {
  const [power, setPower] = useState("");
  const [haveSubscriptionPlan, setHaveSubscriptionPlan] = useState(false);

  const [userData, setUserData] = useState({});
 
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [userID, setUserID] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [organizationName, setOrganizationName] = useState("N/A");
  const [includeGST, setIncludeGST] = useState(false);
  const [gst, setGst] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("RAZORPAY");
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [tagLine1, setTagLine1] = useState("");
  const [tagLine2, setTagLine2] = useState("");
  const [watermark1, setWatermark1] = useState("");
  const [watermark2, setWatermark2] = useState("");
  const [gstnum, setGstNum] = useState("");

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [reason, setReason] = useState("");

  const [step, setStep] = useState(1); // Step state for tracking the current screen

  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);

  const subscriptionDetailsApi = async (userid) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example Authorization
        },
      };
      const responseBody = {
        userid: userid,
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Entitlement/fetchSubscriptionDetails",
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        setSubscriptionData(response.data.data);
        setHaveSubscriptionPlan(true);
      } else {
        console.error(
          "Failed to fetch subscription details:",
          response.data.responseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Entitlement/cancelSubscriptionPlan",
        {
          userid: userID,
          reason: reason,
        },
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setReason("");
      } else {
        toast.error(response.data.responseMessage);
        setReason("");
      }
      setShowCancelModal(false); // Close the modal after successful API call
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("An error occurred while canceling the subscription.");
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Get the Base64 string from the reader result
      const base64String = reader.result;

      console.log("Base64 Encoded Image: ", base64String);

      // If you need to display the image, you can use this Base64 string
      // For example, set it to the state and use it in the img src
      setImageLink(base64String); // Assuming setImageLink is your state setter for the image URL
    };

    if (file) {
      reader.readAsDataURL(file); // This will read the file and convert it to Base64
    }
  };

  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchOrganizationDetails = async (userid) => {
    try {
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/User/getOrganizationDetails",
        {
          userid: userid,
        }
      );

      if (response.data.responseCode === "00") {
        const details = response.data.response;
        setOrganizationDetails(details);

        // Pre-fill modal fields with fetched data
        setOrganizationName(details.organization_name);
        setIncludeGST(!!details.gst);
        setGst(details.gst);
        setGstNumber(details.gst);
        setBusinessAddress(details.business_address || "");
        setPaymentGateway(details.payment_gateway);
        setImageLink(details.brand_image);
      } else {
        console.error("Failed to fetch organization details");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };
  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    await updateOrganizationInfo(); // Call your update function here
  };

  const updateOrganizationInfo = async () => {
    if (!imageLink) {
      console.error("Image URL is not set yet!");
      return;
    }

    try {
      const formData = {
        userid: userID,
        organizationName: organizationName,
        gst: gstNumber,
        pan_no: "",
        brand_image: imageLink,
        payment_gateway: paymentGateway,
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/User/editOrganizationInfo",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success(
          response.data.response.message ||
            "Organization info updated successfully!"
        );
      } else {
        toast.error("Failed to update organization info");
      }

      console.log("Organization info updated successfully", response.data);
    } catch (error) {
      toast.error("There was an error updating the organization info!");
      console.error(
        "There was an error updating the organization info!",
        error
      );
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserID(parsedUser.userid);
      if (parsedUser.userid) {
        verifyCredentials(parsedUser.userid);
        fetchOrganizationDetails(parsedUser.userid);
        subscriptionDetailsApi(parsedUser.userid);
        fetchReceiptDetails(parsedUser.userid);
        GetBankInfo(parsedUser.userid);
      }
    }
  }, []);
  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Copied to clipboard: ", text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    const storedPower = sessionStorage.getItem("power");
    const user = sessionStorage.getItem("user");

    if (storedPower) {
      setPower(storedPower);
    }
  }, []);

  const verifyCredentials = async (userId) => {
    try {
      const response = await fetch(
        "https://anlookuat.remittpe.com/api/Whatsapp/getWhatsappCredentials",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.responseCode === "00") {
        setUserData(responseData.response);
      } else if (responseData.responseCode === "01") {
        setUserData({});
      } else {
        console.error("Unexpected response code:", responseData);
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  const handleUpdateReceiptClick = () => {
    setShowReceiptModal(true);
  };

  const handleSaveReceiptChanges = async () => {
    setShowReceiptModal(false);
    await updateReceiptInfo();
  };
  const updateReceiptInfo = async () => {
    try {
      const formData = {
        userid: userID,
        address: address,
        phone_no: phoneNo,
        tagLine1: tagLine1,
        tagLine2: tagLine2,
        watermark1: watermark1,
        watermark2: watermark2,
        gst: gst,
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Order/editReceiptData",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success("Receipt info updated successfully!");
      } else {
        toast.error("Failed to update receipt info");
      }
    } catch (error) {
      toast.error("Error updating receipt info!");
      console.error("Error updating receipt info:", error);
    }
  };
  const [Account, setAccount] = useState("");
  const [Ifsc, setIfsc] = useState("");
  const [BankName, setBankName] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [BankInfo, setBankInfo] = useState([]);

  const updateBankInfo = async () => {
    try {
      const BankData = {
        userid: userID,
        account: Account,
        ifsc: Ifsc,
        bankName: BankName,
        bankHolderName: AccountHolderName,
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Transactions/addSettlementAccount",
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setStateNull();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error updating bank info!");
      console.error("Error updating bank info:", error);
    }
  };
  function setStateNull() {
    setBankName("");
    setAccountHolderName("");
    setAccount("");
    setIfsc("");
  }
  const GetBankInfo = async (userID) => {
    try {
      const BankData = {
        userid: userID,
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Transactions/getSettlementAccountList",
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        setBankInfo(response.data.response);
        console.log(BankInfo);
      } else {
        // toast.error(response.data.responseMessage);
      }
    } catch (error) {
      // toast.error("Error getting bank info!");
      console.error("Error bank info:", error);
    }
  };

  // bank Modal state controler
  const [showBankModal, setShowBankModal] = useState(false);
  // Function to handle saving changes
  const handleSaveBankChanges = () => {
    setShowBankModal(false);
    updateBankInfo();
  };

  const fetchReceiptDetails = async (userID) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        userid: userID,
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Order/getReceiptData",
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        const details = response.data.response[0]; // Fetch the first item from the response array
        setReceiptDetails(details);

        // Pre-fill modal fields with fetched data
        setAddress(details.address);
        setPhoneNo(details.phone_no);
        setTagLine1(details.tag_line1); // Correct key from the response
        setTagLine2(details.tag_line2); // Correct key from the response
        setWatermark1(details.watermark1);
        setWatermark2(details.watermark2);
        setGstNum(details.gst); // Correct key from the response
      } else {
        console.error("Failed to fetch receipt details");
      }
    } catch (error) {
      console.error("Error fetching receipt details:", error);
    }
  };

  return (
    <>
      <NoIndex />
      <ToastContainer />
      <div
        className="p-4 px-5 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito, sans-serif",
        }}
      >
        <div className="profile-dashboard">
          <div className="d-flex justify-content-between">
            <h2 className="CreateCampaign">Your profile</h2>
            {/* <div>
              <Link to="/Profile/Setup">
                <Button className="btn bg-danger  border-none text-white">
                  Complete Setup
                </Button>
              </Link>
            </div> */}
          </div>
          {organizationDetails ? (
            <div className="profile-card bg-light">
              <div className="profile-info p-3">
                <div className="profile-avatar">
                  {organizationDetails.brand_image ? (
                    <img
                      src={organizationDetails.brand_image}
                      alt="Brand Logo"
                      style={{ height: "80px" }}
                      height={100}
                    />
                  ) : (
                    <span>AN</span>
                  )}
                </div>
                <div className="profile-details">
                  <h3>{profiles?.username}</h3>
                  <p className="role">Owner</p>
                  <p className="merchant-id">
                    Number ID
                    <br />
                    {userData.phone_no_id}{" "}
                    <span
                      className="icon"
                      onClick={() => copyText(userData.phone_no_id)}
                    >
                      📋
                    </span>
                  </p>
                </div>
              </div>
              <div className="bg-white"></div>
              <div className="profile-contact bg-white p-3 border-15-only">
                <div className="contact-item">
                  <span className="label">App ID</span>
                  <span>{userData.app_id}</span>
                  <button
                    onClick={handleUpdateClick}
                    className="ms-4 blue-button"
                  >
                

                    Update
                  </button>
                </div>
                <div className="contact-item">
                  <span className="label">Login email</span>
                  <span>{profiles?.email}</span>
                </div>
                <div className="contact-item">
                  <span className="label">Payment Gateway</span>
                  <span>{organizationDetails.payment_gateway || "N/A"}</span>
                </div>
                <div className="contact-item">
                  <span className="label">Business Name</span>
                  <span>{organizationDetails.organization_name || "N/A"}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="settings-grid my-3">
            <div className="bg-light p-4 border rounded  d-flex flex-column">
              <div className="p-3 text-center">
              <div className="text-3-2">
                Please provide your business information.
              </div>
              <div>
                <button
                  className="btn btn-primary mt-3"
                  onClick={handleUpdateClick}
                >
                  Provide Information
                </button>
              </div>
              </div>
            </div>
            </div>
          )}

          <h3 className="section-title">Account and product settings</h3>

          <div className="settings-grid">
            <div className="bg-light p-4 border rounded h-100 d-flex flex-column">
           

              {receiptDetails ? (
                <>
                   <p className="campaignheading p-0 m-0">Receipt Information</p>
              <p className="campaignSubheading">
                This information will appear on the customer's receipt.
              </p>
                  <p className="campaignBoxName">
                    Address
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.address || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    Phone Number
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.phone_no || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    Tagline 1
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.tag_line1 || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    Tagline 2
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.tag_line2 || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    Watermark 1
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.watermark1 || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    Watermark 2
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.watermark2 || "N/A"}
                      readOnly
                    />
                  </p>

                  <p className="campaignBoxName">
                    GST
                    <input
                      type="text"
                      className="form-control"
                      value={receiptDetails.gst || "N/A"}
                      readOnly
                    />
                  </p>

                  <div className="py-2 d-flex justify-content-center ">
                    <Button
                      className="blue-button text-white px-5"
                      onClick={handleUpdateReceiptClick}
                    >
                      Update
                    </Button>
                  </div>
                </>
              ) : (
                <div className="text-center p-3">
                    <p>
                      <span className="text-dark text-3-2">
                        {" "}
                        Add Receipt Information{" "}
                      </span>
                    </p>
                  <p>
                    Please provide information to display on the receipt and
                    invoice.
                  </p>
                  <Button
                    className="blue-button text-white "
                    onClick={handleUpdateReceiptClick}
                  >
                    Add Receipt Information
                  </Button>
                </div>
              )}
            </div>

            {/* Modal for updating receipt information */}
            <Modal
              show={showReceiptModal}
              onHide={() => setShowReceiptModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Receipt Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="campaignBoxName">
                  Address
                  <input
                    type="text"
                    className="form-control"
                    value={address || ""} // Ensure state is set correctly
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Phone Number
                  <input
                    type="text"
                    className="form-control"
                    value={phoneNo || ""}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Tagline 1
                  <input
                    type="text"
                    className="form-control"
                    value={tagLine1 || ""}
                    onChange={(e) => setTagLine1(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Tagline 2
                  <input
                    type="text"
                    className="form-control"
                    value={tagLine2 || ""}
                    onChange={(e) => setTagLine2(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Watermark 1
                  <input
                    type="text"
                    className="form-control"
                    value={watermark1 || ""}
                    onChange={(e) => setWatermark1(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Watermark 2
                  <input
                    type="text"
                    className="form-control"
                    value={watermark2 || ""}
                    onChange={(e) => setWatermark2(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  GST
                  <input
                    type="text"
                    className="form-control"
                    value={gstnum || ""}
                    onChange={(e) => setGstNum(e.target.value)}
                  />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowReceiptModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSaveReceiptChanges}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showCancelModal}
              onHide={() => setShowCancelModal(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {step === 1 && "Cancel Subscription"}
                  {step === 2 && "How Can We Help?"}
                  {step === 3 && "Cancellation Reason"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {step === 1 && (
                  <div>
                    <p>Are you sure you want to cancel your subscription?</p>
                  </div>
                )}

                {step === 2 && (
                  <div>
                    <p>
                      What can we do to meet your needs? If you require a
                      customized service, please email us at{" "}
                      <strong>Support@anlook.com</strong>.
                    </p>
                  </div>
                )}

                {step === 3 && (
                  <Form>
                    <Form.Group controlId="cancelReason">
                      <Form.Label>Reason for Cancellation</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                )}

                {step === 4 && (
                  <div>
                    <p>
                      We are sad to see you go. Thank you for using our service.
                    </p>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {step === 1 && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => setShowCancelModal(false)}
                    >
                      No
                    </Button>
                    <Button variant="danger" onClick={handleNext}>
                      Yes
                    </Button>
                  </>
                )}

                {step === 2 && (
                  <>
                    <Button variant="secondary" onClick={handleBack}>
                      Back
                    </Button>
                    <Button variant="primary" onClick={handleNext}>
                      Next
                    </Button>
                  </>
                )}

                {step === 3 && (
                  <>
                    <Button variant="secondary" onClick={handleBack}>
                      Back
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleNext}
                      disabled={reason.trim().length === 0}
                    >
                      Next
                    </Button>
                  </>
                )}

                {step === 4 && (
                  <>
                    <Button variant="secondary" onClick={handleBack}>
                      Back
                    </Button>
                    <Button variant="danger" onClick={handleCancelSubscription}>
                      Confirm Cancellation
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <div
              className="settings-card"
              style={{ backgroundColor: "#F8F9FA" }}
            >
              <div className="d-flex flex-column">
                {subscriptionData ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="campaignheading p-0 m-0">
                        Current Plan Details
                      </p>
                      <Link to="/Profile/Plans">
                        <Button className="btn bg-green-filter text-white">
                          Upgrade Plan
                        </Button>
                      </Link>
                    </div>
                    <hr />

                    <div className="d-flex justify-content-between">
                      <p className=" p-1 m-0 text-3-2">Plan Name</p>
                      <p className=" p-0 m-0">
                        {subscriptionData.fk_plan_id || (
                          <span className="text-3-2 text-danger">
                            {" "}
                            No Active Plan
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className=" p-1 m-0">Start Date</p>
                      <p className=" p-0 m-0">
                        {subscriptionData.start_date || (
                          <span className=" text-dark"></span>
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between p-0 m-0">
                      <p className=" p-1 m-0">End Date</p>
                      <p className=" p-0 m-0">
                        {subscriptionData.end_date || (
                          <span className=" text-dark"></span>
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className=" p-1 m-0">Billing Cycle</p>
                      <p className=" p-0 m-0">
                        {subscriptionData.billing_cycle || (
                          <span className=" text-dark"></span>
                        )}
                      </p>
                    </div>

                    <div className="bg-white rounded p-3  mt-3">
                      <div className="d-flex justify-content-between">
                        <p>Purchased On</p>
                        <p>
                          {subscriptionData.purchased_on || (
                            <span className=" text-dark"></span>
                          )}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Remaining Days</p>
                        <p>
                          {subscriptionData.remaining_days || (
                            <span className=" text-danger"></span>
                          )}
                        </p>
                      </div>
                    </div>

                    {subscriptionData.fk_plan_id && (
                      <div className="d-flex justify-content-end mt-3">
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => setShowCancelModal(true)}
                        >
                          Cancel Subscription
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center p-3">
                    <p>
                      <span className="text-danger text-3-2">
                        {" "}
                        No plan is activated.{" "}
                      </span>
                    </p>
                    <p> Please buy a suitable plan for your business needs.</p>
                    <Link to="/Profile/Plans">
                      <Button className="btn bg-green-filter text-white">
                        Browse Plans
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="settings-card bg-light">
              <div className="d-flex flex-column">
               

                {BankInfo && BankInfo.length > 0 ? (
                  <>
                   <p className="campaignheading p-0 m-0">Bank Information</p>
                <p className="campaignSubheading">
                  Below is the information associated with your bank accounts.
                </p>
                    {/* Bank Info Table */}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Bank Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BankInfo.map((info, index) => (
                          <tr key={info.id}>
                            <td>{info.us_account}</td>
                            <td>{info.bank_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="py-2 d-flex justify-content-center">
                      <Button
                        className="blue-button text-white "
                        onClick={() => setShowBankModal(true)}
                      >
                        Update Bank Info
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="text-center p-3">
                   <p>
                      <span className="text-dark text-3-2">
                        {" "}
                        Add Bank Details{" "}
                      </span>
                    </p>
                    <p>
                      Order payments will be settled in the provided account.
                    </p>
                    <Button
                      className="blue-button text-white "
                      onClick={() => setShowBankModal(true)}
                    >
                      Add Bank Info
                    </Button>
                  </div>
                )}

                {/* Modal for updating bank details */}
                <Modal
                  show={showBankModal}
                  onHide={() => setShowBankModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Update Bank Information</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="campaignBoxName">
                      Account Number
                      <input
                        type="text"
                        className="form-control"
                        value={Account}
                        onChange={(e) => setAccount(e.target.value)}
                      />
                    </p>

                    <p className="campaignBoxName">
                      IFSC Code
                      <input
                        type="text"
                        className="form-control"
                        value={Ifsc}
                        onChange={(e) => setIfsc(e.target.value)}
                      />
                    </p>

                    <p className="campaignBoxName">
                      Bank Name
                      <input
                        type="text"
                        className="form-control"
                        value={BankName}
                        onChange={(e) => setBankName(e.target.value)}
                      />
                    </p>

                    <p className="campaignBoxName">
                      Account Holder Name
                      <input
                        type="text"
                        className="form-control"
                        value={AccountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                      />
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowBankModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSaveBankChanges}>
                      Add Account
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Update Business Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="campaignBoxName">
                Business Name
                <input
                  type="text"
                  className="form-control"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </p>

              <p className="campaign-box-name d-flex align-items-center">
                <span className="me-2">Include GST:</span>
                <ToggleButtonGroup
                  type="radio"
                  name="includeGST"
                  value={includeGST}
                  onChange={(val) => setIncludeGST(val)}
                  className="toggle-group"
                >
                  <ToggleButton
                    id="gst-yes"
                    value={true}
                    variant="outline-success"
                    size="sm"
                  >
                    YES
                  </ToggleButton>
                  <ToggleButton
                    id="gst-no"
                    value={false}
                    variant="outline-danger"
                    size="sm"
                  >
                    NO
                  </ToggleButton>
                </ToggleButtonGroup>
              </p>

              {includeGST && (
                <p className="campaignBoxName">
                  GST Rate
                  <input
                    type="number"
                    className="form-control"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                  />
                </p>
              )}

              <p className="campaignBoxName">
                GST Number (Optional)
                <input
                  type="text"
                  className="form-control"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                />
              </p>

              <p className="campaignBoxName">
                Payment Gateway
                <select
                  className="form-control"
                  value={paymentGateway}
                  onChange={(e) => setPaymentGateway(e.target.value)}
                >
                  <option value="RAZORPAY"> Razorpay</option>
                  <option value="PAYPAL"> Paypal</option>
                  <option value="BUMPPYPAY"> Bumppy Pay</option>
                </select>
              </p>

              <p className="campaignBoxName">
                Business Logo
                <input
                  type="file"
                  className="form-control"
                  onChange={handleLogoUpload}
                />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
