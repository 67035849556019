import React from "react";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const dummyData = {
  clientName: "Arthum Corporation",
  campaignsRunning: 5,
  ordersReceived: 1234,
  catalogItems: 78,
  revenue: 45678,
  activeUsers: 5678,
  conversionRate: 3.45,
  monthlyOrders: [650, 730, 810, 920, 1050, 1234],
  categoryDistribution: [30, 25, 20, 15, 10],
  campaignPerformance: [
    { name: "Campaign A", conversions: 250 },
    { name: "Campaign B", conversions: 180 },
    { name: "Campaign C", conversions: 320 },
    { name: "Campaign D", conversions: 90 },
    { name: "Campaign E", conversions: 140 },
  ],
  userAcquisition: [120, 200, 150, 80],
  productPerformance: [
    { name: "Product A", sales: 450 },
    { name: "Product B", sales: 300 },
    { name: "Product C", sales: 200 },
    { name: "Product D", sales: 100 },
  ],
};

const ClientAnalyticsDashboard = () => {
  const monthlyOrdersData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Monthly Orders",
        data: dummyData.monthlyOrders,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const categoryDistributionData = {
    labels: [
      "Category A",
      "Category B",
      "Category C",
      "Category D",
      "Category E",
    ],
    datasets: [
      {
        data: dummyData.categoryDistribution,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
        ],
      },
    ],
  };

  const campaignPerformanceData = {
    labels: dummyData.campaignPerformance.map((campaign) => campaign.name),
    datasets: [
      {
        label: "Conversions",
        data: dummyData.campaignPerformance.map(
          (campaign) => campaign.conversions
        ),
        backgroundColor: "rgba(75, 192, 192, 0.8)",
      },
    ],
  };

  const userAcquisitionData = {
    labels: ["Organic", "Paid", "Referral", "Social"],
    datasets: [
      {
        data: dummyData.userAcquisition,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
        ],
      },
    ],
  };

  const productPerformanceData = {
    labels: dummyData.productPerformance.map((product) => product.name),
    datasets: [
      {
        label: "Sales",
        data: dummyData.productPerformance.map((product) => product.sales),
        backgroundColor: "rgba(153, 102, 255, 0.8)",
      },
    ],
  };

  return (
    <div
      className="dashboard px-4 NavGap bg-white"
   
    >
      <p className="CreateCampaign mt-4 mb-4">
        {dummyData.clientName} - Analytics Dashboard
      </p>

      <div className=" border rounded-4 mb-4">
        <p className="CreateCampaign p-4">Overview</p>

        <div className="d-flex flex-wrap g-3 mb-4 col-12">
          <div className="card h-100 col-2 bg-warning text-white m-4">
            <div className="card-body">
              <h5 className="card-title">Campaigns Running</h5>
              <p className="card-text display-6">
                {dummyData.campaignsRunning}
              </p>
            </div>
          </div>

          <div className="card h-100 col-2 bg-info text-white m-4">
            <div className="card-body">
              <h5 className="card-title">Orders Received</h5>
              <p className="card-text display-6">{dummyData.ordersReceived}</p>
            </div>
          </div>

          <div className="card h-100 col-2 bg-danger text-white m-4">
            <div className="card-body">
              <h5 className="card-title">Catalog Items</h5>
              <p className="card-text display-6">{dummyData.catalogItems}</p>
            </div>
          </div>

          <div className="card h-100 col-2 bg-success text-white m-4 ">
            <div className="card-body">
              <h5 className="card-title">Revenue</h5>
              <p className="card-text display-6">${dummyData.revenue}</p>
            </div>
          </div>

          <div className="card h-100 col-2 bg-primary text-white m-4">
            <div className="card-body">
              <h5 className="card-title">Active Users</h5>
              <p className="card-text display-6">{dummyData.activeUsers}</p>
            </div>
          </div>

          <div className="card h-100 col-2 bg-secondary text-white m-4">
            <div className="card-body">
              <h5 className="card-title">Conversion Rate</h5>
              <p className="card-text display-6">{dummyData.conversionRate}%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border  rounded-4">
      <p className="CreateCampaign p-4">Analytics</p>
        <div className="d-flex  flex-wrap g-4">
          <div className="card h-100 col-3 m-4" style={{boxShadow:"none"}}>
            <div className="card-body">
              <h5 className="card-title">Recent Activity</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  New campaign "Summer Sale" launched
                </li>
                <li className="list-group-item">
                  Product "Widget X" restocked
                </li>
                <li className="list-group-item">50 new user signups today</li>
                <li className="list-group-item">
                  Revenue target for Q2 achieved
                </li>
              </ul>
            </div>
          </div>
          <div className="card h-100 col-3 m-4" style={{boxShadow:"none"}}>
            <div className="card-body">
              <h5 className="card-title">Monthly Orders</h5>
              <Line data={monthlyOrdersData} />
            </div>
          </div>
          <div className="card h-100 col-3  m-4" style={{boxShadow:"none"}}>
            <div className="card-body">
              <h5 className="card-title">Campaign Performance</h5>
              <Bar data={campaignPerformanceData} />
            </div>
          </div>

          <div className="card h-100 col-3  m-4" style={{boxShadow:"none"}}>
            <div className="card-body">
              <h5 className="card-title">Category Distribution</h5>
              <Doughnut data={categoryDistributionData} />
            </div>
          </div>

          <div className="card h-100 col-3  m-4" style={{boxShadow:"none"}}>
            <div className="card-body">
              <h5 className="card-title">User Acquisition</h5>
              <Pie data={userAcquisitionData} />
            </div>
          </div>

          <div className="card h-100 col-3  m-4">
            <div className="card-body">
              <h5 className="card-title">Product Performance</h5>
              <Bar data={productPerformanceData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAnalyticsDashboard;
