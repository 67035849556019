import { ToastContainer } from "react-toastify";

import ss1 from "../../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import ss2 from "../../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../../images/LandingPageImage/web_inbox_team_1x.webp";

import signup from "../../../images/signup.svg";

const SecondSectionFeatures = () => {
  return (
    <>
      <ToastContainer />

      <div className="d-flex justify-content-center bg-green-removed padding-5-rem py-5 ">
        <div>
          <div className="text-center pt-2 text-2-2">
            <h1>Features</h1>
          </div>
          <div className="text-center text-text-2 pt-4 ">
            WhatsApp campaigns and intuitive chatbots enhance your engagements
            and target acquisition.
          </div>

          <div className="text-center py-2 text-text-2 mb-5">
            Efficiently manage support inquiries and updates through a unified
            inbox and seamless integrations.
          </div>

          {/* First Feature Section */}
          <div className="row col-12 py-lg-5 py-2">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="pe-5">
                <div className="text-3-2-large  pt-5 pt-lg-0">Broadcast Engagement</div>
                <div className="pb-2 pt-4">
                  • Maximize your outreach and boost interaction with compelling
                  WhatsApp campaigns.
                </div>
                <div className="py-2">
                  • Seamlessly tag, categorize, and group contacts for tailored
                  messaging strategies.
                </div>
                <div className="py-2">
                  • Cultivate and maintain valuable connections that pave the
                  way for future sales through consistent engagement on
                  WhatsApp.
                </div>
              </div>
              <div className="py-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 text-white d-flex align-items-center"
                >
                  Talk to an Expert
                  <img
                    loading="lazy"
                    src={signup}
                    className="ps-2"
                    alt="Create campaigns for WhatsApp automation with Anlook"
                  />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center position-relative">
              <img
                loading="lazy"
                src={ss1}
                alt="Anlook's automation features: efficiency, reliability, and innovation"
                width={250}
                className="w-100"
              />
              <div className="circle-background-blue"></div> {/* Circular background */}
            </div>
          </div>

          {/* Second Feature Section */}
          <div className="row col-12 py-0 py-lg-5 my-5">
            <div className="col-12 col-lg-6 order-1 order-lg-1 d-flex justify-content-center position-relative">
              <img
                loading="lazy"
                src={ss2}
                alt=""
                width={250}
                className="w-100"
              />
              <div className="circle-background-yellow"></div> {/* Circular background */}
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              <div className="ps-lg-5 ps-0">
                <div className="text-3-2-large  pt-5 pt-lg-0">Chatbots</div>
                <div className="pb-2 pt-4">
                  • Leverage no-code chatbots for swift responses to frequent
                  queries.
                </div>
                <div className="py-2">
                  • Streamline personalized communication and mass messaging
                  with automated solutions.
                </div>
                <div className="py-2">
                  • Drive sales by efficiently converting customer interactions
                  into opportunities on WhatsApp.
                </div>
              </div>
              <div className="py-4 ps-0 ps-lg-5">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 me-5 text-white d-flex align-items-center"
                >
                  Talk to an Expert
                  <img loading="lazy" src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
          </div>

          {/* Third Feature Section */}
          <div className="row col-12 py-lg-5 py-2 mt-5">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="pe-5">
                <div className="text-3-2-large  pt-5 pt-lg-0">
                  Collaborative Inbox for Teams
                </div>
                <div className="pb-2 pt-4">
                  • Share the Anlook inbox with your team for outstanding
                  WhatsApp support.
                </div>
                <div className="py-2">
                  • Utilize pre-built integrations to access customer context
                  effortlessly.
                </div>
                <div className="py-2">
                  • Efficiently manage post-sales communications and swiftly
                  resolve support inquiries via WhatsApp.
                </div>
              </div>
              <div className="py-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 me-5 text-white"
                >
                  Talk to an Expert
                  <img loading="lazy" src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center position-relative">
              <img
                loading="lazy"
                src={ss3}
                alt="Anlook is the most trusted name in WhatsApp automation."
                width={250}
                className="w-100"
              />
              <div className="circle-background-green"></div> {/* Circular background */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSectionFeatures;
