import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl } from "react-bootstrap";
import trash from "../../images/Delete.svg";
import Edit from "../../images/Edit.svg";
import plus from "../../images/add-circle.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserManagement({ userID }) {
  const [loading, setLoading] = useState(false);
  // invite user States#################################################
  const [inviteUserName, setInviteUserName] = useState("");
  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [AssignedRole, setAssignedRole] = useState("");
  const [Role, setRole] = useState("");
  const [modalStep, setmodalStep] = useState(1);
  const [isPArtialOn, setIsPArtialOn] = useState(false);
  const [isFullOn, setIsFullOn] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [activeState, setactiveState] = useState("");

  const [ModalData, setModalData] = useState("");
  const [deleteState, setdeleteState] = useState("");
  const [editState, seteditState] = useState("");
  const [editModal, seteditModal] = useState(false);

  const [People, setPeople] = useState([]);

  // fetching the All user using the get all user api.
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getAllUserList = async (e) => {
    try {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/User/getAllUserList`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setPeople(data?.response);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    getAllUserList();
  }, []);

  const NewInvite = {
    userid: userID,
    username: inviteUserName,
    email: inviteUserEmail,
    mobile: PhoneNumber,
    role: AssignedRole,
  };

  const InviteNewUser = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      const raw = JSON.stringify(NewInvite);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://anlookuat.remittpe.com/api/User/inviteUser`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage + "🚀");
      } else {
        toast.error(result.responseMessage);
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getRole = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/User/getUserRoles`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setRole(data?.response);
        console.log(data);
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getRole();
  }, []);

  const ChangeStatus = {
    userid: editState,
    adminId: userID,
    status: activeState,
  };

  const ChangeUserStatus = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      const raw = JSON.stringify(ChangeStatus);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://anlookuat.remittpe.com/api/User/changeUserStatus`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage);
        getAllUserList();
      } else {
        toast.error(result.responseMessage);
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const togglePartial = () => {
    if (!isFullOn) {
      setIsPArtialOn(!isPArtialOn);
    } else {
      setIsFullOn(false);
      setIsPArtialOn(true);
    }
  };
  const toggleFull = () => {
    if (!isPArtialOn) {
      setIsFullOn(!isFullOn);
    } else {
      setIsFullOn(true);
      setIsPArtialOn(false);
    }
  };

  function toggleActive() {
    if (isActive) {
      setIsActive(false);
      setactiveState("N");
    } else if (!isActive) {
      setIsActive(true);
      setactiveState("Y");
    }
  }

  function setState() {
    setInviteUserName("");
    setInviteUserEmail("");
    setPhoneNumber("");
    setAssignedRole("");
  }

  const switchStyles = {
    width: "50px",
    height: "25px",
    border: "2px solid #004d40", // Adjust the color as needed
    borderRadius: "25px",
    backgroundColor: "rgba(252, 255, 253, 1)", // Adjust the color as needed
    position: "relative",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };
  const toggleStylesActive = {
    width: "21px",
    height: "21px",
    backgroundColor: isActive ? "#4caf50" : "rgba(12, 77, 69, 1)    ", // Adjust the color as needed
    borderRadius: "50%",
    position: "absolute",
    // top: "1px",
    left: isActive ? "25px" : "0px", // Adjust according to the switch's width
    transition: "left 0.3s ease",
  };

  const toggleStylesfull = {
    width: "21px",
    height: "21px",
    backgroundColor: isFullOn ? "#4caf50" : "rgba(12, 77, 69, 1)    ", // Adjust the color as needed
    borderRadius: "50%",
    position: "absolute",
    // top: "1px",
    left: isFullOn ? "25px" : "0px", // Adjust according to the switch's width
    transition: "left 0.3s ease",
  };
  const toggleStylespartial = {
    width: "21px",
    height: "21px",
    backgroundColor: isPArtialOn ? "#4caf50" : "rgba(12, 77, 69, 1)    ", // Adjust the color as needed
    borderRadius: "50%",
    position: "absolute",
    // top: "1px",
    left: isPArtialOn ? "25px" : "0px", // Adjust according to the switch's width
    transition: "left 0.3s ease",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thTdStyle = {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  };

  function handleModal(a, b) {
    setModalData(a);
    setdeleteState(b);
  }
  function handleDelete(item) {
    setdeleteState(item);
    toast.error("user deleted successfully");
  }

  const DeleteUser = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: ModalData,
        adminId: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/User/deleteUser`,
        requestOptions
      );

      const data = await res.json();
      if (deleteState !== "") {
        toast.success(data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      getAllUserList();
      setLoading(false); // Set loading to false after API call
    }
  };

  function handleEditModal(a, b, c) {
    seteditModal(b);
    seteditState(a);
    if (c == "ACTIVE") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <>
      {" "}
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>
      <div
        className="p-4 bg-white"
        style={{ fontFamily: "Nunito, sans-serif" }}
      >
        <div className="bg-white px-4" style={{ borderRadius: "10px" }}>
          <Form className="d-flex justify-content-between ">
            <Form.Label className="CreateCampaign p-0 m-0">
              Invite People
            </Form.Label>{" "}
            <button
              // style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
              className="btncampaign1   text-white"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#InviteModal"
              onClick={() => setmodalStep(1)}
            >
              <img src={plus} alt="" className="pe-1" height={20} /> Invite
            </button>
          </Form>
          {loading ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {People ? (
                <>
                  <div className="tab-content p-0 py-2" id="myTabContent">
                    {/* New Order */}
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div
                        style={{
                          border: "1px solid #dddddd",
                          borderRadius: "10px",
                        }}
                      >
                        <table
                          className="col-12 table-hover  "
                          style={(tableStyle, { borderRadius: "10px" })}
                        >
                          <thead>
                            <tr>
                              <th className="  text-center" style={thTdStyle}>
                                Person Name
                              </th>
                              <th className="text-center" style={thTdStyle}>
                                Email
                              </th>
                              <th className=" text-center" style={thTdStyle}>
                                Mobile No.
                              </th>
                              <th className=" text-center" style={thTdStyle}>
                                Status
                              </th>

                              <th className=" text-center" style={thTdStyle}>
                                Access
                              </th>
                              {/* <th
                              className="text-center col-1 p-3"
                              style={thTdStyle}
                            >
                              Total Price
                            </th> */}
                              <th className="text-center " style={thTdStyle}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Dummy Data for the table */}
                            {People.map((item, index) => (
                              <React.Fragment key={1}>
                                <tr>
                                  <td
                                    className=" align-middle text-center text-order "
                                    style={thTdStyle}
                                  >
                                    {item.user_name}
                                  </td>

                                  <td
                                    className=" align-middle text-center text-order"
                                    style={thTdStyle}
                                  >
                                    {item.user_email}
                                  </td>
                                  <td
                                    className=" align-middle text-center text-order"
                                    style={thTdStyle}
                                  >
                                    {item.user_mobile}
                                  </td>
                                  <td
                                    className=" align-middle text-center text-order"
                                    style={thTdStyle}
                                  >
                                    {item.user_status === "ACTIVE" ? (
                                      <span
                                        class="badge"
                                        style={{
                                          color: "rgba(1, 195, 125, 1)",
                                          backgroundColor:
                                            "rgba(1, 195, 125, 0.2)",
                                        }}
                                      >
                                        Active
                                      </span>
                                    ) : (
                                      <span
                                        class="badge"
                                        style={{
                                          color: "#ED5A48",

                                          backgroundColor:
                                            " rgba(237, 90, 72, 0.2)",
                                        }}
                                      >
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td
                                    className=" align-middle text-center text-order"
                                    style={thTdStyle}
                                  >
                                    {item.control === "full" ? (
                                      <>Full Control</>
                                    ) : (
                                      <>Partial Control</>
                                    )}
                                  </td>

                                  <td
                                    className=" d-flex justify-content-between "
                                    style={thTdStyle}
                                  >
                                    <div
                                      type="div"
                                      className="cursor-pointer"
                                      // data-bs-toggle="tooltip"
                                      data-bs-toggle="modal"
                                      data-bs-target="#EditModal"
                                      data-bs-placement="top"
                                      title="Edit"
                                      onClick={() =>
                                        handleEditModal(
                                          item.userid,
                                          item.user_name,
                                          item.user_status
                                        )
                                      }
                                    >
                                      <img src={Edit} value={1} alt="Logo" />
                                    </div>
                                    <div
                                      className="cursor-pointer"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal1"
                                      // data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Delete"
                                      onClick={() => {
                                        handleModal(
                                          item.userid,
                                          item.user_name
                                        );
                                      }}
                                    >
                                      <img src={trash} alt="Logo" />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>No User found.</div>
              )}
            </>
          )}
        </div>
        {/* modal for invite button  */}

        {modalStep === 1 ? (
          <div
            class="modal fade"
            id="InviteModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ color: "rgba(12, 77, 69, 1)" }}
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h1
                    class="ModalHeading modal-title fs-5"
                    id="exampleModalLabel"
                    style={{ fontWeight: "solid" }}
                  >
                    Invite People
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setState()}
                  ></button>
                </div>
                <div class="modal-body">
                  <div
                    className="ModalSubheading"
                    style={{ color: " #000000" }}
                  >
                    Invite new members to your business by entering their email
                    or phone number. Customize their access permissions and
                    select the Accounts and tools they can use.
                  </div>
                  <div className="my-3">
                    <p className="ModalOption p-0 m-0 pt-1">UserName</p>
                    <input
                      type="Text"
                      className="form-control"
                      id="contactEmail"
                      name="contactEmail"
                      value={inviteUserName}
                      onChange={(e) => setInviteUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <p className="ModalOption p-0 m-0 pt-1">Email</p>
                    <input
                      type="email"
                      className="form-control"
                      id="contactEmail"
                      name="contactEmail"
                      value={inviteUserEmail}
                      onChange={(e) => setInviteUserEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="my-3">
                    <p className="ModalOption p-0 m-0 pt-1">Phone Number</p>
                    <input
                      type="text"
                      className="form-control"
                      id="contactNumber"
                      name="contactNumber"
                      value={PhoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    className="ModalSubheading my-3"
                    style={{ color: " #000000" }}
                  >
                    Choose the tasks that <b>{inviteUserName}</b> needs to
                    perform within the Chaafo business portfolio.
                  </div>
                  <div>
                    <p className="ModalOption p-0 m-0">Select Role</p>
                    <Form.Select
                      defaultValue=""
                      onChange={(e) => setAssignedRole(e.target.value)}
                    >
                      {Role
                        ? Role.map((Item, index) => (
                            <option key={index} value={Item.role_title}>
                              {Item.role_title}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                  </div>

                  <div className="my-3">
                    <div className="d-flex justify-content-between">
                      <p className="ModalOption p-0 m-0 pt-1">Partial Access</p>
                      <div
                        style={switchStyles}
                        value={isPArtialOn}
                        onClick={togglePartial}
                      >
                        <div style={toggleStylespartial}></div>
                      </div>
                    </div>
                    <p
                      className="ModalDescription p-0 m-0 "
                      style={{ color: " rgba(12, 27, 77, 0.6)" }}
                    >
                      Partial control access typically grants limited
                      permissions, allowing users to perform specific tasks
                      within defined parameters.
                    </p>
                  </div>
                  <div className="my-2">
                    <div className="d-flex justify-content-between">
                      <p className="ModalOption p-0 m-0 pt-1 ">Full Access</p>
                      <div
                        style={switchStyles}
                        value={isFullOn}
                        onClick={toggleFull}
                      >
                        <div style={toggleStylesfull}></div>
                      </div>
                    </div>{" "}
                    <p
                      className="ModalDescription p-0 m-0"
                      style={{ color: " rgba(12, 27, 77, 0.6)" }}
                    >
                      Full control access provides unrestricted authority,
                      enabling users to modify, create, and delete without
                      limitations.
                    </p>
                  </div>
                </div>

                <div class="modal-footer justify-content-end">
                  <div>
                    {" "}
                    <button
                      className="btncampaign "
                      type="button"
                      data-bs-dismiss="modal"
                      onClick={() => setState()}
                      style={{
                        backgroundColor: "rgba(1, 195, 125, 0.05)",
                        color: "rgba(1, 195, 125, 1)",
                        border: "1px solid rgba(1, 195, 125, 1)",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btncampaign1 ms-3"
                      onClick={() => InviteNewUser()}
                      style={{
                        Color: "rgba(1, 195, 125, 0.05)",
                        backgroundColor: "rgba(1, 195, 125, 1)",
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Edit Access modal */}
        <div
          class="modal fade"
          id="EditModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ color: "rgba(12, 77, 69, 1)" }}
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1
                  class=" ModalHeading modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ fontWeight: "solid" }}
                >
                  Edit user Access
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="ModalSubheading text-dark">
                  Manage Access for <b>{editModal}</b>
                </div>
                <div className="my-2">
                  <div className="d-flex justify-content-between">
                    <p className="ModalOption pt-1 ">Active Status</p>
                    <div
                      style={switchStyles}
                      value={isActive}
                      onClick={toggleActive}
                    >
                      <div style={toggleStylesActive}></div>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  <p className="ModalOption ">Change Role</p>
                  <Form.Select
                    defaultValue=""
                    onChange={(e) => setAssignedRole(e.target.value)}
                  >
                    {Role
                      ? Role.map((Item, index) => (
                          <option key={index} value={Item.role_title}>
                            {Item.role_title}
                          </option>
                        ))
                      : ""}
                  </Form.Select>
                </div>
                <div className="my-3">
                  <div className="d-flex justify-content-between">
                    <p className="ModalOption p-0 m-0 pt-1">Partial Access</p>
                    <div
                      style={switchStyles}
                      value={isPArtialOn}
                      onClick={togglePartial}
                    >
                      <div style={toggleStylespartial}></div>
                    </div>
                  </div>
                  <p
                    className="ModalDescription p-0 m-0 "
                    style={{ color: " rgba(12, 27, 77, 0.6)" }}
                  >
                    Partial control access typically grants limited permissions,
                    allowing users to perform specific tasks within defined
                    parameters.
                  </p>
                </div>
                <div className="my-2">
                  <div className="d-flex justify-content-between">
                    <p className="ModalOption p-0 m-0 pt-1 ">Full Access</p>
                    <div
                      style={switchStyles}
                      value={isFullOn}
                      onClick={toggleFull}
                    >
                      <div style={toggleStylesfull}></div>
                    </div>
                  </div>{" "}
                  <p
                    className="ModalDescription p-0 m-0"
                    style={{ color: " rgba(12, 27, 77, 0.6)" }}
                  >
                    Full control access provides unrestricted authority,
                    enabling users to modify, create, and delete without
                    limitations.
                  </p>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  className="btncampaign "
                  type="button"
                  data-bs-dismiss="modal"
                  style={{
                    backgroundColor: "rgba(1, 195, 125, 0.05)",
                    color: "rgba(1, 195, 125, 1)",
                    border: "1px solid rgba(1, 195, 125, 1)",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btncampaign1 ms-3"
                  style={{
                    Color: "rgba(1, 195, 125, 0.05)",
                    backgroundColor: "rgba(1, 195, 125, 1)",
                  }}
                  onClick={() => ChangeUserStatus()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* delete access modal */}
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1
                  class="modal-title fs-5 text-success"
                  id="exampleModalLabel"
                >
                  {deleteState}
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete this item? This action cannot be
                undone.
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="liveAlertBtn"
                  type="button"
                  class="btn btn-danger"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal2"
                  data-bs-dismiss="modal"
                  onClick={() => DeleteUser()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagement;