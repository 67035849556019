import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NoIndex from "../NoIndex";
import * as XLSX from 'xlsx';
import { json, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, Button, Form } from 'react-bootstrap';
const LeadDetails = ({userID}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(8).fill(false));
  const [filter, setFilter] = useState('Interested'); // State to manage the current filter
  const [campaignData, setCampaignData] = useState([]);
  const [campaignResponseData, setCampaignResponseData] = useState([]);
  const location = useLocation();
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState({});
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [showModal, setShowModal] = useState(false);
  const [audienceGroupName, setAudienceGroupName] = useState("");
  const [templateId, setTemplateId] = useState(''); // New useState for template_id

  useEffect(() => {
    // Extract campaign ID from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get('LeadDetails');

    // Fetch the data from the API using axios
    const fetchCampaignData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${BASIC_AUTH}`,
        };
    
        const body = {
          userid: userID,
          campaignName: "string",
        };
    
        const response = await axios.post(
          'https://anlookuat.remittpe.com/api/Campaign/getCampaigns',
          body,
          { headers }
        );

        if (response.data.responseCode === '00' && response.data.data) {
          // Filter the campaign using the campaign ID
          const campaign = response.data.data.find(item => item.id === campaignId);
          if (campaign) {
            setCampaignData(campaign);
            setTemplateId(campaign.template_id); // Set template_id in state
          }
        }
      } catch (error) {
        console.error('Error fetching campaign data:', error);
      }
    };

    fetchCampaignData();
  }, [location]);

  // const campaignData = {
  //   name: 'Anlook Launch',
  //   id: 'Camp_001',
  //   channel: 'Whatsapp',
  //   startDate: '6/19/2024 1:05:00 PM',
  //   endDate: '6/19/2024 1:05:00 PM',
  //   intervalTime: '01',
  //   template: 'Temp_Launch'
  // };

  const stats = [
    { title: 'Sent', value: 250, bgColor: '#EBF0FF', textColor: '#4B9BE3' },
    { title: 'Delivered', value: 200, bgColor: '#F2ECFD', textColor: '#9376E4' },
    { title: 'Read', value: 180, bgColor: '#EDFDF5', textColor: '#67C680' },
    { title: 'Replied', value: 90, bgColor: '#FFF4E1', textColor: '#FC8A02' },
    { title: 'Failed', value: 50, bgColor: '#FFECEC', textColor: '#FB3C4F' }
  ];

  const interestedData = [
    { id: 1, contactId: '1025', name: 'Sanjay Saxena', number: '9856798567' },
    { id: 2, contactId: '1025', name: 'Deepesh Solanki', number: '9856798566' },
    { id: 3, contactId: '1025', name: 'Ramesh Mittal', number: '9856798563' },
    // Add more entries as needed
  ];

  const websiteVisitedData = [
    { id: 4, contactId: '1026', name: 'Kapil Rawat', number: '9856798568' },
    { id: 5, contactId: '1027', name: 'Ramesh Mittal', number: '9856798567' },
    { id: 6, contactId: '1028', name: 'Shamsher Kaushik', number: '9856798562' },
    // Add more entries as needed
  ];

  const noResponseData = [
    { id: 7, contactId: '1029', name: 'Dilip Rajvanshi', number: '9856798567' },
    { id: 8, contactId: '1030', name: 'Dheeru Bhai Ambani', number: '9856798565' },
    // Add more entries as needed
  ];

  const handleAllChecked = () => {
    setAllChecked(!allChecked);
    setCheckedState(new Array(getCurrentData().length).fill(!allChecked));
  };

  const handleIndividualCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    setAllChecked(updatedCheckedState.every(Boolean));
  };



  useEffect(() => {
 
    const fetchCampaignAnalyticsData = async () => {
      try {
        // setLoading(true);

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${BASIC_AUTH}`,
        };
        
        const body = {
          userid: userID,
           secret: "string",

           metaTemplateId: templateId,
        };
    
        const response = await axios.post(
          'https://anlookuat.remittpe.com/api/Campaign/getCampaignAnalyticsById',
          body,
          { headers }
        );

        if (response.data.responseCode === '00' && response.data.data) {
          // Filter the campaign using the campaign ID
          setCampaignAnalyticsData( response.data.data);
        }
      } catch (error) {
        console.error('Error fetching campaign data:', error);
      }
    };

    fetchCampaignAnalyticsData();
 
  }, [templateId]);

  const [filteredData,setFilteredData]=useState([])

  const getCurrentData = (status) => {

    if (status === 'Interested') {
      setFilteredData([]);
      const newData=campaignResponseData.filter(item => item.status === 'Interested');
      setFilteredData(newData);
      return ;
    }
    if (status === 'read') {
      setFilteredData([]);
      const newData=campaignResponseData.filter(item => item.status === 'read');
      setFilteredData(newData);
      return ;
    }
    if (status === 'sent') {
      setFilteredData([]);
      const newData=campaignResponseData.filter(item => item.status === 'sent');
      setFilteredData(newData);
      return ;

    }
    return [];
  };



  const responseListApi = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get('LeadDetails');
    try {
      // setLoading(true);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${BASIC_AUTH}`,
      };
      
      const body = {
        "userid": userID,
        "campaignId":campaignId ,
        "status": [
          "read", "sent","interested"
        ]
      };
  
      const response = await axios.post(
        'https://anlookuat.remittpe.com/api/LeadManagement/getLeadsByCampaign',
        body,
        { headers }
      );

      if (response.data.responseCode === '00' && response.data.data) {
        setCampaignResponseData( response.data.data);
        const newData=response.data.data.filter(item => item.status === 'Interested');
        setFilteredData(newData);
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error);
    }
  };

useEffect(()=>{
  responseListApi();
},[]);




const createAudienceGroup=async()=>{

  try{
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
      },
    };

    const tranformedMembers=filteredData.map((member)=>({
      id: member.id,
      contact_name: member.contact_name,
      contact_number: member.contact

    }))
      const requestBody={
        "userid": userID,
        "audienceGroupName": audienceGroupName,
        "members":tranformedMembers,
      }

      const response=await axios.post("https://anlookuat.remittpe.com/api/LeadManagement/createAudienceGroup",requestBody,config);
      if (response.data.responseCode === "00") {
        toast.success(response.data.data.message);
        setShowModal(false); // Close modal on success
      } else {
        toast.error(response.data.data.message);
      }
    } catch (error) {
      toast.error("Failed to create audience group");
    }


}

const handleDownloadData = () => {
  const data = filteredData.map((audience) => ({
    Date: audience.indate,
    'Audience Name': audience.contact_name || "NA",
    'Contact Number': audience.contact,
  
  }));

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Audience Data');

  XLSX.writeFile(workbook, 'audience_data.xlsx');
};


  return (
    <div className="p-4 bg-white" style={{ fontFamily: "Nunito, sans-serif" }}>
        <NoIndex/>
      <div className='px-4'>
    
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Audience Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupName">
              <Form.Label>Audience Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter audience group name"
                value={audienceGroupName}
                onChange={(e) => setAudienceGroupName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button 
            variant="primary" 
            onClick={createAudienceGroup} 
            disabled={!audienceGroupName.trim()}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
        <div className="CreateCampaign pb-3">Overview</div>
        <div className='border-15-only p-2' style={{ border: "1px solid #ddd" }}>
          <div className="lead-details-container">
            <div className="campaign-details bg-light">
              <h3>Campaign Name: {campaignData.campaign_name}</h3>
              <div className="details-grid">
                <div><strong>Campaign ID:</strong> {campaignData.id}</div>
                <div><strong>Channel:</strong> {campaignData.channel}</div>
                <div><strong>Start Date:</strong> {campaignData.campaign_start}</div>
                <div><strong>End Date:</strong> {campaignData.campaign_end}</div>
                <div><strong>Interval Time:</strong> {campaignData.campaign_interval}</div>
                <div><strong>Template In Use:</strong> {campaignData.template_id}</div>
              </div>
            </div>
              
  <div className="lead-management-overview">
         
        
         <div className="overview-box" style={{ backgroundColor:'#EBF0FF' , color:'#4B9BE3'  }}>
           <div className="title">
           Total Sent
           </div>
           <div className="value">{campaignAnalyticsData.totalSent || 0}</div>
         </div>
         <div className="overview-box" style={{ backgroundColor:'#F2ECFD' , color: '#9376E4'  }}>
           <div className="title">
           Total Delivered
           </div>
           <div className="value">{campaignAnalyticsData.totalDelivered || 0}</div>
         </div>
         <div className="overview-box" style={{ backgroundColor:'#EDFDF5' , color: '#67C680' }}>
           <div className="title">
           Total Read
           </div>
           <div className="value">{campaignAnalyticsData.totalRead || 0}</div>
         </div>
         <div className="overview-box" style={{ backgroundColor: '#FFF4E1', color: '#FC8A02'   }}>
           <div className="title">
           Total Replied
           </div>
           <div className="value">{campaignAnalyticsData.totalReplied || 0}</div>
         </div>
         <div className="overview-box" style={{ backgroundColor: '#FFECEC' , color: '#FB3C4F' }}>
           <div className="title">
           Total Failed
           </div>
           <div className="value">{campaignAnalyticsData.totalFailed || 0}</div>
         </div>
        

     </div>
            {/* <div className="statistics">
              {stats.map((stat, index) => (
                <div key={index} className="stat-box" style={{ backgroundColor: stat.bgColor, color: stat.textColor }}>
                  <div className="stat-title">
                    {stat.title} <span className="info-icon">ℹ️</span>
                  </div>
                  <div className="stat-value">{stat.value}</div>
                </div>
              ))}
            </div> */}
            <hr  className='mt-0'/>
            <div className='pt-3'>
            <ul className="nav">
                  <li className="nav-item">
                    <button
                      // className="nav-link active"
                      className={`nav-link ${
                        filter === "Interested"
                          ? "active active-underline "
                          : "gray-underline"
                      }`}
                      id="Interested"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                      style={{
                        color: "#01C37D",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setFilter('Interested');
                        getCurrentData("Interested");
                      }}
                    >
                      Interested{" "}
                    
                    </button>
                  </li>
                  <li className="nav-item" style={{ color: "black" }}>
                    <button
                      className={`nav-link ${
                        filter === "read"
                          ? "active active-underline"
                          : "gray-underline"
                      }`}
                      id="websiteVisited"
                      data-bs-toggle="tab"
                      data-bs-target="#order-history-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="order-history-tab-pane"
                      aria-selected="false"
                      style={{
                        color: "#01C37D",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setFilter('read');
                        getCurrentData("read");
                      }}
                    >
                    Read
                     
                    </button>
                  </li>
                  <li className="nav-item" style={{ color: "black" }}>
                    <button
                      className={`nav-link ${
                        filter === "noResponse"
                          ? "active active-underline"
                          : "gray-underline"
                      }`}
                      id="noResponse"
                      data-bs-toggle="tab"
                      data-bs-target="#order-history-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="order-history-tab-pane"
                      aria-selected="false"
                      style={{
                        color: "#01C37D",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setFilter('noResponse');
                        getCurrentData("noResponse");
                      }}
                    >
                     No Response
                      
                    </button>
                  </li>
                </ul>
            </div>
          
            <div className="text-3-2 border-15-only p-3 my-3 audience-table-container text-center bg-light" >
  {filter === 'Interested' && (
    <>
    <div className='text-5-2 '>Interested</div>
     <div className='text-dark'> (Audience who have shown interest and clicked the call-to-action button)</div>
    </>
  )}
  {filter === 'read' && (
   
    <>
    <div className='text-5-2 '>Message Read</div>
     <div className='text-dark'> ( Audience who read message )</div>
    </>
  )}
  {filter === 'noResponse' && (
   
     <>
     <div className='text-5-2 '>No Response</div>
      <div className='text-dark'> ( Audience who received the message but did not respond)</div>
     </>
    
  )}
</div>
            <div className="audience-table-container bg-light">
              <table className="audience-table">
                <thead>
                  <tr>
                    {/* <th className='text-center'><input type="checkbox" checked={allChecked} onChange={handleAllChecked} /></th> */}
                    <th className='text-center'>S.No.</th>
                    <th className='text-center'>Date</th>
                    <th className='text-center'>Audience Name</th>
                    <th className='text-center'>Contact Number</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((audience, index) => (
                    <tr key={audience.id}>
                      {/* <td className='text-center'>
                        <input 
                          type="checkbox" 
                          checked={checkedState[index]} 
                          onChange={() => handleIndividualCheckboxChange(index)} 
                        />
                      </td> */}
                      <td className='text-center'>{1+index}</td>
                      <td className='text-center'>{audience.indate}</td>
                      <td className='text-center'>{audience.contact_name ||"NA"}</td>
                      <td className='text-center'>{audience.contact}</td>
                      <td className='text-center'>
                      <div className="d-flex justify-content-center gap-3">
                       
              <button  className={`btn bg-white btn-outline-success text-success`}>
              Assign Salesperson 
              </button>
            
              <button    className={`btn bg-white btn-outline-danger text-danger`}>
                Close
              </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='d-flex justify-content-center gap-3'>
                <button   onClick={handleDownloadData} className="btn btn-outline-primary text-primary bg-white">Download Data</button>
                <button 
               onClick={() => setShowModal(true)}
                className="re-target-button">Create audience group</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDetails;
