import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import "./AdminDashboard.css";
import Home from "../../images/AdminHome.png";
import Home1 from "../../images/Home-green.png";

import staticsIcon from "../../images/Statistics.png";
import staticsIcon1 from "../../images/Statistics-green.png";

import clients from "../../images/Client.png";
import clients1 from "../../images/Client-green.png";

import pricing from "../../images/Pricing.png";
import pricing1 from "../../images/Pricing-green.png";

import payout from "../../images/Payout.png";
import payout1 from "../../images/Payout-green.png";

import Employees from "../../images/Employee.png";
import Employees1 from "../../images/Employee-green.png";

import Logout from "../../images/Logout (1).png";
import Logout1 from "../../images/Logout (1)-green.png";
import "./AdminDashboard.css";

const SideNav = ({ logout }) => {
  const [activeItem, setActiveItem] = useState(() => {
    const savedState = localStorage.getItem("myState");
    return savedState ? JSON.parse(savedState) : "home";
  });

  useEffect(() => {
    localStorage.setItem("myState", JSON.stringify(activeItem));
  }, [activeItem]);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <nav className="side-nav">
      <ul>
        <li
          className={
            activeItem === "Home" ? "merge-link active hide-desktop" : "merge-link hide-desktop"
          }
          onClick={() => handleItemClick("Home")}
        >
          <Link className="nav-link" to="/admin/home">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img src={activeItem === "Home" ? Home1 : Home} alt="" />
              </div>
              Home
            </div>
          </Link>
        </li>
        <li
          className={
            activeItem === "Employee" ? "active merge-link hide-desktop" : "merge-link hide-desktop"
          }
          onClick={() => handleItemClick("Employee")}
        >
          <Link className="nav-link" to="/admin/employee">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img
                  src={activeItem === "Employee" ? Employees1 : Employees}
                  alt=""
                />
              </div>
              Employee
            </div>
          </Link>
        </li>
        <li
          className={
            activeItem === "pricing" ? "active merge-link hide-desktop" : "merge-link hide-desktop"
          }
          onClick={() => handleItemClick("pricing")}
        >
          <Link className="nav-link" to="/admin/pricing">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img src={activeItem === "pricing" ? pricing1 : pricing} alt="" />
              </div>
              Pricing
            </div>
          </Link>
        </li>
        {/* Other links not affected by the hide-desktop class */}
        <li
          className={activeItem === "dashboard" ? "active" : ""}
          onClick={() => handleItemClick("dashboard")}
        >
          <Link className="nav-link" to="/admin/dashboard">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img
                  src={activeItem === "dashboard" ? staticsIcon1 : staticsIcon}
                  alt=""
                />
              </div>
              Dashboard
            </div>
          </Link>
        </li>
        <li
          className={activeItem === "client" ? "active" : ""}
          onClick={() => handleItemClick("client")}
        >
          <Link className="nav-link" to="/admin/clients">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img
                  src={activeItem === "client" ? clients1 : clients}
                  alt=""
                />
              </div>
              Clients
            </div>
          </Link>
        </li>
        <li
          className={activeItem === "onboarding" ? "active" : ""}
          onClick={() => handleItemClick("onboarding")}
        >
          <Link className="nav-link" to="/admin/payout">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img
                  src={activeItem === "onboarding" ? payout1 : payout}
                  alt=""
                />
              </div>
              Payout
            </div>
          </Link>
        </li>
        <li
          className={activeItem === "statistics" ? "active" : ""}
          onClick={logout}
        >
          <Link className="nav-link">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img src={activeItem === "statistics" ? Logout1 : Logout} alt="" />
              </div>
              Logout
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;