import React, { useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import cut from "../../images/close-circle.svg";
import SuccessTick from "../../images/SuccessTick.svg";
import downloadIcon from "../../images/download-02.svg";
import uploadIcon from "../../images/upload-03.svg";


import axios from "axios";

import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from "react-bootstrap";

const ContectBook = ({ userID, fetchData }) => {
  const [contactNumber, setContactNumber] = useState("");

  const [contactEmail, setContactEmail] = useState("");

  const [contactName, setContactName] = useState("");

  const [gender, setGender] = useState("");

  const [result, setResult] = useState(null);

  const [tags, setTags] = useState([""]);

  const [selectedMethod, setSelectedMethod] = useState("1");

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.id);
  };

  const addTag = () => {
    setTags([...tags, ""]); // Add an empty tag
  };

  const handleTagChange = (index, value) => {
    const updatedTags = [...tags];

    updatedTags[index] = value;

    setTags(updatedTags);
  };

  const removeTag = (index) => {
    const updatedTags = [...tags];

    updatedTags.splice(index, 1); // Remove tag at index

    setTags(updatedTags);
  };

  const [errorName, setErrorName] = useState("");

  const [errorMobile, setErrorMobile] = useState("");

  const eraseTags = () => {
    setTags([]);
  };
  const addContectApi = async (event) => {
    event.preventDefault();

    if (!contactName) {
      toast.error("Please enter name.");
      setErrorName("Please enter name.");
      return;
    }
    if (!contactNumber || contactNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number.");
      setErrorMobile("Please enter a valid 10-digit mobile number.");
      return;
    }
    // Define the headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );

    // Define the body
    const raw = JSON.stringify({
      userid: userID,
      contactNumber: contactNumber,
      contactEmail: contactEmail,
      contactName: contactName,
      gender: gender,
      tags: tags,
    });

    // Request options
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      // Fetch data from the API
      const response = await fetch(
        "https://anlookuat.remittpe.com/api/Campaign/addContact",
        requestOptions
      );

      const data = await response.json();
      setResult(data);
      if (data.responseCode === "00") {
        toast.success(data.responseMessage);
        setContactName("");
        setContactNumber("");
        setContactEmail("");
        setGender("");
        eraseTags();
        fetchData();
      } else {
        toast.error(data.responseMessage);
      }
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  // #################

  const [file, setFile] = useState(null);

  const downloadSampleExcel = () => {
    const sampleData = [
      {
        contactNumber: "string",
        contactEmail: "string",
        contactName: "string",
        gender: "string",
        tags: "tag1, tag2, tag3",
      },
    ];

    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contacts");

    XLSX.writeFile(wb, "sample_contacts.xlsx");
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert("Please upload an Excel file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const formattedData = jsonData.map((item) => ({
        userid: userID,
        contactNumber: item.contactNumber,
        contactEmail: item.contactEmail,
        contactName: item.contactName,
        gender: item.gender,
        tags: item.tags ? item.tags.split(",").map((tag) => tag.trim()) : [],
      }));
      console.log(formattedData);
      // Call API to send formattedData
      sendDataToAPI(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const sendDataToAPI = async (data) => {
    try {
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Campaign/uploadBulkContact",
        {
          contacts: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        }
      );
      console.log("Response:", response);
      if (response.status === 200) {
        if (
          response.data.responseCode === "00" &&
          response.data.responseMessage === "0 contacts uploaded successfully"
        ) {
          toast.warning(response.data.responseMessage);
        } else {
          toast.success(response.data.responseMessage);
          fetchData();
        }
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Response error:", error.response);
        toast.error(`Error: ${error.response.data}`);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        toast.error("No response received from the server.");
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        toast.error(`Error: ${error.message}`);
      }
    }
  };
  return (
    <>
      <div className=" d-flex justify-content-center align-items-center">
        <div className="row p-3 bg-white  border-15">
          <div className=" col-12 text-start    ">
            <div className="font-bold pt-2">Add Customer Contacts</div>
            <div className="text-5 py-2">
              Please provide the contact number you wish to add. This will help
              us keep in touch with you for updates and important information.
            </div>
          </div>

          {/* Radio Button */}
          <div className="col-12  d-flex justify-content-start align-items-center">
            <div className="d-flex  align-items-center">
              <input
                name="method"
                id="1"
                type="radio"
                checked={selectedMethod === "1"}
                onChange={handleMethodChange}
              />
              <span className="ps-2">One By One</span>
            </div>

            <div className="ps-4 d-flex  align-items-center">
              <input
                name="method"
                id="2"
                type="radio"
                checked={selectedMethod === "2"}
                onChange={handleMethodChange}
              />
              <span className="ps-2">Bulk Upload</span>
            </div>
          </div>

          <div className="m-0 p-0 pt-3">
            {selectedMethod === "1" && (
              <div className="">
                <Form className="">
                  <div className="row col-12 m-0 p-0">

                    <div className="col-6 ">
                      <FormGroup className=" py-1">
                        <FormLabel style={{ width: "5rem" }}>
                          Name <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                          type="text"
                          className={`${errorName ? "is-invalid" : ""}`}
                          placeholder="Enter contact name. "
                          value={contactName}
                          onChange={(e) => {
                            setContactName(e.target.value);
                            setErrorName("");
                          }}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-6 ">
                      <FormGroup className=" py-1">
                        <FormLabel style={{ width: "5rem" }}>
                          Mobile <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                          type="tel"
                          value={contactNumber}
                          className={`${errorMobile ? "is-invalid" : ""}`}
                          placeholder="Enter mobile number. "
                          onChange={(e) => {
                            const input = e.target.value.replace(/\D/g, ""); // Remove any non-digit characters
                            if (input.length <= 10) {
                              // Check if the input has 10 or fewer digits
                              setContactNumber(input); // Update the state with the sanitized input
                              setErrorMobile("");
                            }
                          }}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-6  ">
                      <FormGroup className=" py-1">
                        <FormLabel style={{ width: "5rem" }}>E-mail</FormLabel>
                        <FormControl
                          type="email"
                          value={contactEmail}
                          placeholder="Enter e-mail id. "
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-6 ">
                      <FormGroup className=" py-1">
                        <FormLabel style={{ width: "5rem" }}>Gender</FormLabel>
                        <Form.Select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          aria-label="Select Gender"
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </FormGroup>
                    </div>

                    <div className="col-12">
                      <FormGroup className=" align-items-center py-1">
                        <FormLabel style={{ width: "5rem" }}>Tags</FormLabel>
                        <div className=" row">
                          <div className="col-6">
                            {tags.map((tag, index) => (
                              <div
                                key={index}
                                className=" d-flex align-items-center mb-1 border-15"
                              >
                                <FormControl
                                  type="text"
                                  value={tag}
                                  placeholder="Enter Tag Name"
                                  style={{ border: "0px solid #000" }}
                                  onChange={(e) =>
                                    handleTagChange(index, e.target.value)
                                  }
                                  onFocus={(e) => {
                                    e.target.style.outline = "none";
                                    e.target.style.boxShadow = "none";
                                  }}
                                />

                                <Button
                                  className=" bg-white border-white"
                                  style={{ outline: "none", boxShadow: "none" }}
                                  onFocus={(e) => {
                                    e.target.style.outline = "none";
                                    e.target.style.boxShadow = "none";
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onClick={() => removeTag(index)}
                                >
                                  <img src={cut} alt="" />
                                </Button>
                              </div>
                            ))}
                          </div>
                          <div className=" col-6 d-flex justify-content-start">
                            <div>
                              <div
                                onClick={addTag}
                                className="gray-button cursor-pointer text-primary"
                              >
                                Add More Tag
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="d-flex justify-content-start pt-3 ">
                      <Button
                        onClick={addContectApi}
                        className="blue-button  text-white px-4"
                      >
                        Save
                      </Button>
                    </div>

                  </div>
                </Form>
              </div>
            )}

            {selectedMethod === "2" && (
              <div className="">
                <div className="p-4">
               

                  <div className="flex-grow-1 d-flex flex-column justify-content-center">
                    <div className=" d-flex justify-content-center upload-section">
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        className="upload-input visually-hidden "
                        id="fileInput"
                      />
                      <label
                        htmlFor="fileInput"
                        className="upload-label cursor-pointer "
                      >
                        {file ? file.name : 
                        
                        <>
                        <div>
                          <img src={uploadIcon} alt=""/>
                        </div>
                        <div className="text-3-2 text-blue">  Click to upload Excel file</div>
                        </>
                      }
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-3">
                                    
                    <div className="text-5">

                    You can download an CSV sample for your reference
                    </div>
                   
                    <div>
                    <button
                      onClick={downloadSampleExcel}
                      className="gray-button text-blue"
                    >
                    <img src={downloadIcon} alt="Downalod Icon"/> Download Sample Excel  
                    </button>
                    </div>
                  </div>

                  <div className="mt-4 d-flex justify-content-start">
                    <button
                      onClick={handleFileUpload}
                      className="blue-button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {selectedMethod === "3" && (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center">
                    <img src={SuccessTick} alt="Success" height={150} />
                    <div className="pt-3 text-3-2-dark">Great!!</div>
                    <div className="pb-3">
                      Your Contact Number has been saved
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContectBook;
