import React from "react";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import { Helmet } from "react-helmet";

const Privacy = ({ onLoginClick }) => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.anlook.com/PrivacyPolicy",
    },
    headline: "Anlook Privacy Policy - Your Data Security is Our Priority",
    description:
      "Read Anlook's privacy policy to understand how we protect your data. Learn about our commitment to data security, user privacy, and transparent practices. Your trust is important to us.",
    author: {
      "@type": "Organization",
      name: "Anlook",
    },
    publisher: {
      "@type": "Organization",
      name: "Anlook",
      logo: {
        "@type": "ImageObject",
        url: "https://anlook.com/.uploads/anlook_logo.png",
        width: 600,
        height: 60,
      },
    },
    datePublished: "2024-07-15T00:00:00Z",
    dateModified: "2024-07-15T00:00:00Z",
  };

  return (
    <>
      <Helmet>
        <title>
          Anlook Privacy Policy - Your Data Security is Our Priority
        </title>
        <meta
          name="description"
          content="Read Anlook's privacy policy to understand how we protect your data. Learn about our commitment to data security, user privacy, and transparent practices. Your trust is important to us."
        />
        <meta
          name="keywords"
          content="Anlook privacy policy, data security, user privacy, Privacy policy, data protection"
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="w-100 bg-green">
        <LandingNav onLoginClick={onLoginClick} />

        <div className="padding-5-rem pt-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="bg-white border-radius-20 px-lg-5 px-3 py-3">
                <p className="text-3-2">Table of Content</p>
                <ul>
                  <li>Information We Collect</li>
                  <li>Consent</li>
                  <li>Disclosure</li>
                  <li>Payment</li>

                  <li>ThirdParty Services</li>

                  <li>Security</li>

                  <li>Cookies</li>
                  <li> Age of Consent</li>

                  <li>Changes to This Privacy Policy</li>

                  <li>Contact Information</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-12 pt-lg-0 pt-3">
              <div className="bg-white border-radius-20 pb-5 ">
                <div className="text-2-2  py-4 px-lg-5 px-3">
                  Privacy Policy
                </div>

                <div className="px-lg-5 px-3">
                  <p>
                    Chaafo Private Limited (“we,” “our,” or “us”) values your
                    privacy and is committed to safeguarding your personal
                    information. This Privacy Policy, effective from August, 2024,
                    explains how we collect, use, and disclose information when
                    you visit or make a purchase from Anlook.com, which is now
                    operated by Chaafo Private Limited.
                  </p>
                  <p className="text-3-2"> Information We Collect </p>
                  <p>
                    When you purchase a product from our store, we collect
                    personal information that you provide, such as your name,
                    address, and email address, as part of the buying and
                    selling process. Additionally, when you browse our website,
                    we automatically receive your computer’s internet protocol
                    (IP) address, which helps us learn about your browser and
                    operating system. With your consent, we may send you emails
                    about our store, new products, and other updates.
                  </p>

                  <p className="text-3-2"> Consent </p>
                  <p>How do we obtain your consent?</p>
                  <p>
                    When you provide us with personal information to complete a
                    transaction, verify your credit card, place an order,
                    arrange a delivery, or return a purchase, you consent to our
                    collecting and using it for that specific purpose only. We
                    use your phone number as your login ID, and this number is
                    stored in our database for license management. Occasionally,
                    we may send you educational material to help you make better
                    use of our products. If you prefer not to have your phone
                    number stored, simply stop using the app and inform us by
                    emailing support@Chaafo.com. We will remove your number from
                    our database within 72 working hours and send you a written
                    acknowledgment.
                  </p>
                  <p>
                    If we request your personal information for a secondary
                    purpose, such as marketing, we will either ask you directly
                    for your explicit consent or provide you with an opportunity
                    to decline.
                  </p>

                  <p>How do I withdraw my consent?</p>
                  <p>
                    If, after opting in, you change your mind, you may withdraw
                    your consent for us to contact you or for the continued
                    collection, use, or disclosure of your information at any
                    time by contacting us at support@Chaafo.com.
                  </p>
                  <p className="text-3-2"> Disclosure </p>
                  <p>
                  We may disclose your personal information if required by law or if you violate our Terms of Service.

                  </p>

                  <p className="text-3-2"> Payment </p>
                  <p>
                  We use the Razorpay Payment Gateway to process payments. Razorpay does not store your card data on its servers. Your payment information is encrypted through the Payment Card Industry Data Security Standard (PCIDSS) during processing. Your purchase transaction data is used only as long as necessary to complete your purchase. After that, your purchase transaction information is not retained.

                  </p>
                  <p>Our payment gateway adheres to the standards set by PCIDSS, which is managed by the PCI Security Standards Council, a joint effort of companies like Visa, MasterCard, American Express, and Discover. These standards help ensure the secure handling of credit card information by our store and its service providers. For more details, please refer to Razorpay's terms and conditions at [https://razorpay.com](https://razorpay.com).
                  </p>

                  <p className="text-3-2"> ThirdParty Services</p>
                  <p>
                  Generally, thirdparty providers we use will only collect, use, and disclose your information to the extent necessary to perform the services they provide to us. However, certain thirdparty service providers, such as payment gateways and other payment processors, have their own privacy policies regarding the information we are required to provide them for your purchaserelated transactions. We recommend you review their privacy policies to understand how your personal information will be handled by these providers.

                  </p>
                  <p>Please note that some providers may be located in or have facilities in a different jurisdiction than you or us. If you proceed with a transaction involving a thirdparty service provider, your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our website or are redirected to a thirdparty website or application, this Privacy Policy and our website’s Terms of Service no longer apply.
                  </p>

                  <p className="text-3-2"> Security </p>
                  <p>
                  To protect your personal information, we take reasonable precautions and follow industry best practices to ensure that it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
                  </p>

                  <p className="text-3-2"> Cookies </p>
                  <p>
                  We use cookies to maintain your user session. These cookies do not personally identify you on other websites.
                  </p>

                  <p className="text-3-2"> Age of Consent</p>
                  <p>
                  By using this site, you confirm that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and have given us consent to allow any of your minor dependents to use this site.
                  </p>

                  <p className="text-3-2"> Changes to This Privacy Policy</p>
                  <p>
                  We reserve the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make significant changes to this policy, we will notify you here, so you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with another company, your information may be transferred to the new owners so we may continue to sell products to you.
                  </p>

                  <p className="text-3-2"> Contact Information</p>
                  <p>
                   If you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at support@Chaafo.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Privacy;
