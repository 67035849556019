import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import signup from "../../images/signup.svg";
import axios from 'axios';
import blueTick from "../../images/LandingPageImage/blueTick.svg";
import trueTick from "../../images/LandingPageImage/Tickcircle.svg";
import redcross from "../../images/LandingPageImage/redcross.svg";
import Modal from 'react-bootstrap/Modal';
import dropdown from "../../images/LandingPageImage/dropDown.svg";
import { toast, ToastContainer } from "react-toastify";

const PlansAndPricing = ({  userID }) => {
  const [plans, setPlans] = useState([]);
  const [alaCartePlans, setAlaCartePlans] = useState([]);

  const [isAnnual, setIsAnnual] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [arrayPlans, setArrayPlans] = useState([
    {
      id: "4",
      plan_type: "Free Trial",
      price: "0",
      duration: "for 7 days",
      button: "Get Free Trial",
      onetimefee:"5000",
      user_seats: "Get a 7-day free trial",
      conversations_per_year: " Get complete Meta account setup",
      additional_user_cost: "",
      additional_conversations_cost: "",
      description:
        "To access the free trial, a one-time setup fee of Rs. 5000 is mandatory.",
      features: [
        { id: "1", feature_name: "Developer account setup.", status: "Y" },
        { id: "2", feature_name: "Meta Business verification.", status: "Y" },
        { id: "3", feature_name: "Webhook setup", status: "Y" },
        { id: "4", feature_name: "Phone number registration.", status: "Y" },
        { id: "5", feature_name: "Configure WhatsApp API.", status: "Y" },
        { id: "6", feature_name: "Generate tokens.", status: "Y" },
        { id: "7", feature_name: "Start messaging.", status: "Y" },
      ],
    },
    {
      id: "1",
      plan_type: "Basic",
      price: "2,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee:"5000",
      user_seats: "5-10 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "1", feature_name: "Website + App (Basic)", status: "Y" },
        { id: "2", feature_name: "Chat Automation", status: "Y" },
        { id: "3", feature_name: "Automated Workflows", status: "Y" },
        { id: "4", feature_name: "Roles & Permissions", status: "Y" },
        { id: "5", feature_name: "Shared Team Inbox (Basic)", status: "Y" },
      ],
    },
    {
      id: "2",
      plan_type: "Standard",
      price: "5,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee:"5000",
      user_seats: "5 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "6", feature_name: "Website + App ", status: "Y" },
        { id: "7", feature_name: "Chat Automation", status: "Y" },
        { id: "8", feature_name: "Automated Workflows", status: "Y" },
        { id: "9", feature_name: "Roles & Permissions", status: "Y" },
        { id: "10", feature_name: "Shared Team Inbox (Intermediate)", status: "Y" },
        { id: "11", feature_name: "SEO/SMO", status: "Y" },
        { id: "12", feature_name: "Limited Team Members", status: "Y" },
      ],
    },
    {
      id: "3",
      plan_type: "Gold",
      price: "8,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee:"5000",
      user_seats: "5 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "13", feature_name: "Website + App (Advanced)", status: "Y" },
        { id: "14", feature_name: "Chat Automation", status: "Y" },
        { id: "15", feature_name: "Automated Workflows", status: "Y" },
        { id: "16", feature_name: "Roles & Permissions", status: "Y" },
        { id: "17", feature_name: "Shared Team Inbox (Advanced)", status: "Y" },
        { id: "18", feature_name: "SEO/SMO", status: "Y" },
        { id: "19", feature_name: "Maximum Number Of Team Members", status: "Y" },
      ],
    },
  ]);
  const upgradePlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const buyPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSwitch = () => {
    setIsAnnual(!isAnnual);
  };
  const pricingSchemaData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Anlook Pricing Plans",
    description:
      "Explore Anlook's pricing plans for WhatsApp automation tools.",
    url: "https://www.anlook.com/pricing",
    offers: {
      "@type": "AggregateOffer",
      url: "https://www.anlook.com/pricing",
      priceCurrency: "INR",
      lowPrice: "3000",
      highPrice: "10000",
      offerCount: 3,
      offers: [
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "3000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Basic Plan",
            description: "Basic WhatsApp automation tools.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "5000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Standard Plan",
            description:
              "Standard WhatsApp automation tools with extra features.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "10000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Premium Plan",
            description: "Premium WhatsApp automation tools with all features.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
      ],
    },

    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.5",
      reviewCount: "10",
    },
    review: [
      {
        "@type": "Review",
        author: {
          "@type": "Person",
          name: "RadhaKrishna ",
        },
        datePublished: "2024-07-01",
        reviewBody:
          "Great service with comprehensive WhatsApp automation tools.",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
        },
      },
    ],
  };





  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };


  const openInNewTab = (url) => {
    const newWindow = window.open(
    url,
    "",
    "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
    };
  const buyPlanClickAPI = async (planId) => {
 

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const location = await getUserLocation();
      const requestBody = {
        userid: userID,
        planid: planId,
        latitude: location.latitude.toString(),
        longitude: location.longitude.toString(),
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Entitlement/buySubscriptionPlan",
        requestBody,config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink; 
        // openInNewTab(response.data.data.paymentLink);
        setShowModal(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const upgradePlanClickAPI = async (planId) => {
 

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        userid: userID,
        planid: planId,
      
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Entitlement/upgradeSubscriptionPlan",
        requestBody,config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink; 
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const [subscriptionDetails, setSubscriptionDetails] = useState("");


  const subscriptionDetailsApi = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example Authorization
        },
      };
      const responseBody = {
        userid: userID,
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Entitlement/fetchSubscriptionDetails",
        responseBody,
        config
      );
  
      if (response.data.responseCode === "00") {
        // Remove the Free Trial plan (id: "4")
        const updatedPlans = arrayPlans.filter(plan => plan.id !== "4");
        // Update the button text for remaining plans
        const modifiedPlans = updatedPlans.map(plan => {
          return {
            ...plan,
            button: "Upgrade Now",
            onetimefee:"0",

          };
        });
        setArrayPlans(modifiedPlans);
      } else if (response.data.responseCode === "03") {
        // Handle response code 03 if needed
      } else {
        console.error("Failed to fetch subscription details:", response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  



  const fetchSubscriptionPlans = async () => {
    const url = 'https://anlookuat.remittpe.com/api/Entitlement/fetchSubscriptionPlans';
    const requestBody = {
      userid: userID
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
      },
    };
    try {
      
      const response = await axios.post(url, requestBody,config);

      setPlans(response.data.data); // Assuming the data is in response.data.data
      // setLoading(false);
    } catch (error) {
      // setError('Error fetching subscription plans');
      // setLoading(false);
      console.error('Error fetching subscription plans:', error);
    }
  };



  const fetchAlaCartePlans = async () => {
    const url = 'https://anlookuat.remittpe.com/api/Entitlement/fetchAlaCarte';
    const requestBody = {
      userid: userID
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
      },
    };
    try {
      
      const response = await axios.post(url, requestBody,config);

      setAlaCartePlans(response.data.data); 
    } catch (error) {
    
      console.error('Error fetching subscription plans:', error);
    }
  };
  
  // Call the function with a specific userid
  useEffect(() => {
    subscriptionDetailsApi();
     fetchSubscriptionPlans();
     fetchAlaCartePlans();

  }, []);
  return (
    <>
      <Helmet>
        <title>Anlook Pricing - Affordable Plans for WhatsApp Automation</title>
        <meta
          name="description"
          content="Discover Anlook's flexible pricing plans for our WhatsApp automation tool. Choose the perfect plan to meet your business needs and budget. Enhance your business communication strategy with Anlook today!"
        />
        <meta
          name="keywords"
          content="Anlook pricing, WhatsApp automation pricing, affordable plans, business communication, flexible pricing plans, WhatsApp automation tool, communication strategy, cost-effective solutions, Anlook plans, Anlook whatsapp automation"
        />
        <script type="application/ld+json">
          {JSON.stringify(pricingSchemaData)}
        </script>
      </Helmet>
      {/* {JSON.stringify(selectedPlan)} */}


      {selectedPlan && 
<>
<Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
  <Modal.Header closeButton className="modal-header-custom">
    <Modal.Title className="modal-title-custom">
      {selectedPlan?.plan_name} Plan
    </Modal.Title>
  </Modal.Header>
  
  <Modal.Body className="modal-body-custom">
  <div className="custom-pricing-card">
  <div className="custom-plan-info">
    <p className="custom-plan-details d-flex align-items-center">
      <span className="custom-plan-name pe-3">
        {selectedPlan?.plan_name} Plan {" "} :
      </span>
      <span className="custom-plan-price">
        {selectedPlan?.plan_price ? `₹${Number(selectedPlan.plan_price).toFixed(0) }` : "Price not available"}
      </span>
      <span className="ps-1 custom-plan-duration">
      {selectedPlan?.plan_duration}
    </span>
    </p>
    
    {selectedPlan?.setup_fee === "5000.000000" ?
      <>
         <p className="custom-plan-details d-flex align-items-center">
      <span className="custom-plan-name pe-3">
       Setup Fee :
      </span>

     
      <span className="custom-plan-price">
      ₹5,000
      </span>
      <span className="ps-1 custom-plan-duration">
      One-Time Fee
    </span>
     
    </p>
      </>:
      <>


      </>


      }


 
  </div>
  
  <div className="custom-total-cost">
  <h4 className="custom-total-cost-heading">Total Price</h4>
  <p className="custom-total-cost-amount mb-0">
    ₹ {selectedPlan?.total_price}
   

  </p>
</div>
</div>

    {/* Plan Details Section */}
    {/* <div className="modal-plan-details-section">
      <h4>Plan Details</h4>
      <p><strong>User Seats:</strong> {selectedPlan?.user_seats}</p>
      <p><strong>Conversations per Year:</strong> {selectedPlan?.conversations_per_year}</p>
       <p>{selectedPlan?.description}</p>
    </div> */}
  
    {/* Feature List Section */}
    <div className="custom-modal-feature-list-section">
  <h4>Features</h4>
  <ul className="custom-modal-feature-list">
    {selectedPlan?.features.map((feature, index) => (
      <li key={index} className={`custom-modal-feature-item ${feature.status === 'N' ? 'custom-disabled-feature' : ''}`}>
        {feature.feature_name}
      </li>
    ))}
  </ul>
</div>
  </Modal.Body>
  
  <Modal.Footer className="modal-footer-custom">
{selectedPlan && selectedPlan.button_text === "Upgrade Now" ? (
    <button onClick={() => { upgradePlanClickAPI(selectedPlan.id) }} className="btn btn-primary btn-custom">
        Proceed to Payment
    </button>
) : (
    <button onClick={() => { buyPlanClickAPI(selectedPlan.id) }} className="btn btn-primary btn-custom">
        Proceed to Payment
    </button>
)}

   
  </Modal.Footer>
</Modal>

</>
}
    




      <div className=" ">
        
      <ToastContainer />
        <div className="  px-4 py-3">
          <div className="py-2 d-flex flex-column justify-content-center text-center text-2-2 text-dark">
            <div>
              Elevate meaningful <br /> customer conversations.
            </div>
          </div>

          {/* <div
            className="d-flex py-3 flex-column flex-md-row justify-content-center single-line-css"
            style={{ gap: "1rem" }}
          >
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              7-day free trial
            </div>
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              Cancel anytime
            </div>
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              No credit card required
            </div>
          </div> */}

          <div className=" py-3 d-flex justify-content-center">
            <div className=" switch-button">
              <div
                className={`switch-option ${!isAnnual ? "active" : ""}`}
                onClick={() => setIsAnnual(false)}
              >
                Monthly
              </div>
              <div
                className={`switch-option ${isAnnual ? "active" : ""}`}
                onClick={() => setIsAnnual(true)}
              >
                Annually <span className="save-text">Save 20%</span>
              </div>
              <div
                className={`switch-slider ${
                  isAnnual ? "slider-right" : "slider-left"
                }`}
                onClick={toggleSwitch}
              ></div>
            </div>
          </div>

          <div className="">
            <div>

            </div>
          </div>
          <div className="pricing-cards-container pt-5">
        
          {plans?.map(plan => (
  <div 
    className={`pricing-card-wrapper ${plan.is_enabled !== "Y" ? "disabled-card" : ""}`} 
    key={plan.id}
  >
    <div className={`pricing-card ${plan.plan_name === "Standard" ? "highlight-card" : ""}`}>
      <div className="pricing-card-header">
        <h3>{plan.plan_name}</h3>
        {plan.plan_name === "Standard" && <span className="badge">Most Popular</span>}
      </div>
      <div className="pricing-card-body">
        <div className="price">
          ₹ {Number(plan.plan_price).toFixed(0)} <span className="price-duration">{plan.plan_duration}</span>
        </div>
        {plan.setup_fee === "5000.000000" && (
          <p className="setup-cost-note">
            <strong>+ One-time setup fee of ₹5,000</strong>
          </p>
        )}
        {plan.setup_fee === "0.000000" && (
          <p className="setup-cost-note">
            <strong>One-time setup not included</strong>
          </p>
        )}
        <p className="description">{plan.plan_desc}</p>
        <button 
          onClick={() => buyPlanClick(plan)} 
          className="buy-button" 
          disabled={plan.is_enabled !== "Y"}
        >
          {plan.button_text} →
        </button>
        <div className="feature-list pt-3">
          {plan.features
            .slice(0, showMore ? plan.features.length : 8)
            .map((feature, index) => (
              <p key={index} className={`feature-item ${feature.status === 'N' ? 'disabled' : ''}`}>
                {feature.status === 'Y' ? <img src={blueTick} alt="feature tick" /> : <img src={redcross} alt="redcross tick" />}
                {feature.feature_name}
              </p>
            ))}
        </div>
      </div>
    </div>
  </div>
))}


        </div>

          <div className="d-flex justify-content-center pt-5 ">
            <button
              onClick={toggleShowMore}
              className="gray-button bg-white text-gray "
              style={{ border: "1px solid #ccc", borderRadius: "20px" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {showMore ? "View less details" : "View all Feature details"}
                <span className="ms-1">
                  <img src={dropdown} alt="dropdown" />
                </span>
              </div>
            </button>
          </div>
        </div>






        <div className="alaCarte-wrapper bg-light border-15-only m-5 p-4">
      <h1 className="text-2-2 text-center">Available Ala-Carte Plans</h1>
      <div className="card-grid p-3">
        {alaCartePlans.map((plan) => (
          <div key={plan.id} className="alaCarte-card">
            <div className="card-header">
              <h2>{plan.add_on_module_name || plan.module_name}</h2>
              <p className="price-tag">  ₹ {Number(plan.module_price).toFixed(0)}</p>
            </div>
            <div className="card-body">
              <h3>Services Included</h3>
              <ul className="ps-0 ms-0">
                {plan.services.map((service) => (
                  <li key={service.id} className="service-item">
                    <span>{service.service_name}</span>
                    {/* <span className="service-code">Code: {service.service_code}</span> */}
                  </li>
                ))}
              </ul>
              <button 
          // onClick={() => buyPlanClick(plan)} 
          className="buy-button" 
          // disabled={plan.is_enabled !== "Y"}
        >
          Get It Now →
        </button>
            </div>
          </div>
        ))}
      </div>
    </div>



        {/* Calling Section */}
        <div className="d-flex justify-content-center bg-light-brown  py-5  ">
          <div className="row col-12 shadow bg-white border-redius-20 responsive-container">
            <div className="col-12 col-lg-7">
              <div className="p-4">
                <div className="text-5-2">Maximize your advertising ROI,</div>
                <div className="text-5-2">
                  Transform clicks into captivating conversations!
                </div>

                <div className="py-2">
                  Unleash Quality Leads, Nurture, and Interact through WhatsApp
                  after Clicks!
                </div>

                <div className="pt-4">
                  <button className=" shadow btn bg-green-filter  p-2 px-4 me-5 text-white d-flex align-items-center">
                    {/* <Link to="/contact"> */}
                    Talk to an Expert
                    <img
                      loading="lazy"
                      src={signup}
                      className="ps-2"
                      alt="signup"
                    />
                    {/* </Link> */}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 d-flex justify-content-center align-items-center">
              <img
                loading="lazy"
                src={callImage}
                alt="group9"
                className="image-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlansAndPricing;
