import { useState } from "react";
import {  ToastContainer } from "react-toastify";

import createCampaignImage from "../../../images/LandingPageImage/web_create_campaign_1x.webp";
import createTemplateImage from "../../../images/LandingPageImage/web_create_templates_1x.webp";
import chatSectionImage from "../../../images/LandingPageImage/web_chats_1x.webp";
import dashboardImage from "../../../images/LandingPageImage/web_dashboard_1x.webp";
import templateStatusImage from "../../../images/LandingPageImage/web_template_status_1x.webp";

const tabs = [
    {
      id: 0,
      label: "Dashboard",
      content:
        "View the main page displaying an overview of your WhatsApp bulk messaging activities, including campaign performance, subscriber statistics, and account settings.",
      img: dashboardImage,
    },
    {
      id: 1,
      label: "Create template",
      content:
        "Design custom message templates tailored for your WhatsApp bulk campaigns. This feature allows you to craft personalized and engaging messages efficiently.        ",
      img: createTemplateImage,
    },
    {
      id: 2,
      label: "Template Status",
      content:
        "Check the status of your existing message templates, including their approval status, usage metrics, and any necessary updates or modifications.",
      img: templateStatusImage,
    },
    {
      id: 3,
      label: "Chat Section",
      content:
        "Access the interactive chat section where you can engage with subscribers, address inquiries, and manage conversations in real-time, enhancing customer support and interaction.",
      img: chatSectionImage,
    },
    {
      id: 4,
      label: "Create Campaign",
      content:
        "Initiate new WhatsApp bulk messaging campaigns, specifying target audiences, scheduling delivery times, and selecting appropriate message templates to maximize engagement and outreach.",
      img: createCampaignImage,
    },
  ];
const TabSection=()=>{
    const [activeTab, setActiveTab] = useState(0);

    
    
return (

    <>
        <ToastContainer />

        <div className="p-0">
      <div className="d-flex justify-content-center bg-light-blue  py-5 ">
        <div>
          <div className="text-center  text-2-2">
            Rapid Integration with Your Existing Apps and Platforms
          </div>

      
          <div className="">
            <div className="tab-buttons d-flex justify-content-center ">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  style={{
                    color: "rgba(12, 77, 69, 1)",
                    fontWeight: activeTab === tab.id ? "bold" : "normal",
                    borderBottom:
                      activeTab === tab.id
                        ? "3px solid rgba(1, 195, 125, 1)"
                        : "3px solid #ccc",
                  }}
                  className="single-line-css"
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className="tab-content ">
              <div className="text-3-2 pb-4 text-center" >
                {tabs[activeTab].content}
              </div>
              <div className="d-flex justify-content-center">
                {tabs[activeTab].img && (
                  <img
                  loading="lazy"
                    src={tabs[activeTab].img}
                    alt={tabs[activeTab].label}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "700px",
                      objectFit: "cover"
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    
    </>
)
}

export default TabSection;