import React, { useEffect, useState } from "react";

import AdminNav from "./NavbarAdmin";
import axios from "axios";
import DonutChart from "../DonutChart";
import Bell from "../../images/notification-bell.png";
import Profile from "../../images/My Profile.png";
import ProgressImage from "../../images/Inprogress.png";
import CloseImage from "../../images/Close-circle.png";
import TickImage from "../../images/Tick-circle.png";
import SMSImage from "../../images/SMS Campaign.png";
import SupportImage from "../../images/Support Center.png";
import APIImage from "../../images/SMS API.png";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from "chart.js";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
ChartJS.register(ArcElement, ChartTooltip, ChartLegend);
const speed = 75; // Example speed of the bike
const maxSpeed = 100; // Maximum speed value

const semiDonutData = {
  datasets: [
    {
      data: [speed, maxSpeed - speed],
      backgroundColor: [
        " rgba(1, 195, 125, 1)", // Color for speed
        "rgba(200, 200, 200, 0.2)", // Color for remaining part
      ],
      borderColor: [" rgba(1, 195, 125, 1)", "rgba(200, 200, 200, 0.2)"],
      borderWidth: 1,
      cutout: "80%", // This makes the chart a donut
      rotation: -90, // Rotate to start from the top
      circumference: 180, // Semi-donut
    },
  ],
};

const semiDonutOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  rotation: -90,
  circumference: 180,
};

const areaChartData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data = [
  { name: "Jan", uv: 1000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const data_chat = [
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];

const CustomTooltip_chat = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const HomeAdmin = () => {
  const [dashboardData, setDashboardData] = useState("");
  const [percentage, setPercentage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [loading, setLoading] = useState(true);

  const QrCodeApi = async () => {
    try {
      const requestBody = {
        userid: "A1001",
        secret: "string",
        message: "Hii",
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Whatsapp/getQRCode",
        requestBody
      );
      console.log(response.data.response.qr_image_url);
      if (response.data.responseCode === "00") {
        setQrCode(response.data.response.qr_image_url);
      }
    } catch {}
  };

  const DashboardAPi = async () => {
    try {
      const requestbody = {
        userid: "A1001",
        secret: "string",
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Whatsapp/getDashboardData",
        requestbody
      );
      if (response.data.responseCode === "00") {
        setDashboardData(response.data.response);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    DashboardAPi();
    QrCodeApi();
  }, []);

  useEffect(() => {
    if (
      !loading &&
      dashboardData.sent_to_network !== undefined &&
      dashboardData.not_sent_to_network !== undefined
    ) {
      const donut = document.getElementById("donut-chart");
      const total =
        parseInt(dashboardData.sent_to_network) +
        parseInt(dashboardData.not_sent_to_network);
      setPercentage(total);
      const donutFill = (dashboardData.sent_to_network / total) * 440;
      donut.style.strokeDasharray = `${donutFill} 440`;
    }
  }, [dashboardData, loading]);

  const dashboardData1 = {
    total_requests: 358, // Example data
    approved: 180,
    pending: 150,
    rejected: 28,
  };
  const total =
    dashboardData1.approved + dashboardData1.pending + dashboardData1.rejected;
  const approvedPercentage = (dashboardData1.approved / total) * 100;
  const pendingPercentage = (dashboardData1.pending / total) * 100;
  const rejectedPercentage = (dashboardData1.rejected / total) * 100;

  // Calculate the stroke-dasharray values
  const circleCircumference = 2 * Math.PI * 80; // Circumference of the circle
  const approvedDashArray = `${
    (approvedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const pendingDashArray = `${
    (pendingPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const rejectedDashArray = `${
    (rejectedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;

  return (
    <div className="d-flex flex-column w-100">
      <div>
        <AdminNav />
      </div>
      <div
        className=" p-3 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito,sans-serif",
        }}
      >

     


        <div className="d-flex justify-content-between px-4">
          {/* <div className="CreateCampaign"> Dashboard</div> */}
        </div>


        <div className=" px-3">
          <div className="row d-flex  justify-content-between align-items-between">
            {/* First Network*/}

            <div className="col-5 p-0 m-0 ">
              <div className=" p-3 m-2 bg-white d-flex flex-column  justify-content-between border-15-black dashboard-height">
                <div className="row">
                  <div className="col-6">
                    <p className="text-3-2 m-2">Whatsapp Activity Status</p>
                    <div className="p-2  m-2 bg-green border-15">
                      <div>
                        <div className="text-3-2 text-start">
                          {" "}
                          {dashboardData.sent_to_network}
                        </div>
                        <div>
                          <span
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "rgba(1, 195, 125, 1)",
                              marginRight: "5px",
                            }}
                          ></span>
                          <span className="text-5 text-gray">
                            Sent to Network
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-2 m-2 border-15 bg-light-red">
                      <div>
                        <div className="text-3-2 text-start">
                          {" "}
                          {dashboardData.not_sent_to_network}
                        </div>
                        <div>
                          <span
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "rgba(215, 107, 102, 1)",
                              marginRight: "5px",
                            }}
                          ></span>
                          <span className="text-5 text-gray">
                            Not Sent to Network
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="p-2 m-2 border-15 bg-light-yellow">
                      <div>
                        <div className="text-3-2 text-start"> 0</div>
                        <div>
                          <span
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "rgba(250, 152, 0, 1)",
                              marginRight: "5px",
                            }}
                          ></span>
                          <span className="text-5 text-gray">In Progress</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    {" "}
                    {/* <DonutChart />{" "} */}
                    <div className="donut-chart ">
                      <svg width="200" height="200">
                        <circle
                          cx="100"
                          cy="100"
                          r="80"
                          stroke="#E7E7E7"
                          strokeWidth="20"
                          fill="transparent"
                        />
                        <circle
                          id="donut-chart"
                          cx="100"
                          cy="100"
                          r="80"
                          stroke="rgba(1, 195, 125, 1)"
                          strokeWidth="20"
                          fill="transparent"
                        />
                        <text
                          x="100"
                          y="100"
                          textAnchor="middle"
                          fontSize="24"
                          fill="black"
                          fontWeight="bold"
                        >
                          {dashboardData.total_requests}
                        </text>
                        <text
                          x="100"
                          y="120"
                          textAnchor="middle"
                          fontSize="12"
                          fill="black"
                        >
                          Total Requests
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Order Management*/}

            {/* <div className="col-3 p-0 m-0 ">
              <div className="m-2 p-3 bg-white border-15-black dashboard-height d-flex flex-column justify-content-between">
                <p className="text-3-2">Order Management</p>

                <div className="row ">
                  <div className="col-6">
                    <div className="text-3-2">500</div>
                    <div className="text-footer">Total Order</div>
                  </div>
                  <div className="col-6">
                    {" "}
                    <ResponsiveContainer height={50}>
                      <AreaChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="rgba(73, 166, 119, 1)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="rgba(255, 255, 255, 0)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ strokeDasharray: "3 3" }}
                        />
                        <Area
                          type="linier"
                          dataKey="uv"
                          stroke="rgba(93, 180, 138, 1)"
                          fill="url(#colorUv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <hr className="p-0 m-0" />
                <div className="row ">
                  <div className="col-6">
                    <div className="text-3-2">15</div>
                    <div className="text-footer">Processing</div>
                  </div>
                  <div className="col-6">
                    {" "}
                    <ResponsiveContainer height={50}>
                      <AreaChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv2"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="rgba(235, 148, 162, 1)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="rgba(235, 148, 162, 0)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ strokeDasharray: "3 3" }}
                        />
                        <Area
                          type="linier"
                          dataKey="uv"
                          stroke="rgba(235, 116, 135, 1)"
                          fill="url(#colorUv2)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <hr className="p-0 m-0" />
                <div className="row ">
                  <div className="col-6">
                    <div className="text-3-2">485</div>
                    <div className="text-footer">Delivered</div>
                  </div>
                  <div className="col-6">
                    {" "}
                    <ResponsiveContainer height={50}>
                      <AreaChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#8884d8"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="#8884d8"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ strokeDasharray: "3 3" }}
                        />
                        <Area
                          type="linier"
                          dataKey="uv"
                          stroke="rgba(93, 180, 138, 1)"
                          fill="url(#colorUv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <hr className="p-0 m-0" />
                <div className="d-flex justify-content-center">
                  <div className="btn bg-green-filter text-white">Details</div>
                </div>
              </div>
            </div> */}

            {/* Third Campaign*/}

            {/* <div className="col-4 p-0 m-0 ">
              <div className="m-2 p-3 bg-white d-flex flex-column  justify-content-between border-15-black dashboard-height">
                <div>
                  <p className="text-3-2">Campaign Management</p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="bg-light border-15 p-3">
                      <div className="d-flex justify-content-center">
                        Delivered
                      </div>
                      <div className="d-flex justify-content-center text-3-2">
                        450
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="bg-light border-15 p-3">
                      <div className="d-flex justify-content-center">
                        Processing
                      </div>
                      <div className="d-flex justify-content-center text-3-2">
                        20
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gred-voilate border-15 px-1 ">
                  <div className="p-3">
                    <div className="d-flex justify-content-center text-3-2">
                      470
                    </div>

                    <div className="d-flex justify-content-center">
                      Total Campaign
                    </div>
                  </div>
                  <div>
                    <ResponsiveContainer height={50}>
                      <AreaChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv4"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="rgba(230, 229, 251, 1)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="rgba(254, 253, 255, 1)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ strokeDasharray: "3 3" }}
                        />
                        <Area
                          type="linier"
                          dataKey="uv"
                          stroke="rgba(151, 143, 237, 1)"
                          fill="url(#colorUv4)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div> */}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAdmin;
