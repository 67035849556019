import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import frame1 from "../../images/Frame1.svg";
import frame2 from "../../images/Frame2.svg";
import frame3 from "../../images/Frame3.svg";
import youtube from "../../images/youtube.svg";
import linkedin from "../../images/linkedin.svg";
import Anlook from "../../images/Anlook.svg";

const LandingFooter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="bg-green-light-2 mt-5 d-flex justify-content-center padding-5-rem">
        <div className="">
          <div className="row col-12 pt-4">
            <div className="col-lg-5 col-12">
              <div className="p-1 p-lg-4">
                <img src={Anlook} width="150" alt="" />
                <div className="py-4">
                  At Anlook, our goal is to empower brands by offering personalized conversation services that cater to their end consumers. We are dedicated to providing seamless experiences that yield optimal results, driven by our commitment to your growth. Our ethos revolves around building stronger customer relationships through meaningful conversations.
                </div>
                <div className="pt-3">
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="shadow btn p-2 px-lg-5 px-2 me-5 text-white"
                    // onClick={onLoginClick}
                  >
                    Get in touch
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="p-4">
                <div className="text-3-2 pb-3">Company</div>
                <div className="py-2">
                  <Link to="/AboutUs" className="no-decoration">About Us</Link>
                </div>
                <div className="py-2">
                  <Link to="/PrivacyPolicy" className="no-decoration">Privacy Policy</Link>
                </div>
                <div className="py-2">
                  <Link to="/pricing" className="no-decoration">Pricing</Link>
                </div>
                <div className="py-2">
                  <Link to="/T&C" className="no-decoration">Terms & Condition</Link>
                </div>
                <div className="py-2">
                  <Link to="/Cancellation" className="no-decoration">Cancellation Policy</Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="p-4">
                <div className="text-3-2 pb-3">Resources</div>
                <div className="py-2">
                  <Link to="/blogs" className="no-decoration">Blogs</Link>
                </div>
                <div className="py-2">
                  <Link to="/" className="no-decoration">FAQ’s</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="p-4">
                <div className="text-3-2 pb-3">Follow Us</div>
                <div className="d-flex">
                  <div className="px-2">
                    <a href="https://www.instagram.com/anlookofficial/?igsh=MXE3Z256emNjZTkxeA%3D%3D" target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={frame1} alt="Instagram" />
                    </a>
                  </div>
                  <div className="px-2">
                    <a href="https://www.facebook.com/profile.php?id=61561042967744" target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={frame2} alt="Facebook" />
                    </a>
                  </div>
                  <div className="px-2">
                    <a href="https://www.youtube.com/channel/UC1Z5I3Rb-9PGwfSXBI-jiSQ" target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={youtube} alt="YouTube" />
                    </a>
                  </div>
                  <div className="px-2">
                    <a href="https://www.linkedin.com/company/anlook-official/" target="_blank" rel="noopener noreferrer">
                      <img className="social-icon" src={linkedin} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="" />
          <div className="d-flex justify-content-center pb-3">
            Anlook © 2024. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
