// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
  },

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
      
    },
    incrementByAmount:  (state, action) => {
      state.value += action.payload;
    },
    decrementByAmount:(state,action)=>{
      state.value -=action.payload;
    },
     
    makeItZero:(state,action)=>{
      state.value =0;
    }
  },
});
// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount ,decrementByAmount} = counterSlice.actions;
export default counterSlice.reducer;

