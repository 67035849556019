import React from "react";
import { Button } from "react-bootstrap";
import women from "../images/welcome.jpg"
import Expire from "../images/Expired.png"
import { Link } from "react-router-dom"; 
const ExpiredPlan = ({profiles}) => {
    const handlePlanClick = () => {
        window.location.href = "http://localhost:3000/Profile/Plans";
        // window.location.href = "https://www.anlook.com/Profile/Plans";

      };
      const handleDashboardClick = () => {
        window.location.href = "http://localhost:3000/Dashboard";
        // window.location.href = "https://www.anlook.com/Dashboard";

      };
  return (
    <div className="d-flex justify-content-center align-items-center p-5 flex-column">
   

    <h1 className="welcome-title "> Hi {profiles.username}</h1>
    <h2 className="welcome-subtitle">Your plan has expired. Please renew your subscription to continue using our services.</h2>
    
    <div className="py-5">
   <img src={Expire} alt="Anlook Logo" height={300} />
    </div>
    
    <div className="welcome-message mt-3 text-center">
        <p className="text-center text-primary welcome-subtitle">Upgrade your Plan</p>
      <p>Subscribe now to get your dedicated WhatsApp number approved and continue delivering a top-notch customer experience!</p>
 
      <div className="d-flex justify-content-center align-items-center">
      <Link to="/Profile/Plans">
      <button className="blue-button">Subscribe Now</button>
      </Link>
      </div>
    </div>
    <div className="text-center text-3-2 py-3">
<p className="">Go To <span className=" cursor-pointer">
<Link to="/Dashboard" className="no-decoration text-primary">
    Dashboard
      </Link>
    </span></p>
    </div>
    </div>
  );
};

export default ExpiredPlan;
