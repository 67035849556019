import React, { useState, useCallback } from "react";
import Box from "./Chat_Bot_Box";

const svgStyle = {
  width: "50%",
  height: "100%",
  zIndex: 0,
  position: "absolute",
  left: 0,
  top: 0,
  overflow: "visible",
  pointerEvents: "none",
};

export default function ChatBot() {
  const [l, setL] = useState({ x: 0, y: 0 });
  const [r, setR] = useState({ x: 0, y: 0 });
  const [one, setOne] = useState({ x: 0, y: 0 });
  const [two, setTwo] = useState({ x: 0, y: 0 });
  const [three, setThree] = useState({ x: 0, y: 0 }); // New state for box three
  const [four, setFour] = useState({ x: 0, y: 0 }); // New state for box four

  const updateL = useCallback(({ x, y }) => {
    setL({ x, y });
  }, []);

  const updateR = useCallback(({ x, y }) => {
    setR({ x, y });
  }, []);

  const updateOne = useCallback(({ x, y }) => {
    setOne({ x, y });
  }, []);

  const updateTwo = useCallback(({ x, y }) => {
    setTwo({ x, y });
  }, []);

  const updateThree = useCallback(({ x, y }) => {
    setThree({ x, y });
  }, []); // Update function for box three

  const updateFour = useCallback(({ x, y }) => {
    setFour({ x, y });
  }, []); // Update function for box four

  // Function to calculate the midpoint of a line
  const calculateMidpoint = (start, end) => ({
    x: (start.x + end.x) / 2,
    y: (start.y + end.y) / 2
  });

  return (
    <div className="bg-green-200">
      <div className="text1 px-5">Chatbot flow</div>
      <div className="App-chatbot">
        <Box id={"l"} x={200} y={200} onUpdate={updateL} />
        <Box id={"r"} x={500} y={300} onUpdate={updateR} />
        <Box id={"one"} x={800} y={100} onUpdate={updateOne} />
        <Box id={"two"} x={800} y={500} onUpdate={updateTwo} />
        {/* New Box components for three and four */}
        <Box id={"three"} x={1100} y={300} onUpdate={updateThree} />
        {/* <Box id={"four"} x={1500} y={300} onUpdate={updateFour} /> */}
        <svg style={svgStyle}>
          <g>
            {/* Connect l with r */}
            <path
              id="path1"
              d={`
                M ${l.x},${l.y}
                C ${l.x + Math.max((r.x - l.x) / 2, 100)},${l.y}
                  ${r.x - Math.max((r.x - l.x) / 2, 100)},${r.y}
                  ${r.x},${r.y}
              `}
              stroke="#ac5"
              strokeWidth="2px"
              fill="transparent"
            />
            <text fill="Green" dy="-5px" style={{ fontSize: "14px" }}>
              <textPath xlinkHref="#path1" startOffset="40%">
                Sending Menu ➜
              </textPath>
            </text>
            {/* Connect r with one */}
            <path
              id="path2"
              d={`
                M ${r.x},${r.y}
                C ${r.x + Math.max((one.x - r.x) / 2, 100)},${r.y}
                  ${one.x - Math.max((one.x - r.x) / 2, 100)},${one.y}
                  ${one.x},${one.y}
              `}
              stroke="#ac5"
              strokeWidth="2px"
              fill="transparent"
            />
            <text fill="Green" dy="-5px" style={{ fontSize: "14px" }}>
              <textPath xlinkHref="#path2" startOffset="40%">
                Order Placed ➜
              </textPath>
            </text>
            {/* Connect r with two */}
            <path
              id="path3"
              d={`
                M ${r.x},${r.y}
                C ${r.x + Math.max((two.x - r.x) / 2, 100)},${r.y}
                  ${two.x - Math.max((two.x - r.x) / 2, 100)},${two.y}
                  ${two.x},${two.y}
              `}
              stroke="#ac5"
              strokeWidth="2px"
              fill="transparent"
            />
            <text fill="red" dy="-5px" style={{ fontSize: "14px" }}>
              <textPath xlinkHref="#path3" startOffset="40%">
                Cancelled ➜
              </textPath>
            </text>
            {/* Connect two with three */}
            <path
              id="path4"
              d={`
                M ${two.x},${two.y}
                C ${two.x + Math.max((three.x - two.x) / 2, 100)},${two.y}
                  ${three.x - Math.max((three.x - two.x) / 2, 100)},${three.y}
                  ${three.x},${three.y}
              `}
              stroke="#ac5"
              strokeWidth="2px"
              fill="transparent"
            />
            <text fill="red" dy="-5px" style={{ fontSize: "14px" }}>
              <textPath xlinkHref="#path4" startOffset="40%">
                Some Action ➜
              </textPath>
            </text>
            {/* Connect three with four */}
            {/* <path
              id="path5"
              d={`
                M ${three.x},${three.y}
                C ${three.x + Math.max((four.x - three.x) / 2, 100)},${three.y}
                  ${four.x - Math.max((four.x - three.x) / 2, 100)},${four.y}
                  ${four.x},${four.y}
              `}
              stroke="#ac5"
              strokeWidth="2px"
              fill="transparent"
            />
            <text fill="red" dy="-5px" style={{ fontSize: "14px" }}>
              <textPath xlinkHref="#path5" startOffset="40%">
                Another Action ➜
              </textPath>
            </text> */}
          </g>
        </svg>
      </div>
    </div>
  );
}
