import React from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import logoImage from "../../images/Anlook.svg";
// Dummy data (same as before)
const monthlyData = [
  { name: 'Jan', clients: 4, revenue: 5000, visitors: 1000 },
  { name: 'Feb', clients: 7, revenue: 7000, visitors: 1200 },
  { name: 'Mar', clients: 10, revenue: 9000, visitors: 1500 },
  { name: 'Apr', clients: 15, revenue: 12000, visitors: 1800 },
  { name: 'May', clients: 20, revenue: 15000, visitors: 2200 },
  { name: 'Jun', clients: 23, revenue: 18000, visitors: 2500 },
];

const productPerformance = [
  { name: 'Product A', value: 400 },
  { name: 'Product B', value: 300 },
  { name: 'Product C', value: 200 },
  { name: 'Product D', value: 100 },
];

const clientAcquisition = [
  { name: 'Organic Search', value: 40 },
  { name: 'Paid Ads', value: 30 },
  { name: 'Referrals', value: 20 },
  { name: 'Direct', value: 10 },
];

const StatisticsDashboard = () => {
  return (
    <div className="dashboard mt-5 pt-5 bg-white  NavGap">
      <div className="company-header">
        <img src={logoImage} alt="" style={{height:"70px"}} />
        
      </div>
      
      <h2 className="dashboard-title CreateCampaign">Website Statistics Dashboard</h2>
      
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Total Clients</h3>
          <p className="stat-value">23</p>
          <p className="stat-change positive">↑ 15%</p>
        </div>
        <div className="stat-card">
          <h3>Total Revenue</h3>
          <p className="stat-value">$18,000</p>
          <p className="stat-change positive">↑ 20%</p>
        </div>
        <div className="stat-card">
          <h3>Website Visitors</h3>
          <p className="stat-value">2,500</p>
          <p className="stat-change positive">↑ 13%</p>
        </div>
        <div className="stat-card">
          <h3>Conversion Rate</h3>
          <p className="stat-value">3.2%</p>
          <p className="stat-change negative">↓ 0.5%</p>
        </div>
        <div className="stat-card">
          <h3>Avg. Session Duration</h3>
          <p className="stat-value">4m 32s</p>
          <p className="stat-change positive">↑ 8%</p>
        </div>
        <div className="stat-card">
        <h3>Customer Satisfaction</h3>
          <p className="stat-value">4.7/5</p>
          <p className="stat-change negative">↑ 0.2</p>
        </div>
        <div className="stat-card">
          <h3>New Users</h3>
          <p className="stat-value">1,250</p>
          <p className="stat-change positive">↑ 18%</p>
        </div>
    
      </div>

      <div className="chart-grid">
        <div className="chart-container">
          <h2>Monthly Growth</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="clients" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h2>Website Visitors</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="visitors" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h2>Product Performance</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie dataKey="value" data={productPerformance} fill="#8884d8" label />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h2>Client Acquisition Channels</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={clientAcquisition}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <style jsx>{`
        .dashboard {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .company-header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
        .company-logo {
          font-size: 2.5em;
          margin-right: 10px;
        }
        .company-name {
          font-size: 2em;
          color: #333;
        }
        .dashboard-title {
          text-align: center;
          color: #333;
          margin-bottom: 30px;
        }
        .stats-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 15px;
          margin-bottom: 30px;
        }
        .stat-card {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 15px;
          text-align: center;
        }
        .stat-card h3 {
          margin: 0 0 5px;
          color: #666;
          font-size: 0.9em;
        }
        .stat-value {
          font-size: 1.4em;
          font-weight: bold;
          margin: 0;
          color: #333;
        }
        .stat-change {
          margin: 5px 0 0;
          font-size: 0.8em;
        }
        .positive {
          color: #4caf50;
        }
        .negative {
          color: #f44336;
        }
        .chart-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
          gap: 20px;
        }
        .chart-container {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
        }
        .chart-container h2 {
          margin-top: 0;
          color: #333;
          font-size: 1.2em;
          text-align: center;
        }
        @media (max-width: 768px) {
          .stats-grid {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          }
          .chart-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default StatisticsDashboard;