import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import LandingNav from "./Landing_Nav";
import group9 from "../../images/LandingPageImage/web_img_01_1x.webp";

import { toast, ToastContainer } from "react-toastify";
import ss1 from "../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import ss2 from "../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../images/LandingPageImage/web_inbox_team_1x.webp";
import s4 from "../../images/s1.jpg";
import s5 from "../../images/s2.jpg";
import signup from "../../images/signup.svg";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";

import Integrations from "../../images/Integrations.svg";
import Highscalibility from "../../images/Highscalibility.svg";

import Chat22 from "../../images/Chat22.svg";

import TickShield from "../../images/TickShield.svg";

import CrossChannel from "../../images/CrossChannel.svg";

import scroll from "../../images/scroll.svg";

import LandingFooter from "./Landing_Footer";

const LandingAbout = ({ onLoginClick }) => {
  const [activeTab, setActiveTab] = useState(0);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const tabs = [
    {
      id: 0,
      label: "Dashboard",
      content:
        "View the main page displaying an overview of your WhatsApp bulk messaging activities, including campaign performance, subscriber statistics, and account settings.",
      img: s4,
    },
    {
      id: 1,
      label: "Create template",
      content:
        "Design custom message templates tailored for your WhatsApp bulk campaigns. This feature allows you to craft personalized and engaging messages efficiently.        ",
      img: ss2,
    },
    {
      id: 2,
      label: "Template Status",
      content:
        "Check the status of your existing message templates, including their approval status, usage metrics, and any necessary updates or modifications.",
      img: s5,
    },
    {
      id: 3,
      label: "Chat Section",
      content:
        "Access the interactive chat section where you can engage with subscribers, address inquiries, and manage conversations in real-time, enhancing customer support and interaction.",
      img: ss3,
    },
    {
      id: 4,
      label: "Create Campaign",
      content:
        "Initiate new WhatsApp bulk messaging campaigns, specifying target audiences, scheduling delivery times, and selecting appropriate message templates to maximize engagement and outreach.",
      img: ss1,
    },
  ];
  const cardData = [
    {
      id: 1,
      image: ss3,
      title: "Marketing Agencies",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 2,
      image: ss1,
      title: "Banking & Finances",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 3,
      image: ss2,
      title: "AI Industry",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
    {
      id: 4,
      image: ss3,
      title: "Banking",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 5,
      image: ss3,
      title: "Industry",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
    {
      id: 6,
      image: ss3,
      title: "Finances",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
  ];

  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === cardData.length - 3 ? 0 : prevIndex + 1
    );
  };

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)
    console.log("Name:", name);
    console.log("Phone:", phone);
    console.log("Message:", message);
    // Reset form fields and hide the form
    setName("");
    setPhone("");
    setMessage("");
    setShowForm(false);
  };

  const handleCloseClick = () => {
    setShowForm(false);
  };

  const [callBackForm, setCallBackForm] = useState({
    mobile: "",
    email: "",
    name: "",
    message: "",
    source: "",
    callback: "Y",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCallBackForm({
      ...callBackForm,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!callBackForm.name) {
      newErrors.name = "Name is required";
      toast.error("Name is required");
    } else if (!callBackForm.mobile) {
      newErrors.mobile = "Mobile number is required";
      toast.error("Mobile number is required");
    } else if (!/^\d{10}$/.test(callBackForm.mobile)) {
      newErrors.mobile = "Invalid mobile number";
      toast.error("Invalid mobile number");
    } else if (!callBackForm.message) {
      newErrors.message = "Message is required";
      toast.error("Message is required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const callbackAPI = async () => {
    if (!validateForm()) return;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        mobile: callBackForm.mobile,
        email: callBackForm.email,
        name: callBackForm.name,
        message: callBackForm.message,
        source: callBackForm.source,
        callback: callBackForm.callback,
      };
      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/User/saveLeadMessage",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
      } else {
        toast.error("Failed to edit contact");
      }
    } catch {
      console.log("");
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const aboutUsSchemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Anlook",
    url: "https://www.anlook.com",
    logo: "https://www.anlook.com/logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91-9958853167",
      contactType: "Customer Service",
      areaServed: {
        "@type": "Place",
        name: "India",
      },
      availableLanguage: "English",
    },
    sameAs: [
      "https://www.instagram.com/anlookofficial/?igsh=MXE3Z256emNjZTkxeA%3D%3D",
      "https://www.linkedin.com/company/anlook-official/",
      "https://www.facebook.com/profile.php?id=61561042967744",
    ],
    description:
      "Anlook is a leader in WhatsApp automation solutions. Discover our mission, values, and the team dedicated to enhancing your business communication. Get to know us better!",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Ithum Tower, Tower-B, 218, Second Floor",
      addressLocality: "Sector 62",
      addressRegion: "Noida",
      postalCode: "201309",
      addressCountry: "IN",
    },
    contactOption: {
      "@type": "ContactOption",
      contactType: "Customer Support",
      url: "https://www.anlook.com/contact",
    },
  };

  return (
    <div className=" ">
      <Helmet>
        <title>About Anlook | Innovating WhatsApp Automation Solutions</title>
        <meta
          name="description"
          content="Learn about Anlook, a leader in WhatsApp automation solutions. Discover our mission, values, and the team dedicated to enhancing your business communication. Get to know us better!"
        />
        <meta
          name="keywords"
          content="about Anlook, WhatsApp automation solutions, company mission, business communication, team, leadership, Anlook values"
        />
        <script type="application/ld+json">
          {JSON.stringify(aboutUsSchemaData)}
        </script>
      </Helmet>

      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <LandingNav onLoginClick={onLoginClick} />
      <div className="gradientBackground padding-5-rem py-3">
        {/* First Section */}
        <div className="row col-12 py-lg-5 my-lg-5 ">
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div>
              <div className="text-2-2 pt-3 ">
                <h1> About Us</h1>
              </div>
              <div className="pb-1 text-text-2 pt-4">
                Anlook is a new tool for WhatsApp that makes your business
                conversations easier. With Anlook, you can send messages
                automatically to your customers. It helps you keep your
                consumers updated with information you want to share.
              </div>

              <div className="pt-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="  btn  p-2 px-4 me-3 text-white"
                  // onClick={onLoginClick}
                >
                  Explore
                  <img src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="  col-12  col-lg-6 d-flex justify-content-center align-items-center">
            <img
              src={group9}
              alt="Anlook enhances customer trust"
              className="image-cover"
            />
          </div>
        </div>
      </div>

      {/* Second Section */}
      <div className="d-flex justify-content-center bg-green padding-5-rem py-5 ">
        <div>
          <div className="row col-12 py-lg-5 py-2">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="pe-5">
                <div className="text-3-2 pt-5 pt-lg-0">Why Choose Us?</div>
                <div className="pb-2 pt-4">
                  Do you want to boost your business connections, But don’t know
                  which marketing type is best for your business and will surely
                  give you the desired results? Don't worry we have got your
                  back.
                </div>
                <div className="py-2">
                  Ever heard of automated marketing tools, what if I told you
                  this tool is a one-stop solution for all your worries? It's
                  easy to use and can help you instantly connect with your
                  desired users.
                </div>
                <div className="py-2">
                  Manage and send bulk WhatsApp messages with Anlook. Run
                  campaigns and create customized templates to boost your
                  communication.
                </div>
              </div>
              <div className="py-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 text-white d-flex align-items-center"
                >
                  Talk to an Expert
                  <img
                    src={signup}
                    className="ps-2"
                    alt="Create campaigns for WhatsApp automation with Anlook"
                  />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center">
              <img
                src={ss1}
                alt="Anlook's automation features: efficiency, reliability, and innovation"
                width={250}
                className="w-100"
              />
            </div>
          </div>

          <div className="row col-12 py-0 py-lg-5 my-5">
            <div className="col-12 col-lg-6 order-1 order-lg-1 d-flex justify-content-center">
              <img src={ss2} alt="" width={250} className="w-100" />
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              <div className="ps-lg-5 ps-0">
                <div className="text-3-2 pt-5 pt-lg-0">What do we offer?</div>
                <div className="pb-2 pt-4">
                  • Easy Messaging: Send large volumes of WhatsApp messages with
                  ease.
                </div>
                <div className="py-2">
                  • Schedule Campaigns: Plan your messages. Do cool marketing
                  stuff on WhatsApp easily!
                </div>
                <div className="py-2">
                  • Personalize Templates: Make your messages unique.
                </div>
                <div className="py-2">
                  • Security: We keep your messages secure.
                </div>
                <div className="py-2">
                  • Regular Updates: Keep your consumers updated with alerts and
                  personalised promotional messages.
                </div>
              </div>
              <div className="py-4 ps-0 ps-lg-5">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 me-5 text-white d-flex align-items-center"
                >
                  Talk to an Expert
                  <img src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
          </div>

          <div className="row col-12 py-lg-5 py-2 mt-5">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="pe-5">
                <div className="text-3-2 pt-5 pt-lg-0">Benefits you get</div>
                <div className="pb-2 pt-4">
                  Communicating with customers is now made easy. Anlook is a new
                  way of marketing with which you can make more money and boost
                  your sales. You can easily deliver all the information about
                  your business to your consumer. A happy customer drives your
                  business and gives them a reason to come back to you, engage
                  with them and let your sales shoot.
                </div>
              </div>
              <div className="py-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="shadow btn p-2 px-4 me-5 text-white"
                >
                  Talk to an Expert
                  <img src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center">
              <img
                src={ss3}
                alt="Anlook is the most trusted name in WhatsApp automation."
                width={250}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>

    
      {/* Third Section */}
      <div className="d-flex justify-content-center bg-light-brown  py-5  ">
        <div className="row col-12 shadow bg-white border-redius-20 responsive-container">
          <div className="col-12 col-lg-7">
            <div className="p-4">
              <div className="text-5-2">Maximize your advertising ROI,</div>
              <div className="text-5-2">
                Transform clicks into captivating conversations!
              </div>

              <div className="py-2">
                Unleash Quality Leads, Nurture, and Interact through WhatsApp
                after Clicks!
              </div>

              <div className="pt-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className=" shadow btn  p-2 px-4 me-5 text-white d-flex align-items-center"
                  onClick={onLoginClick}
                >
                  Talk to an Expert
                  <img src={signup} className="ps-2" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-12 d-flex justify-content-center align-items-center">
            <img
              loading="lazy"
              src={callImage}
              alt="group9"
              className="image-cover"
            />
          </div>
        </div>
      </div>

      {/* Eleven Section Footer */}

      <LandingFooter />
    </div>
  );
};

export default LandingAbout;
