import React from "react";
import employee from "../../images/Employee1.png";
import envelope from "../../images/message.svg";
import phone from "../../images/telephone.svg";
import TopNav from "./NavbarAdmin";
import SideNav from "./AdminSideNav";

const employees = [
  {
    id: 1,
    name: "Radha Krishna Yadav",
    role: "Senior Frontend Developer",
    department: "Frontend",
    avatar: employee,
    email: "radha.krishna@bumppy.com",
    phone: "(555) 123-4567",
  },
  {
    id: 2,
    name: "Kewal Singh Jaryal",
    role: "Junior Frontend Developer",
    department: "Frontend",
    avatar: employee,
    email: "kewal.singh@bumppy.com",
    phone: "(555) 234-5678",
  },
  {
    id: 3,
    name: "Suhas Suryavanshi",
    role: "Backend Engineer",
    department: "Backend",
    avatar: employee,
    email: "suhas.suryavanshi@bumppy.com",
    phone: "(555) 345-6789",
  },
  {
    id: 4,
    name: "Abhijeet Mandal",
    role: "Database Administrator",
    department: "Backend",
    avatar: employee,
    email: "abhijeet.mandal@bumppy.com",
    phone: "(555) 456-7890",
  },
  {
    id: 5,
    name: "Suhas Suryavanshi",
    role: "Project Manager",
    department: "Operations",
    avatar: employee,
    email: "ethan@example.com",
    phone: "(555) 567-8901",
  },
  {
    id: 6,
    name: "Deepak Rajput",
    role: "UX Designer",
    department: "Frontend",
    avatar: employee,
    email: "deepak.rajput@bumppy.com",
    phone: "(555) 678-9012",
  },
  {
    id: 7,
    name: "Sumit Kasana",
    role: "Operation Head",
    department: "Operations",
    avatar: employee,
    email: "sumit.kasana@bumppy.com",
    phone: "(555) 789-0123",
  },
  {
    id: 8,
    name: "Nikhil Bhatti",
    role: "PHP Developer",
    department: "Backend",
    avatar: employee,
    email: "nikhil.bhati@bumppy.com",
    phone: "(555) 890-1234",
  },
];

const EmployeeCard = ({ employee }) => (
  <div className="employee-card dashboard">
    <img
      src={employee.avatar}
      alt={employee.name}
      className="employee-avatar"
    />
    <h3 className="employee-name">{employee.name}</h3>
    <p className="employee-role">{employee.role}</p>
    <div className="employee-contact">
      <p>
        <img src={envelope} alt="" /> {employee.email}
      </p>
      <p>
        {" "}
        <img src={phone} alt="" /> {employee.phone}
      </p>
    </div>
  </div>
);

const EmployeeSectionCards = () => {
  const departments = [...new Set(employees.map((emp) => emp.department))];

  return (
    <div className="mt-5 dashboard employee-section  NavGap bg-white">
      <h1 className="section-title text-start">Our Team</h1>
      {departments.map((department) => (
        <div key={department} className="department-section">
          <p className="department-title CreateCampaign">{department}</p>
          <div className="employee-grid">
            {employees
              .filter((emp) => emp.department === department)
              .map((employee) => (
                <EmployeeCard key={employee.id} employee={employee} />
              ))}
          </div>
        </div>
      ))}
      <style jsx>{`
        .employee-section {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .section-title {
          text-align: center;
          color: #333;
          margin-bottom: 30px;
        }
        .department-section {
          margin-bottom: 40px;
        }
        .department-title {
          color: #2c3e50;
          border-bottom: 2px solid #3498db;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
        .employee-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
        }
        .employee-card {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .employee-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
        .employee-avatar {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 15px;
        }
        .employee-name {
          margin: 0;
          color: #2c3e50;
          font-size: 1.2em;
        }
        .employee-role {
          color: #7f8c8d;
          margin: 5px 0 15px;
        }
        .employee-contact {
          font-size: 0.9em;
          color: #34495e;
        }
        .employee-contact p {
          margin: 5px 0;
        }
        @media (max-width: 768px) {
          .employee-grid {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          }
        }
        @media (max-width: 480px) {
          .employee-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default EmployeeSectionCards;