import React, { useState } from "react";
import eye from "../../images/eye.svg";

function SupportCenter() {
  const userId = "K3w4l";
  const icon = "k";
  return (
    <>
      {/* Heading NavBar */}
      <nav className="navbar bg-body-tertiary col-lg-12">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1 my-2">Your Account</span>
        </div>
      </nav>
      <div
        className="d-flex "
        style={{
          padding: "90px",
          paddingLeft: "100px",
          paddingBottom: "175px",
          backgroundColor: "#F3F3F3",
        }}
      >
        {/* user icon and logout button */}

        <div
          className="circle-icon text-center col-lg-4"
          style={{ paddingTop: "150px" }}
        >
          <img
            className="icon"
            src={
              "https://w7.pngwing.com/pngs/529/816/png-transparent-computer-icons-user-profile-avatar-heroes-monochrome-black-thumbnail.png"
            }
            alt="User Icon"
          />
          <div className="mx-1 my-1">{userId}</div>
          <button
            type="button"
            class="btn btn-outline-dark my-1"
            style={{ color: "solid" }}
          >
            Logout
          </button>
        </div>
        {/* account profile and detail filling form  */}
        <div className="profile-detail-form container ">
          <div className="mb-3 col-lg-6">
            <label for="exampleFormControlInput1" className="form-label">
              <b>Display Name</b>
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="Enter your username....."
              style={{ backgroundColor: "#DFDFDF" }}
            />
          </div>
          <div className="mb-3  col-lg-6 ">
            <label for="exampleFormControlInput1" className="form-label">
              <b>Email</b>
            </label>
            <input
              type="email"
              className="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="abcdefghijkl@gmail.com"
              style={{ backgroundColor: "#DFDFDF" }}
            />
          </div>
          <div className="mb-3  col-lg-6 ">
            <label for="exampleFormControlInput1" className="form-label">
              <b>WhatsApp Number</b>
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="9876543210"
              style={{ backgroundColor: "#DFDFDF" }}
            />
          </div>
          <div className="mb-3  col-lg-6 ">
            <label for="exampleFormControlInput1" className="form-label">
              <b>User Name</b>
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="exampleFormControlInput1"
              placeholder="kewalJaryal@bumppy.com"
              style={{ backgroundColor: "#DFDFDF" }}
            />
          </div>
          <div className="mb-3  col-lg-6 ">
            <label for="inputPassword" className="form-label">
              <b>Password</b>
            </label>
            <div className="input-group mb-3 d-flex">
              <input
                type="password"
                className="form-control form-control-lg"
                id="exampleFormControlInput1"
                placeholder="..............."
                style={{ backgroundColor: "#DFDFDF" }}
              />
              <span
                class="input-group-text"
                id="basic-addon1"
                style={{ backgroundColor: "#DFDFDF" }}
              >
                <img src={eye} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportCenter;