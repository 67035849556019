import React from "react";
import signup from "../../images/signup.svg";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import Anlook from "../../images/Anlook.svg";
import { Link } from "react-router-dom";

const LandingNav = ({ onLoginClick }) => {
  return (
    <>
      <div className="">
        <Navbar bg="" className="bg-green-light-2  d-flex align-items-center" expand="lg">
          <Container>
            <Navbar.Brand href="#home" className=" p-0 m-0">
              <img
                src={Anlook}
                width="150"
              
                className="d-inline-block align-top "
                alt="Anlook logo - Your trusted partner in automation"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto  me-auto ">
                <Link
                  to="/"
                  className={`text-3-2 px-3 p-2 mx-3 ${
                    window.location.pathname === "/" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Home
                </Link>
                <Link
                  to="/pricing"
                  className={`text-3-2 px-3 p-2 mx-3 ${
                    window.location.pathname === "/pricing" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Pricing
                </Link>

                <Link
                  to="/blogs"
                  className={`text-3-2 px-3 p-2 mx-3 ${
                    window.location.pathname === "/blogs" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Blogs
                </Link>
                <Link
                  to="/contact"
                  className={`text-3-2 px-3 p-2 mx-3 ${
                    window.location.pathname === "/contact" ? "active-link" : ""
                  }`}
                  activeClassName="active-link"
                >
                  Contact Us
                </Link>
              </Nav>
              <Nav>
                <div className="">
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="  btn  p-2 px-4 me-3 text-white"
                    onClick={onLoginClick}
                  >
                    Sign Up
                    <img src={signup} className="ps-2" alt="" />
                  </button>
                  <button
                    // style={{
                    //   backgroundColor: "#c4d45",
                    //   color: "rgba(1, 195, 125, 1)",
                    // }}
                    // style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className="  btn  bg-success text-white p-2 px-5 "
                    onClick={onLoginClick}
                  >
                    Login
                  </button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default LandingNav;
