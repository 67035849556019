import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JS
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import axios from 'axios';
import Pagination from "../Whatsapp/Pagination";
const PayOut = ({ userID }) => {
  const [loading, setLoading] = useState(false);
  const [Campaign, setCampaign] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [wallet, setWallet] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // fetching the campaigns using the Anlook api.
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const transactionsReportApi = async () => {
    try {
      setLoading(true);
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${BASIC_AUTH}`,
        },
      };
  
      // const raw = {
      //   userid: userID,
      //   fromDate: "2024-01-01",
      //   toDate: "2024-09-05",
      // };
      const raw = {
        userid: userID,
        fromDate: startDate,
        toDate: endDate,
      };
      const res = await axios.post(
        "https://anlookuat.remittpe.com/api/Transactions/getTransactionsReport",
        raw,
        config
      );
  
      if (res.data?.responseCode === "00") {
        setCampaign(res.data.response);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  

  const totalPages = Math.ceil(Campaign.length / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Sort Campaign array in descending order by date
  const sortedCampaign = [...Campaign].sort((a, b) => new Date(b.indate) - new Date(a.indate));

  const displayedItems = sortedCampaign.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


const walletBalanceApi=async()=>{
  try{
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${BASIC_AUTH}`,
      },
    };
    const requestBody={
       userid: userID,
    }

    const response=await axios.post("https://anlookuat.remittpe.com/api/Transactions/getWalletDetails",requestBody,config);

    if(response.data.responseCode==="00")
    {
      setWallet(response.data.response);
    }else{

    }


  }catch{

  }
}
useEffect(() => {
  walletBalanceApi();
  setStartDate(getCurrentDate()); // Set today's date
  setEndDate(getCurrentDate());   // Set today's date
}, []);

// Fetch data when the dates are set
useEffect(() => {
  if (startDate && endDate) {
    transactionsReportApi();
  }
}, [startDate, endDate]);

  return (
    <>
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
      <div>
        <div
          className="p-4 bg-white"
          style={{
            height: "100vh",
            fontFamily: "Nunito,sans-serif",
          }}
        >
          <div className="px-4 justify-content-center">
            <Form className="d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign">PayOut</Form.Label>
            
              <div className="d-flex">
              <div className="border me-4 bg-light px-4 border-15-only d-flex justify-content-center align-items-center py-0 ">
              <span className="text-dark single-line-css">
  Wallet Balance :{" "}
  <span className="ms-2 text-3-2 text-success ">₹ {parseFloat(wallet.walletBalance ||0.00).toFixed(2)}</span>
</span></div>
                <label
                  htmlFor="start-date"
                  className="single-line-css d-flex align-items-center"
                >
                  Start Date
                </label>

                <FormControl
                  type="date"
                  id="start-date"
                  value={startDate}
                  className="bg-light me-4 ms-2"
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                />
                <label
                  htmlFor="end-date"
                  className="single-line-css d-flex align-items-center"
                >
                  End Date
                </label>
                <FormControl
                  type="date"
                  id="end-date"
                  value={endDate}
                  className="bg-light ms-2"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </Form>
            {loading ? (
              <div className="m-5 p-5 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <table className="table table-hover">
                  <thead style={{ borderRadius: "10px" }}>
                    <tr
                      className="text-center greenColour py-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                      >
                        S.No.
                      </th>
                     
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                       Opening Balance
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Credit
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Debit
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Closing Balance
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                       Refrence No.
                      </th>
                      
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                       Payment Mode
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {displayedItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #DDD",
                          padding: "5px 0px",
                        }}
                      >
                        <td className="p-3 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>

                        <td className="p-3 text-center">{item.opening}</td>
                        <td className="p-3 text-center">{item.credit}</td>
                          <td className="p-3 text-center">{item.debit}</td>
                        <td className="p-3 text-center">{item.closing}</td>
                        <td className="p-3 text-center">{item.refrence}</td>

                        <td className="p-3 text-center">
                          {item.payment_mode === "UPI" ? (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: " #007E59",
                                border: "solid 1px #007E59",
                                borderRadius: "5px",
                              }}
                            >
                              UPI
                            </span>
                          ) : (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: "#000",
                                border: "solid 1px #000",
                                borderRadius: "5px",
                              }}
                            >
                              {item.payment_mode}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
<div className="pb-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePrevPage}
                  onPageClick={handlePageClick}
                />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayOut;
