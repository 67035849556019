import React from 'react';
import  { useState, useEffect } from "react";
import axios from "axios";
import NoIndex from "../NoIndex";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eye from "../../images/EYE.png";
import trash from "../../images/Delete.svg";
import Edit from "../../images/message-edit.png";
import { useNavigate } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
// import Pagination from "../Pagination";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import pause from "../../images/Pause.png";
import Play from "../../images/Play.png";

import Swal from "sweetalert2";
const LeadManagement = ({ userID }) => {
  const dataArray = [
    { title: 'Total Sent', value: 1250, bgColor: '#EBF0FF', textColor: '#4B9BE3' },
    { title: 'Total Delivered', value: 1200, bgColor: '#F2ECFD', textColor: '#9376E4' },
    { title: 'Total Read', value: 1180, bgColor: '#EDFDF5', textColor: '#67C680' },
    { title: 'Total Replied', value: 190, bgColor: '#FFF4E1', textColor: '#FC8A02' },
    { title: 'Total Failed', value: 150, bgColor: '#FFECEC', textColor: '#FB3C4F' },
  ];
  const [Campaign, setCampaign] = useState([]);
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteState, setdeleteState] = useState("");
  const [ExcuteDelete, setExcuteDelete] = useState("");
  const [ModalData, setModalData] = useState("");
  const [ErrorDelete, setErrorDelete] = useState();
  const[interval,setInterval]=useState("1");

  const handleClick = (type) => {
    if (type === "prev") {
      setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
    } else if (type === "next") {
      setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    if (
      pageNumber !== currentPage &&
      pageNumber >= 1 &&
      pageNumber <= totalPages
    ) {
      setCurrentPage(pageNumber);
      // Perform any action here, like fetching data for the new page
    }
  };
  const navigate = useNavigate();
  

  const handleViewClick = (item) => {
    navigate(
    //   `/LeadDetails?LeadDetails=${item}`
     `/Analytics/LeadDetails?LeadDetails=${item}`
    );
  };
  // fetching the campaigns using the Anlook api.
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getCampaign = async (e) => {
    try {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
        campaignName: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Campaign/getCampaigns`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setCampaign(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    getCampaign();
  }, []);

  function handleModal(item) {
    setModalData(item);
  }

  function handleDelete(item) {
    setdeleteState(item);
    SetExecute();
  }
  function SetExecute() {
    setExcuteDelete("delete");
  }

 
  // pagination values defined here
  const data = Campaign.filter(
    (item) =>
      item.campaign_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.campaign_start?.includes(searchTerm) ||
      item.campaign_end?.includes(searchTerm) ||
      item.campaign_interval?.includes(searchTerm) ||
      item.channel?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;

  const endIndex =
    currentPage === totalPages ? Campaign?.length : startIndex + itemsPerPage;
  const currentItems = data?.slice(startIndex, endIndex);

  // Edit campaign states loading
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Initialize tooltips when component mounts
    const tooltips = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));

    // Clean up tooltips when component unmounts
    return () => {
      tooltips?.map((tooltip) => tooltip?.dispose());
    };
  }, []);





  useEffect(() => {
 
    const fetchCampaignAnalyticsData = async () => {
      try {
        // setLoading(true);

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${BASIC_AUTH}`,
        };
    
        const body = {
          userid: userID,
          
           campaignId: "",
           interval: interval,
        };
    
        const response = await axios.post(
          'https://anlookuat.remittpe.com/api/Campaign/getCampaignAnalytics',
          body,
          { headers }
        );

        if (response.data.responseCode === '00' && response.data.data) {
          // Filter the campaign using the campaign ID
          setCampaignAnalyticsData( response.data.data);
        }
      } catch (error) {
        console.error('Error fetching campaign data:', error);
      }
    };

    fetchCampaignAnalyticsData();
  }, [interval]);


  return (
    <div
    className="p-4 bg-white"
    style={{
      // backgroundColor: "#F8F8F8",
      fontFamily: "Nunito, sans-serif",
    }}
  >
     <div className=" px-4 d-flex justify-content-between align-items-end  ">
        <div className="CreateCampaign">Lead Management</div>
        <select
           className="form-select w-auto"
    onChange={(e) => {
      const val = e.target.value;
      setInterval(val);
    }}
  >
    <option value="1">Today</option>
    <option value="7">Last Week</option>
    <option value="30">Last Month</option>
  </select>
       
      </div>
    
       
    
  <div className="lead-management-overview">
         
        
           <div className="overview-box" style={{ backgroundColor:'#EBF0FF' , color:'#4B9BE3'  }}>
             <div className="title">
             Total Sent
             </div>
             <div className="value">{campaignAnalyticsData.totalSent || 0}</div>
           </div>
           <div className="overview-box" style={{ backgroundColor:'#F2ECFD' , color: '#9376E4'  }}>
             <div className="title">
             Total Delivered
             </div>
             <div className="value">{campaignAnalyticsData.totalDelivered || 0}</div>
           </div>
           <div className="overview-box" style={{ backgroundColor:'#EDFDF5' , color: '#67C680' }}>
             <div className="title">
             Total Read
             </div>
             <div className="value">{campaignAnalyticsData.totalRead || 0}</div>
           </div>
           <div className="overview-box" style={{ backgroundColor: '#FFF4E1', color: '#FC8A02'   }}>
             <div className="title">
             Total Replied
             </div>
             <div className="value">{campaignAnalyticsData.totalReplied || 0}</div>
           </div>
           <div className="overview-box" style={{ backgroundColor: '#FFECEC' , color: '#FB3C4F' }}>
             <div className="title">
             Total Failed
             </div>
             <div className="value">{campaignAnalyticsData.totalFailed || 0}</div>
           </div>
          
 
       </div>






       <div className=" px-4  justify-content-center">
            <Form className=" d-flex justify-content-between ">
              {/* <Form.Label className="CreateCampaign   ">
              Campaign List
              </Form.Label> */}
              {/* <Form inline style={{ width: "25rem" }}>
                <FormControl
                  type="text"
                  placeholder="Search Campaign..."
                  value={searchTerm}
                  className="bg-light mb-2"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form> */}
            </Form>
            {loading ? (
              <div className=" m-5 p-5 d-flex justify-content-center">
                <div className="loader "></div>
              </div>
            ) : (
              <>
                <React.Fragment
                  className=""
                  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "10px",
                  }}
                >
                  <table className="table table-hover"  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "10px",
                  }}>
                    <thead style={{ borderRadius: "10px" }}>
                      <tr className="text-center greenColour  py-4">
                        <th className="pb-3">S.No.</th>
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          Campaign Name
                        </th>

                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          {" "}
                          Start Time
                        </th>

                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          End Time
                        </th>

                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          Status
                        </th>

                        
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            borderRadius: "0px 7px 7px 0px",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentItems?.map((item, index) => (
                        <tr
                          key={index}
                          style={
                            {
                              // borderBottom: "1px solid #DDD",
                              // padding: "5px 0px",
                            }
                          }
                        >
                          <td className="pt-4 text-center">
                            {index + startIndex + 1}
                          </td>

                          <td className="pt-4 text-center">{item.campaign_name}</td>

                          <td className="pt-4 text-center">
                            {item.campaign_start}
                          </td>
                          <td className="pt-4 text-center">{item.campaign_end}</td>

                          <td className="pt-4 text-center">
                            {item.status === "N" ? (
                              <span
                                className=" text-center w-100  px-3 py-1"
                                style={{
                                  width: "91px",
                                  height: "31px",

                                  borderRadius: "50px",
                                  color: "rgba(255, 72, 72, 1)",
                                  background: " rgba(255, 224, 224, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                Stopped
                              </span>
                            ) : (
                              <span
                                className=" text-center w-100  px-3 py-1"
                                style={{
                                  width: "91px",
                                  height: "31px",
                                  borderRadius: "50px",
                                  color: " rgba(1, 195, 125, 1)",
                                  background: " rgba(242, 252, 248, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                Running
                              </span>
                            )}
                          </td>
                          <td  className="pt-4 text-center">
                            <div>
                                <button 
                                  onClick={() => {
                                    handleViewClick(item.id, item.campaign_name);
                                  }}
                                className='btn bg-white btn-outline-primary text-primary'>View Details</button>
                            </div>
                          </td>
                      
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
                <>
                  <div
                    class="modal fade"
                    id="exampleModal1"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1
                            class="modal-title fs-5 text-success"
                            id="exampleModalLabel"
                          >
                            {ModalData}
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          Are you sure you want to delete this item? This action
                          cannot be undone.
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            id="liveAlertBtn"
                            type="button"
                            class="btn btn-danger"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal2"
                            data-bs-dismiss="modal"
                            onClick={() => handleDelete(ModalData)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //////////// */}
                  <div className="py-3">
                    {/* <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onNextPage={() => handleClick("next")}
                      onPrevPage={() => handleClick("prev")}
                      onPageClick={(e) => handlePageClick(e)}
                    /> */}
                  </div>
                </>
              </>
            )}
          </div>
       </div>
   
  );
};

export default LeadManagement;