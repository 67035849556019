// src/components/NoIndex.js
import React from 'react';
import { Helmet } from 'react-helmet';

const NoIndex = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default NoIndex;
