import { useState,useEffect } from "react";
import {  ToastContainer } from "react-toastify";

import { Link } from "react-router-dom";
import ss1 from "../../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import ss2 from "../../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../../images/LandingPageImage/web_inbox_team_1x.webp";
import ArrowLeft from "../../../images/ArrowLeft.svg"
import ArrowRight from "../../../images/ArrowRight.svg"
import CampaignManagment from "../../../images/Campaign Management.png";
import userManagement from "../../../images/User Management.png";
import Dashboard from "../../../images/Dashboard Overview.png";
import chatbot from "../../../images/Chat Bots.png";
import chat from "../../../images/Chat & Contact Management.png";
import orderMangement from "../../../images/Order & Catalogue Management.png";
import LeadManagement from "../../../images/Lead Management.png";
import templateManagment from "../../../images/Template Management.png";

const cardData = [
  {
    id: 1,
    image: chat, // Replace with actual image name
    title: "Chat & Contact Management",
    description:
      "Manage your WhatsApp conversations and contacts efficiently in one place, ensuring you never miss an important lead or customer query.",
  },
  {
    id: 2,
    image: templateManagment, // Replace with actual image name
    title: "Template Management",
    description:
      "Simplify your messaging with reusable templates that you can easily create, manage, and deploy for consistent communication.",
  },
  {
    id: 3,
    image: CampaignManagment, // Replace with actual image name
    title: "Campaign Management",
    description:
      "Launch, monitor, and adjust your marketing campaigns on WhatsApp with ease, all from a single dashboard.",
  },
  {
    id: 4,
    image: chatbot, // Replace with actual image name
    title: "Chat Bots",
    description:
      "Set up chatbots to handle routine inquiries and engage with customers 24/7, ensuring prompt responses and increased satisfaction.",
  },
  {
    id: 5,
    image: LeadManagement, // Replace with actual image name
    title: "Lead Management",
    description:
      "Keep track of potential customers and convert leads more effectively with an organized and actionable lead management system.",
  },
  {
    id: 6,
    image: orderMangement, // Replace with actual image name
    title: "Order & Catalogue Management",
    description:
      "Manage your product catalog and orders seamlessly, allowing for easy updates and a streamlined order process.",
  },
  {
    id: 7,
    image: userManagement, // Replace with actual image name
    title: "User Management",
    description:
      "Manage user permissions and roles efficiently to ensure secure and appropriate access to your app’s features.",
  },
  {
    id: 8,
    image: Dashboard, // Replace with actual image name
    title: "Dashboard Overview",
    description:
      "View key metrics and performance indicators in a centralized dashboard, giving you quick insights to drive decision-making.",
  },
];
const BlogSection=()=>{

    const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === cardData.length - 3 ? 0 : prevIndex + 1
    );
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    
return (

    <>
        <ToastContainer />

        <div className="d-flex justify-content-center  padding-5-rem bg-soil-landing py-5 my-5">
        <div>
          <div className="d-flex justify-content-center text-center text-2-2 pb-2">
            Blog updates
          </div>
          <div className="d-flex justify-content-center pb-4">
            Stay informed with our latest blog posts and join the wave of
            innovation.
          </div>

          <div className="container">
            <div className="card-container">
            {cardData.slice(index, index + (isMobile ? 1 : 3)).map((card) => (
                <div key={card.id} className="card p-3">
                  <img loading="lazy" src={card.image} alt={card.title} className="pb-4" />
                  <p className="pb-2 single-line-css text-3-2">{card.title}</p>
                  <p className="pb-2">{card.description.slice(0, 80)}....</p>
                  <p>
                    <Link
                        to="/post"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Read more →
                      </Link>
                    </p>
                </div>
              ))}
            </div>

            <div className=" d-flex justify-content-center pt-3">
              <div
                onClick={handlePrevious}
                disabled={index === 0}
                className="cursor-pointer me-5"
              >
                <img loading="lazy" src={ArrowLeft} alt="" />
              </div>
              <div
                onClick={handleNext}
                disabled={index >= cardData.length - 3}
                className="cursor-pointer "

              >
            <img loading="lazy" src={ArrowRight} alt="" />
              </div>
            </div>

            {/* <div className="buttons-blog d-flex justify-content-center">
              <div
                onClick={handlePrevious}
                disabled={index === 0}
                className="button-blog p-1 pe-2 mx-2 text-dark"
              >
                ◄
              </div>
              <div
                onClick={handleNext}
                disabled={index >= cardData.length - 3}
                className=" p-1 ps-2 button-blog text-dark"
              >
                ►
              </div>
            </div> */}
          </div>
        </div>
      </div>
    
    </>
)
}

export default BlogSection;