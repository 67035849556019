import React, { useState, useEffect } from "react";
import women from "../../images/welcome.jpg"
import { Link } from "react-router-dom"; 

const WelcomePage = ({profiles}) => {
    const handleDashboardClick = () => {
        // window.location.href = "http://localhost:3000/Dashboard";
        window.location.href = "https://www.anlook.com/Dashboard";

      };

  return (
  
  <div className="d-flex justify-content-center align-items-center p-5 flex-column h-100">
   

    <h1 className="welcome-title "> Welcome to Anlook </h1>
    <h2 className="welcome-subtitle">Hi {profiles.username || "User"}</h2>
    
    <div className="py-5">
   <img src={women} alt="Anlook Logo" height={300} />
    </div>
    
    <div className="welcome-message mt-3 text-center">
        {/* <p className="text-center text-primary welcome-subtitle">Upgrade your Plan</p> */}
      <p>Your journey starts now. Enjoy full access to all premium features during your trial. Dive in, explore, and experience everything we have to offer!</p>
 
      <div className="d-flex justify-content-center align-items-center">
      <Link to="/Dashboard">
      <button className="blue-button">Get Started</button>
      </Link>
      </div>
    </div>
    {/* <div className="text-center text-3-2 py-3">
<p className="">Go To <span className=" cursor-pointer">
<Link to="/Dashboard" className="no-decoration text-primary">
    Dashboard
      </Link>
    </span></p>
    </div> */}
    </div>
  );
 };
export default WelcomePage;




   {/* Calling Section */}
//    <div className="d-flex justify-content-center bg-light-brown  py-5  ">
//    <div className="row col-12 shadow bg-white border-redius-20 responsive-container">
//      <div className="col-12 col-lg-7">
//        <div className="p-4">
//          <div className="text-5-2">Maximize your advertising ROI,</div>
//          <div className="text-5-2">
//            Transform clicks into captivating conversations!
//          </div>

//          <div className="py-2">
//            Unleash Quality Leads, Nurture, and Interact through WhatsApp
//            after Clicks!
//          </div>

//          <div className="pt-4">
//            <button className=" shadow btn bg-green-filter  p-2 px-4 me-5 text-white d-flex align-items-center">
//              Talk to an Expert
//              <img
//                loading="lazy"
//                src={signup}
//                className="ps-2"
//                alt="signup"
//              />
//            </button>
//          </div>
//        </div>
//      </div>
//      <div className="col-lg-5 col-12 d-flex justify-content-center align-items-center">
//        <img
//          loading="lazy"
//          src={callImage}
//          alt="group9"
//          className="image-cover"
//        />
//      </div>
//    </div>
//  </div>