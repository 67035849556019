import React, { useState } from "react";
import { Helmet } from "react-helmet";
import LandingNav from "./Landing_Nav";
import signup from "../../images/signup.svg";
import group9 from "../../images/Group9.png";
import LandingFooter from "./Landing_Footer";
import ss1 from "../../images/LandingPageImage/web_campaign_broadcast_1x.webp";
import CampaignManagment from "../../images/Campaign Management.png";
import userManagement from "../../images/User Management.png";
import Dashboard from "../../images/Dashboard Overview.png";
import chatbot from "../../images/Chat Bots.png";
import chat from "../../images/Chat & Contact Management.png";
import orderMangement from "../../images/Order & Catalogue Management.png";
import LeadManagement from "../../images/Lead Management.png";
import templateManagment from "../../images/Template Management.png";

import { Link } from "react-router-dom";

import ss2 from "../../images/ss2.jpg";

// import rectangle from "../images/rectangle.png";
import rectangle from "../../images/Rectangle.png";

const cardData = [
  {
    id: 1,
    image: chat, // Replace with actual image name
    title: "Chat & Contact Management",
    description:
      "Manage your WhatsApp conversations and contacts efficiently in one place, ensuring you never miss an important lead or customer query.",
  },
  {
    id: 2,
    image: templateManagment, // Replace with actual image name
    title: "Template Management",
    description:
      "Simplify your messaging with reusable templates that you can easily create, manage, and deploy for consistent communication.",
  },
  {
    id: 3,
    image: CampaignManagment, // Replace with actual image name
    title: "Campaign Management",
    description:
      "Launch, monitor, and adjust your marketing campaigns on WhatsApp with ease, all from a single dashboard.",
  },
  {
    id: 4,
    image: chatbot, // Replace with actual image name
    title: "Chat Bots",
    description:
      "Set up chatbots to handle routine inquiries and engage with customers 24/7, ensuring prompt responses and increased satisfaction.",
  },
  {
    id: 5,
    image: LeadManagement, // Replace with actual image name
    title: "Lead Management",
    description:
      "Keep track of potential customers and convert leads more effectively with an organized and actionable lead management system.",
  },
  {
    id: 6,
    image: orderMangement, // Replace with actual image name
    title: "Order & Catalogue Management",
    description:
      "Manage your product catalog and orders seamlessly, allowing for easy updates and a streamlined order process.",
  },
  {
    id: 7,
    image: userManagement, // Replace with actual image name
    title: "User Management",
    description:
      "Manage user permissions and roles efficiently to ensure secure and appropriate access to your app’s features.",
  },
  {
    id: 8,
    image: Dashboard, // Replace with actual image name
    title: "Dashboard Overview",
    description:
      "View key metrics and performance indicators in a centralized dashboard, giving you quick insights to drive decision-making.",
  },
];

const Blogs = ({ onLoginClick }) => {
  const [index, setIndex] = useState(0);
  const blogSchemaData = {
    "@context": "https://schema.org",
    "@type": "Blog",
    name: "Anlook Blog",
    description:
      "Insights and updates on WhatsApp automation and industry trends.",
    url: "https://www.anlook.com/blogs",
    blogPost: cardData.map((card) => ({
      "@type": "BlogPosting",
      headline: card.title,
      image: card.image,
      description: card.description,
      author: {
        "@type": "Person",
        name: "Author Name", // Replace with the actual author's name
      },
      publisher: {
        "@type": "Organization",
        name: "Anlook",
        logo: {
          "@type": "ImageObject",
          url: "https://www.anlook.com/logo.png", // Replace with the actual logo URL
        },
      },
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.anlook.com/blogs",
      },
      datePublished: "2023-07-01", // Replace with the actual publish date
      dateModified: "2023-07-01", // Replace with the actual modified date
    })),
  };

  const handlePrevious = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === cardData.length - 3 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Helmet>
        <title>Anlook Blog - See What the World Is Talking About</title>
        <meta
          name="description"
          content="Explore the Anlook Blog for the latest insights, tips, and trends in WhatsApp automation and business communication. Learn how to enhance customer engagement and boost productivity with expert advice from Anlook."
        />
        <meta
          name="keywords"
          content="Anlook blog, WhatsApp automation tips, business communication insights, customer engagement strategies, productivity tips, WhatsApp marketing trends, communication tools, expert advice, Anlook insights, latest trends"
        />
        <script type="application/ld+json">
          {JSON.stringify(blogSchemaData)}
        </script>
      </Helmet>
      <div className="bg-green-removed">
        <LandingNav onLoginClick={onLoginClick} />

        <div className="text-2-2 text-center py-5 px-2 ">
          Get Insightful views About WhatsApp Business
        </div>

        {/* Cards */}
        <div className="d-flex   pb-5 padding-5-rem  ">
          <div>
            <div className="row col-12 card-container-blo">
              {cardData.map((card) => (
                <div key={card.id} className="col-lg-3 col-12 p-0 m-0 card-blo">
                  <div className=" bg-white  shadow p-3  m-2">
                    <div className="image-container">
                      <img
                        src={card.image}
                        alt={card.title}
                        style={{
                          objectFit: "fit",
                          width: "100%",
                          height: "100%",
                        }}
                        className=""
                      />
                    </div>
                    <p className=" single-line-css py-3 m-0 text-3-2">
                      {" "}
                      <Link to="/post" className="text-3-2  ">
                        {" "}
                        {card.title}
                      </Link>
                    </p>

                    <p className="">
                      {card.description.slice(0, 80)}....
                     
                    </p>
                    <p>
                    <Link
                        to="/post"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Read more →
                      </Link>
                    </p>
                    {/* <p className=" m-0 "></p> */}
                  </div>
                </div>
              ))}
            </div>

            <div>
              <div className="d-flex justify-content-center pt-5">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className=" shadow btn  p-2 px-4 me-5 text-white d-flex align-items-center"
                >
                  Load More
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center bg-light-brown  py-5  ">
          <div className="row col-12 responsive-container shadow bg-white border-redius-20 ">
            <div className="col-12 col-lg-7">
              <div className="p-4">
                <div className="text-5-2">Supercharge your ad investments,</div>
                <div className="text-5-2">
                  Turn clicks into engaging conversations!
                </div>

                <div className="py-2">
                  Unlock Quality Leads, Nurture, and Engage with Post-Click
                  WhatsApp Interactions!
                </div>

                <div className="pt-4">
                  <button
                    style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                    className=" shadow btn  p-2 px-4 me-5 text-white d-flex align-items-center"
                    //   onClick={onLoginClick}
                  >
                    Talk to an Expert
                    <img src={signup} className="ps-2" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 d-flex justify-content-center">
              <img src={group9} alt="" width={250} />
            </div>
          </div>
        </div>

        <LandingFooter />
      </div>
    </>
  );
};

export default Blogs;