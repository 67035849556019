import React, { useRef, useEffect, useState, useCallback } from "react";
import { useGesture } from "react-use-gesture";

const containerStyle = {
  position: "absolute",
  width: "150px",
  height: "150px",
  border: "1px solid #ccc"
};

const authorStyle = ({ position }) => ({
  position: "absolute",
  [position]: "0",
  top: "50%",
  height: "50px",
  width: "50px",
  background: "#333",
  transform: "translateY(-50%)",
  cursor: "pointer"
});

export default function App({ id, x, y, onUpdate }) {
  const boxRef = useRef(null);
  const l = useRef(null);
  const r = useRef(null);
  const [left, setLeft] = useState(x);
  const [top, setTop] = useState(y);
  const record = useRef({ x: 0, y: 0 });

  const dnd = useGesture(
    {
      onDragStart() {
        record.current = {
          x: left,
          y: top
        };
      },
      onDrag({ movement }) {
        setLeft(record.current.x + movement[0]);
        setTop(record.current.y + movement[1]);
        updateAnchorPos();
      }
    },
    { domTarget: boxRef, eventOptions: { passive: false } }
  );

  useEffect(dnd, [dnd]);

  const updateAnchorPos = useCallback(() => {
    onUpdate({
      x: left + boxRef.current.offsetWidth / 2,
      y: top + boxRef.current.offsetHeight / 2
    });
  }, [left, top, onUpdate]);

  useEffect(() => {
    updateAnchorPos();
  }, [updateAnchorPos]);

  return (
    <div className="bg-light Z-index-css" ref={boxRef} style={{ ...containerStyle, left, top, }}>
      {/* <div style={authorStyle({ position: "left" })} /> */}
      {/* <div style={authorStyle({ position: "right" })} /> */}
      <div className="text-white bg-success">Condition</div>
    </div>
  );
}
