import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import backgroundImage from "../../images/whats.svg";
import { FormControl, Form } from "react-bootstrap";
import close from "../../images/cross-svgrepo-com.svg";
import { toast, ToastContainer } from "react-toastify";
import NoIndex from "../NoIndex";
const CampaignDetails = ({ userID }) => {
  //  Known State for Kewal
  const [templates, setTemplates] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [AllCampaign, setAllCampaign] = useState([]);
  const [Campaign, setCampaign] = useState();
  const [name, setname] = useState();
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [Interval, setInterval] = useState();
  const [Members, setMembers] = useState();
  const [Status, setStatus] = useState();
  const [startTime, setstartTime] = useState();
  const [Unit, setUnit] = useState();
  const [endTime, setendTime] = useState();
  const [EndPeriod, setEndPeriod] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [Submit, setSubmit] = useState("notSubmit");
  const [repetition, setRepetition] = useState("");
  const [intervalUnit, setIntervalUnit] = useState("minutes");

  //   Known State for Kewal
  const [searchTerm, setSearchTerm] = useState("");
  const [dataToSend, setDataToSend] = useState([]);
  const [searchSelectTerm, setsearchSelectTerm] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [buttonText, setButtonText] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateLanguage, setTemplateLanguage] = useState("");
  const [templateStatus, setTemplateStatus] = useState("");
  const [templateCategory, setTemplateCategory] = useState("");
  const [headerVariables, setHeaderVariables] = useState({});
  const [bodyVariables, setBodyVariables] = useState({});
  const [ErrorAudience, setErrorAudience] = useState('')
  const [searchResults, setSearchResults] = useState([]);
  const [searchSelectResults, setsearchSelectResults] = useState([]);
  const [headerParams, setHeaderParams] = useState([]);
  const [bodyParams, setBodyParams] = useState([]);
  const convertToMinutes = (value, unit) => {
    switch (unit) {
      case "minutes":
        return value;
      case "hours":
        return value * 60;
      case "days":
        return value * 60 * 24;
      default:
        return value;
    }
  };
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  // fetching the templates using the unlook api.
  const getTemplates = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTemplates(data?.response.data);
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTemplates();
  }, []);

  // fetching the Campaign using the unlook api.
  const getCampaign = async (e) => {
    try {
      // setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
        campaignName: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Campaign/getCampaigns`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setAllCampaign(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      // setLoading(false); // Set loading to false after API call?
    }
  };

  useEffect(() => {
    getCampaign();
  }, []);

  // getting contacts through api

  const getContacts = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Campaign/getContacts`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setContacts(data?.data);
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    setname(Campaign?.campaign_name);
    setRepetition(Campaign?.campaign_interval);
    setMembers(Campaign?.members);
    setHeaderParams(Campaign?.headerParams);
    setBodyParams(Campaign?.bodyParams);
    setTemplateName(Campaign?.template_id);
    setStatus(Campaign?.status);
  }, [Campaign]);

  const updateParams = () => {
    const updatedHeaderParams =
      Object.keys(headerVariables).length > 0
        ? Object.values(headerVariables)
        : [];
    const updatedBodyParams =
      Object.keys(bodyVariables).length > 0 ? Object.values(bodyVariables) : [];
    setHeaderParams(updatedHeaderParams);
    setBodyParams(updatedBodyParams);
  };
  const replaceVariablesHeader = (headerText) => {
    return headerText.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = headerParams[variableIndex];
      return variable ? variable : ""; // Return ##1## if present, otherwise return nothing
    });
  };
  const replaceVariablesBody = (bodyText) => {
    return bodyText.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = bodyParams[variableIndex];
      return variable ? variable : ""; // Return ##1## if present, otherwise return nothing
    });
  };
  useEffect(() => {
    updateParams();
  }, [headerVariables, bodyVariables]);

  // Hook to get the current URL location
  const location = useLocation();

  // Function to extract query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("campaignId");
    const name = searchParams.get("campaignName");
    const start = searchParams.get("startDate");
    const end = searchParams.get("endDate");
    const interval = searchParams.get("interval");
    const template = searchParams.get("template");
    const channel = searchParams.get("channel");
    const status = searchParams.get("status");
    const members = searchParams.get("members");

    setCampaignId(id);
    setCampaignName(name);

    // setSelectedItems(members);
  };
  const handleTemplateSelect = (e) => {
    const selectedValue = e.target.value;
    const template = templates.find((t) => t.name === selectedValue);

    if (!template) {
      // Handle case where template is not found
      console.error(`Template not found for value: ${selectedValue}`);
      resetTemplateStates();
      return;
    }

    setHeaderText("");
    setBodyText("");
    setFooterText("");
    setButtonText("");

    setSelectedTemplate(template);
    setTemplateName(template.name);
    setTemplateLanguage(template.language);
    setTemplateStatus(template.status);
    setTemplateCategory(template.category);

    const initialHeaderVariables = {};
    const initialBodyVariables = {};

    if (template.components) {
      template.components.forEach((component) => {
        if (component.text) {
          const matches = component.text.match(/{{\d+}}/g);
          if (matches) {
            matches.forEach((match) => {
              if (component.type === "HEADER") {
                initialHeaderVariables[match] = "";
              } else {
                initialBodyVariables[match] = "";
              }
            });
          }
        }
      });

      setHeaderVariables(initialHeaderVariables);
      setBodyVariables(initialBodyVariables);

      template.components.forEach((component) => {
        switch (component.type) {
          case "HEADER":
            setHeaderText(component.text || "");
            break;
          case "BODY":
            setBodyText(component.text || "");
            break;
          case "FOOTER":
            setFooterText(component.text || "");
            break;
          case "BUTTONS":
            if (component.buttons && Array.isArray(component.buttons)) {
              // Map over the buttons array to extract the text from each button
              setButtonText(component.buttons.map((button) => button.text));
            }
            break;
          default:
            break;
        }
      });
    }
  };
  const resetTemplateStates = () => {
    setSelectedTemplate(null);
    setHeaderText("");
    setBodyText("");
    setFooterText("");
    setButtonText("");
    setHeaderVariables({});
    setBodyVariables({});
    setTemplateName("");
    setTemplateLanguage("");
    setTemplateStatus("");
    setTemplateCategory("");
  };
  const handleHeaderInputChange = (variable, value) => {
    setHeaderVariables((prevState) => ({
      ...prevState,
      [variable]: value,
    }));
  };

  const handleBodyInputChange = (variable, value) => {
    setBodyVariables((prevState) => ({
      ...prevState,
      [variable]: value,
    }));
  };

  // Fetch query parameters on component mount
  useEffect(() => {
    getQueryParams();
  }, [location.search]); // Re-run the effect when the search query changes

  useEffect(() => {
    // to set starting date and time of the campaign
    const separateDateTime = (dateTimeString) => {
      const dateTimeParts = dateTimeString?.split(" ");

      if (dateTimeParts?.length >= 2) {
        const datePart = dateTimeParts[0];
        const timePart = dateTimeParts.slice(1).join(" "); // Handle remaining parts as time
        return { date: datePart, time: timePart };
      } else {
        return { date: "", time: "" }; // Return empty strings if the format does not match
      }
    };

    const { date, time } = separateDateTime(Campaign?.campaign_start);

    // to set Ending date and time of the campaign

    setStartDate(date);
    setstartTime(time);

    // setEndPeriod(eperiod);
  }, [Campaign]);

  useEffect(() => {
    // to set starting date and time of the campaign
    const separateDateTime = (dateTimeString) => {
      const dateTimeParts = dateTimeString?.split(" ");

      if (dateTimeParts?.length >= 2) {
        const datePart = dateTimeParts[0];
        const timePart = dateTimeParts.slice(1).join(" "); // Handle remaining parts as time
        return { date: datePart, time: timePart };
      } else {
        return { date: "", time: "" }; // Return empty strings if the format does not match
      }
    };

    const { date, time } = separateDateTime(Campaign?.campaign_end);
    // to set Ending date and time of the campaign

    setEndDate(date);
    setendTime(time);
  }, [Campaign]);

  useEffect(() => {
    console.log(selectedTemplate);
    if (selectedTemplate) {
      selectedTemplate.components.forEach((component) => {
        switch (component.type) {
          case "HEADER":
            setHeaderText(component.text || "");
            break;
          case "BODY":
            setBodyText(component.text || "");
            break;
          case "FOOTER":
            setFooterText(component.text || "");
            break;
          case "BUTTONS":
            if (component.buttons && Array.isArray(component.buttons)) {
              // Map over the buttons array to extract the text from each button
              setButtonText(component.buttons.map((button) => button.text));
            }
            break;
          default:
            break;
          //
        }
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // console.log(templates);
    templates.map((item) => {
      if (item.name == templateName) {
        setSelectedTemplate(item);
      }
    });
  }, [templates]);
  ///

  useEffect(() => {
    AllCampaign.map((item) => {
      if (item.id == campaignId) {
        setCampaign(item);
      }
    });

    console.log(Campaign);
  }, [AllCampaign]);

  const [startDateString, setStartDateString] = useState("");
  const [endDateString, seEndtDateString] = useState("");
  const [starttimeString, setStartTimeString] = useState();
  const [endtimeString, setEndTimeString] = useState();

  useEffect(() => {
    if (StartDate && startTime) {
      // Format the date
      const dateParts = StartDate.split("/");
      const formattedDate =
        dateParts[2] +
        "-" +
        dateParts[0].padStart(2, "0") +
        "-" +
        dateParts[1].padStart(2, "0");
      seEndtDateString(formattedDate);

      // Format the time
      const [time, period] = startTime.split(" ");
      let [hours, minutes, seconds] = time.split(":");
      if (period === "PM" && hours !== "12") {
        hours = (parseInt(hours) + 12).toString();
      }
      if (period === "AM" && hours === "12") {
        hours = "00";
      }
      const formattedTime =
        hours.padStart(2, "0") + ":" + minutes.padStart(2, "0");
      setStartTimeString(formattedTime);
    }
  }, [StartDate, startTime]);

  useEffect(() => {
    if (EndDate && endTime) {
      // Format the date
      const dateParts = EndDate.split("/");
      const formattedDate =
        dateParts[2] +
        "-" +
        dateParts[0].padStart(2, "0") +
        "-" +
        dateParts[1].padStart(2, "0");
      setStartDateString(formattedDate);

      // Format the time
      const [time, period] = endTime.split(" ");
      let [hours, minutes, seconds] = time.split(":");
      if (period === "PM" && hours !== "12") {
        hours = (parseInt(hours) + 12).toString();
      }
      if (period === "AM" && hours === "12") {
        hours = "00";
      }
      const formattedTime =
        hours.padStart(2, "0") + ":" + minutes.padStart(2, "0");
      setEndTimeString(formattedTime);
    }
  }, [EndDate, endTime]);

  useEffect(() => {
    if (EndDate) {
      const dateParts = EndDate?.split("/");
      const formattedDate =
        dateParts[2] +
        "-" +
        dateParts[0].padStart(2, "0") +
        "-" +
        dateParts[1].padStart(2, "0");
      seEndtDateString(formattedDate);
    }
  }, [EndDate]);

  //  input value for each placeholder found inside the templates
  const [input, setinput] = useState();
  const [bodyInput, setBodyInput] = useState();
  // placeholder counting function to fetch numer of place holder inside a template

  useEffect(() => {
    // Update dataToSend whenever selectedItems change
    const newDataToSend = selectedItems.map((item) => ({
      member_id: item.id,
      name: item.contact_name,
      mobile: item.contact_number,
    }));
    setDataToSend(newDataToSend);
  }, [selectedItems]);

  const handleToggleSelect = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      handleDeselect(item);
    } else {
      handleSelect(item);
    }
  };
  const handleSelect = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const handleDeselect = (item) => {
    const updatedItems = selectedItems.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    const remove = searchSelectResults.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    setSelectedItems(updatedItems);
    setsearchSelectResults(remove);
  };

  const SelectableItem = ({ item }) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id
    );

    return (
      <option
        className="my-2 bg-light p-2 border-15-only"
        value={item.id}
        onClick={() => handleToggleSelect(item)}
      >
        {isSelected && "✅"}
        {!isSelected && <span>⬜</span>}

        <div className="row">
          <div className="col-12">
            <span className="ps-3">
              {item.contact_name}
            </span>
          </div>

          <div className="col-12">
            <div style={{ paddingLeft: "10px" }}>
             <b> {" "}Tag - {item.tags.join(", ")}</b>
            </div>
          </div>
        </div>
      </option>
    );
  };
  const handleSelectAll = () => {
    const newSelectedItems = [...selectedItems];

    filteredContacts.forEach((contact) => {
      if (!newSelectedItems.some((item) => item.id === contact.id)) {
        newSelectedItems.push(contact);
      }
    });

    setSelectedItems(newSelectedItems);
  };
  const handleDeSelectAll = () => {
    setSelectedItems([]);
  };
  const NewCampaign = {
    campaignId: campaignId,
    userid: userID,
    campaignName: campaignName,
    startTime: `${startDateString} ${starttimeString}`,
    endTime: `${endDateString} ${endtimeString}`,
    interval: convertToMinutes(repetition, intervalUnit),
    channel: "Whatsapp",
    members: dataToSend,
    headerParams: headerParams,
    bodyParams: bodyParams,
    template: templateName,
    status: Status,
  };

  const EditOldCampaign = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify(NewCampaign);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://anlookuat.remittpe.com/api/Campaign/editCampaign`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "01") {
        toast.error(data.responseMessage);
      } else {
        toast.success(data.responseMessage);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!Members || !contacts || !Array.isArray(Members)) {
      console.error("Invalid campaign data");
      return;
    }

    const memberMap = new Map(
      Members.map((member) => [member.member_id, member])
    );

    const filtered = contacts
      .filter((contact) => memberMap.has(contact.id))
      .map((contact) => {
        const member = memberMap.get(contact.id);
        return {
          id: contact.id,
          contact_name: member.name,
          contact_number: member.mobile,
          status: contact.status,
          contact_email: contact.contact_email,
          gender: contact.gender,
          tags: contact.tags,
          indate: contact.indate,
          update: contact.update,
          last_conversation: contact.last_conversation,
          unread_count: contact.unread_count,
        };
      });

    setSelectedItems([...filtered]);
    if (Interval < 60) {
      setUnit("minutes");
    } else {
      setUnit("hours");
    }
  }, [contacts, Members]);

  useEffect(() => {
    const filteredContacts = contacts.filter((contact) =>
      contact.contact_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredContacts);
  }, [searchTerm]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const filteredContacts = selectedItems.filter((contact) =>
      contact.contact_name
        .toLowerCase()
        .includes(searchSelectTerm.toLowerCase())
    );
    setsearchSelectResults(filteredContacts);
  }, [searchSelectTerm]);
  const handleSelectdSearch = (event) => {
    setsearchSelectTerm(event.target.value);
  };
    // ################################################################################### *\
    const [searchName, setSearchName] = useState("");
    const [searchTag, setSearchTag] = useState("");
    const [isNameFilterActive, setIsNameFilterActive] = useState(false);
    const [isTagFilterActive, setIsTagFilterActive] = useState(false);
    const filteredContacts = contacts.filter((contact) => {
      // Check if a name filter is active
      const nameMatch = searchName
        ? contact.contact_name.toLowerCase().includes(searchName.toLowerCase())
        : false; // Only true if name filter is active
  
      // Check if a tag filter is active
      const tagMatch = searchTag
        ? contact.tags.some((tag) => {
            const tagLower = tag.trim().toLowerCase();
            const searchTagLower = searchTag.trim().toLowerCase();
            return tagLower.includes(searchTagLower);
          })
        : false; // Only true if tag filter is active
  
      // Return true only if the respective filter is active
      return (
        (searchName && nameMatch) ||
        (searchTag && tagMatch) ||
        (!searchName && !searchTag)
      );
    });
    const handleNameChange = (e) => {
      setSearchName(e.target.value);
      setIsNameFilterActive(true);
      setIsTagFilterActive(false);
    };
  
    const handleTagChange = (e) => {
      setSearchTag(e.target.value);
      setIsTagFilterActive(true);
      setIsNameFilterActive(false);
    };
  
    const clearFilters = () => {
      setSearchName("");
      setSearchTag("");
      setIsNameFilterActive(false);
      setIsTagFilterActive(false);
    };
    useEffect(() => {
      const filteredContacts = contacts.filter((contact) =>
        contact.contact_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredContacts);
    }, [searchTerm]);
    useEffect(() => {
      const filteredContacts = selectedItems.filter((contact) =>
        contact.contact_name
          .toLowerCase()
          .includes(searchSelectTerm.toLowerCase())
      );
      setsearchSelectResults(filteredContacts);
    }, [searchSelectTerm]);

  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>
      <div
        className="p-4 bg-white "
        style={{
          height: "100vh",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <div className=" px-4  justify-content-center">
          <p className="CreateCampaign">Edit Campaign</p>
          <div>
            <div
              class=" row col-12"
              style={{
                borderRadius: "10px",
                border: "1px solid #ccc",
                // backgroundColor: "rgba(1, 195, 125, 0.05)",
              }}
            >
              <div className="d-flex justify-content-between px-3 pt-4" style={{background:"rgba(75, 129, 244, 0.05)", borderRadius:"10px 10px 0px 0px"}}>
                <p className="campaignheading">
                  <b>Campaign ID: {campaignId}</b>
                </p>
                <p className="campaignheading">
                  <p style={{ color: "blue" }}>
                    Note - If values remain unchanged, previously defined values
                    will be submitted.
                  </p>
                </p>
              </div>
              <hr className="p-0 m-0" />

              <div className="bg-white p-3" style={{ borderRadius: "10px" }}>
                <div className="campaignbox1 my-4">
                  <div className="step1">
                    <div className="">
                      <p className="campaignheading text-3-2">
                        Edit Name
                        <p className="campaignSubheading">
                          Choose a descriptive name that reflects your target
                          audience and campaign goals.
                        </p>
                      </p>
                      <div className="mb-3 col-6">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter campaign name"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          maxlength="25"
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                        style={{ textAlign: "left" }}
                      >
                        <div>
                          <p className="campaignheading text-3-2">
                            Schedule Date and Time
                            <p className="campaignSubheading">
                              Set the start date and time for your campaign.
                            </p>
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className=" col-6">
                      <div className="d-flex align-items-center">
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName">
                            Form{" "}
                            <div className="">
                              <input
                                type="date"
                                className="form-control  my-1 "
                                placeholder={`${String(StartDate)}`}
                                value={startDateString}
                                onChange={(e) =>
                                  setStartDateString(e.target.value)
                                }
                              />
                            </div>
                          </p>
                        </div>
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName ">
                            Time start{" "}
                            <div className="">
                              <input
                                type="time"
                                id="startTime"
                                className="form-control "
                                value={starttimeString}
                                onChange={(e) =>
                                  setStartTimeString(e.target.value)
                                }
                              />
                            </div>
                          </p>
                        </div>
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName">
                            To{" "}
                            <input
                              type="date"
                              name="date"
                              id="date"
                              placeholder="DD-MM-YYYY"
                              className="form-control  my-1 "
                              value={endDateString}
                              onChange={(e) => seEndtDateString(e.target.value)}
                            />
                          </p>
                        </div>
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName ">
                            Time end{" "}
                            <div className="">
                              <input
                                type="time"
                                id="endTime"
                                className="form-control"
                                value={endtimeString}
                                onChange={(e) =>
                                  setEndTimeString(e.target.value)
                                }
                              />
                            </div>
                          </p>
                        </div>
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName">
                            Interval Unit:{" "}
                            <select
                              className="form-select"
                              value={intervalUnit}
                              onChange={(e) => {
                                setIntervalUnit(e.target.value);
                              }}
                            >
                              <option value="minutes">Minutes</option>
                              <option value="hours">Hours</option>
                              <option value="days">Days</option>
                            </select>
                          </p>
                        </div>
                        <div className="col-3 ps-2">
                          <p className="campaignBoxName ">
                            Select interval:
                            <input
                              type="number"
                              id="inputRepetition"
                              className="form-control"
                              value={repetition}
                              onChange={(e) =>
                                setRepetition(e.currentTarget.value)
                              }
                              min={1}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              <div className="bg-white p-3" style={{ borderRadius: "10px" }}>
                <div className="campaignbox1">
                  <div className="row col-12">
                    <div className="col-8 ">
                      <div className="select-template-menu">
                        <div className="template-menu ">
                          <div className="campaignheading ">{templateName}</div>

                          <div>
                            {selectedTemplate &&
                              selectedTemplate.components && (
                                <div>
                                  {selectedTemplate &&
                                    selectedTemplate.components && (
                                      <div>
                                        {selectedTemplate.components.map(
                                          (component, idx) => (
                                            <div
                                              className="  border-template my-3 p-4 my-4"
                                              key={idx}
                                              style={{
                                                borderRadius: "1px solid #ccc",
                                              
                                              }}
                                            >
                                              <div className="campaignheading mb-2">
                                                {component.type}
                                              </div>
                                              {!component.buttons && (
                                                <p>
                                                  <strong>Text:</strong>{" "}
                                                  {component.text}
                                                </p>
                                              )}
                                              {component.text &&
                                                component.text.match(
                                                  /{{\d+}}/g
                                                ) &&
                                                component.text
                                                  .match(/{{\d+}}/g)
                                                  .map((variable, varIdx) => (
                                                    <div
                                                      className="d-flex py-2"
                                                      key={varIdx}
                                                    >
                                                      <label className="d-flex align-items-center">
                                                        {variable}{" "}
                                                        <span className="text-danger">
                                                          *{" "}
                                                        </span>
                                                      </label>
                                                      {component.type ===
                                                      "HEADER" ? (
                                                        <input
                                                          type="text"
                                                          className="form-control ms-2"
                                                          placeholder={`Enter the value for ${variable}`}
                                                          value={
                                                            headerVariables[
                                                              variable
                                                            ] ||
                                                            headerParams[varIdx]
                                                          }
                                                          onChange={(e) =>
                                                            handleHeaderInputChange(
                                                              variable,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <input
                                                          type="text"
                                                          className="form-control ms-2"
                                                          placeholder={`Enter the value for ${variable}`}
                                                          value={
                                                            bodyVariables[
                                                              variable
                                                            ] ||
                                                            bodyParams[varIdx]
                                                          }
                                                          onChange={(e) =>
                                                            handleBodyInputChange(
                                                              variable,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  ))}
                                              {component.buttons &&
                                                component.buttons.length >
                                                  0 && (
                                                  <div>
                                                    {component.buttons.map(
                                                      (button, bIdx) => (
                                                        <p key={bIdx}>
                                                          {/* {button.type}  */}
                                                          Button {bIdx +
                                                            1}.{" "}
                                                          <span className="text-3-2">
                                                            {button.text}{" "}
                                                          </span>
                                                        </p>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 ">
                      <div className="py-4  align-items-center d-flex  justify-content-center">
                        <></>
                        <div
                          style={{
                            border: "1px solid #E4E4E4",
                            backgroundColor: "white",
                          }}
                        >
                          <div
                            className="pb-2 text2 text-start mx-3 my-2"
                            style={{
                              height: "40px",
                              color: "#000",
                              backgroundColor: "white",
                              textSize: "16px",
                            }}
                          >
                            Message Preview
                          </div>

                          <div
                            className="p-4  d-flex flex-column  "
                            style={{
                              backgroundImage: `url(${backgroundImage})`,
                              width: "20rem",
                              minHeight: "25rem",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              border: "1px solid #E4E4E4",
                              position: "relative",
                            }}
                          >
                            <div
                              className=""
                              style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                              }}
                            >
                              <div className="row chat-Preview p-2 mb-0 mx-3">
                                <div className="header-text">
                                  {replaceVariablesHeader(headerText) || ""}
                                </div>

                                {/* Display body text */}
                                <div className="body-text py-1">
                                  <div>
                                    {replaceVariablesBody(bodyText) ||
                                      "Body Text"}
                                  </div>
                                </div>

                                <div className="footer-text">{footerText} </div>

                                <div className="footer-text text-end">
                                  05:27 PM
                                </div>
                              </div>
                              <div className="button-text p-2 mx-2 mt-0">
                                {buttonText.length > 0 &&
                                  buttonText.map((text, index) => (
                                    <div
                                      className="bg-white text-primary text-center border-template p-2 mb-1"
                                      key={index}
                                    >
                                      {text}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              <div className="col-12 d-flex flex-wrap justify-content-between">
                  <div
                    className="col-12 col-md-4"
                    style={{ paddingRight: "30px" }}
                  >
                    <div
                      className="Audience mt-4"
                      style={{ border: "1px solid #ccc" }}
                    >
                      <p className="select-Audience-heading m-0 py-1 text-start">
                        Available Audience
                      </p>
                      <div className="seletable-available">
                      <div className="d-flex justify-content-between">
                              <FormControl
                                type="text"
                                placeholder="Search by Name"
                                value={searchName}
                                onChange={handleNameChange}
                                disabled={isTagFilterActive}
                                style={{
                                  backgroundColor: isTagFilterActive
                                    ? "#d3d3d3"
                                    : "#ffff", // Gray when disabled, white otherwise
                                  borderColor: isTagFilterActive
                                    ? "#a9a9a9"
                                    : "#ced4da", // Optional: change border color when disabled
                                }}
                                className="text-start my-1 mx-1"
                              />

                              <FormControl
                                type="text"
                                placeholder="Search by Tag"
                                value={searchTag}
                                onChange={handleTagChange}
                                disabled={isNameFilterActive}
                                style={{
                                  backgroundColor: isNameFilterActive
                                    ? "#d3d3d3"
                                    : "#ffff", // Gray when disabled, white otherwise
                                  borderColor: isNameFilterActive
                                    ? "#a9a9a9"
                                    : "#ced4da", // Optional: change border color when disabled
                                }}
                                className="text-start my-1 mx-1"
                              />
                          
                              <div>
                                <button
                                  style={{
                                    // backgroundColor: "rgba(1, 195, 125, 0.05)",
                                    // color: "rgba(1, 195, 125, 1)",
                                    border: "1px solid #ccc",
                                    whiteSpace: "nowrap", // Prevent text from wrapping
                                    padding: "8px 16px", // Adjust padding to fit text
                                    fontSize: "14px", // Adjust font size if needed
                                  }}
                                  className="blue-button px-4 my-1 me-2 w-auto "
                                  onClick={clearFilters}
                                >
                                  Clear All Filters
                                </button>
                              </div>
                            </div>
                       
                        
                         <div className="d-flex justify-content-start p-1">
                              <div
                         
                                className="blue-outline-button px-4 my-1 me-2 w-auto "
                                onClick={handleSelectAll}
                              >
                                Select All
                              </div>
                              <div
                              
                                className="blue-outline-button px-4 my-1 me-2 w-auto "
                                onClick={handleDeSelectAll}
                              >
                                Deselect All
                              </div>
                            </div>
                        <hr className="m-2" />
                        <select
                              multiple
                              onChange={() => setErrorAudience("")}
                              className={`available-box form-select  ${
                                ErrorAudience ? "is-invalid" : ""
                              }`}
                              aria-label="Default select example"
                            >
                              {searchTag === "" && searchName === ""
                                ? contacts.map((item) => (
                                    <>
                                      <SelectableItem
                                        key={item.id}
                                        item={item}
                                      />
                                     
                                    </>
                                  ))
                                : filteredContacts.map((item) => (
                                    <>
                                      <SelectableItem
                                        key={item.id}
                                        item={item}
                                      />
                                      
                                    </>
                                  ))}
                              {}
                            </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-8">
                    <div
                      className="selected-Audience my-4"
                      style={{ border: "1px solid #cccc" }}
                    >
                      <div>
                        <p className="select-Audience-heading m-0 py-1 text-start">
                          Selected Audience
                        </p>
                        <div
                          className="bg-white w-auto"
                          style={{
                            borderRadius: "0px 0px 10px 10px",
                            border: "1px solid white",
                          }}
                        >
                          <div className="selectable-available">
                            <div className="d-flex">
                              <FormControl
                                type="text"
                                placeholder="Search..."
                                value={searchSelectTerm}
                                style={{ backgroundColor: "#ffff" }}
                                className="text-start my-1 mx-1"
                                onChange={(e) => handleSelectdSearch(e)}
                              />
                              <button
                                className="blue-outline-button px-4 my-1 me-2 w-auto"
                                onClick={handleDeSelectAll}
                              >
                                Clear
                              </button>
                            </div>
                            <hr className="m-2" />

                            <div style={{ borderRadius: "10px" }}>
                              <div
                                className="selected-box mx-2"
                                style={{ borderRadius: "10px" }}
                              >
                                <div
                                  className="d-flex flex-wrap"
                                  style={{ borderRadius: "10px" }}
                                >
                                  {searchSelectTerm === ""
                                    ? selectedItems.map((item, index) => (
                                        <div
                                          className="selected-bubble mx-1 my-1"
                                          key={index}
                                        >
                                          <div style={{ paddingLeft: "8px" }}>
                                            {item.contact_name}
                                            <button
                                              type="button"
                                              className="xbutton btn round"
                                              onClick={() =>
                                                handleDeselect(item)
                                              }
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                            >
                                              <img src={close} alt="logo" />
                                            </button>
                                          </div>
                                        </div>
                                      ))
                                    : searchSelectResults.map((item, index) => (
                                        <div key={index}>
                                          <div className="selected-bubble mx-1 my-1">
                                            <div style={{ paddingLeft: "8px" }}>
                                              {item.contact_name}
                                              <button
                                                type="button"
                                                className="xbutton btn round"
                                                onClick={() =>
                                                  handleDeselect(item)
                                                }
                                              >
                                                x
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

      
            </div>
            <div className="d-flex align-items-start m-0 pt-3 pb-3">
                <button
                 
                  className="blue-button px-4 me-3 text-white"
                  onClick={() => EditOldCampaign()}
                >
                  Submit
                </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignDetails;
