import React, { useState } from "react";
import pig from "../../images/Bank.svg";
import cross from "../../images/Cross.svg";
import coin from "../../images/Cash-Coin.svg";

const PayoutSection = () => {
  // Dummy payout data
  const payoutRequestsData = [
    { id: 1, client: "Client A", amount: "₹10,000", status: "Pending" },
    { id: 2, client: "Client B", amount: "₹5,500", status: "Approved" },
    { id: 3, client: "Client C", amount: "₹8,750", status: "Rejected" },
  ];

  const ordersData = [
    { id: "O1", client: "Client A", earnings: "₹2,000", date: "2024-09-01" },
    { id: "O2", client: "Client B", earnings: "₹5,500", date: "2024-09-02" },
  ];

  // State for search inputs
  const [payoutSearch, setPayoutSearch] = useState("");
  const [orderSearch, setOrderSearch] = useState("");

  // Filter payout requests based on search input
  const filteredPayoutRequests = payoutRequestsData.filter(
    (request) =>
      request.client.toLowerCase().includes(payoutSearch.toLowerCase()) ||
      request.amount.includes(payoutSearch) ||
      request.id.toString().includes(payoutSearch)
  );

  // Filter orders based on search input
  const filteredOrders = ordersData.filter(
    (order) =>
      order.client.toLowerCase().includes(orderSearch.toLowerCase()) ||
      order.earnings.includes(orderSearch) ||
      order.id.toLowerCase().includes(orderSearch)
  );

  return (
    <div
      className="payout-section dashboard mt-5  navGap"
     
    >
      <p className="CreateCampaign mt-4 mb-4">Payout Panel</p>
      {/* Summary Cards */}
      <div className="summary-cards">
        <SummaryCard title="Pending Payouts" value="₹ 15,000" icon={coin} />
        <SummaryCard title="Total Payouts" value="₹ 1,00,000" icon={pig} />
        <SummaryCard title="Rejected Payouts" value="₹ 2,000" icon={cross} />
      </div>

      {/* Payout Requests */}
      <div className="payout-requests">
        <h3>Payout Requests</h3>
        <input
          type="text"
          placeholder="Search Payout Requests"
          value={payoutSearch}
          onChange={(e) => setPayoutSearch(e.target.value)}
          className="search-input"
        />
        <table className="payout-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Client</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPayoutRequests.length > 0 ? (
              filteredPayoutRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{request.client}</td>
                  <td>{request.amount}</td>
                  <td>{request.status}</td>
                  <td>
                    {request.status === "Pending" ? (
                      <>
                        <button className="approve-btn">✔️ Approve</button>
                        <button className="reject-btn ms-2">❌ Reject</button>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No payout requests found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Order History */}
      <div className="order-history">
        <h3>Order History</h3>
        <input
          type="text"
          placeholder="Search Order History"
          value={orderSearch}
          onChange={(e) => setOrderSearch(e.target.value)}
          className="search-input"
        />
        <table className="order-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Client</th>
              <th>Earnings</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.client}</td>
                  <td>{order.earnings}</td>
                  <td>{order.date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No orders found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ title, value, icon }) => {
  return (
    <div className="summary-card">
      <img src={icon} className="icon" alt="" />

      <div className="details">
        <h4>{title}</h4>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default PayoutSection;