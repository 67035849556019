import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import logoImage from "../../images/Anlook.svg";
import Employees from "../../images/Employee.png";
import Employees1 from "../../images/Employee-green.png";
import pricing from "../../images/Pricing.png";
import pricing1 from "../../images/Pricing-green.png";
import Home from "../../images/AdminHome.png";
import Home1 from "../../images/Home-green.png";
import person from "../../images/Person IMG.svg"
import "./AdminDashboard.css";

const TopNav = () => {
  const [activeLink, setActiveLink] = useState("home");

  return (
    <nav className="top-nav sticky-top">
      <div className="logo">
        <img src={logoImage} alt="Anlook" style={{ height: "30px" }} />
      </div>

      <div className="link d-flex text-success ms-5">
        <Link
          to="/admin/home"
          style={{textDecoration:"none", color:"black"}}
          className={
            activeLink === "home"
              ? "top-nav-link-active cursor-pointer"
              : "top-nav-link cursor-pointer"
          }
          onClick={() => setActiveLink("home")}
        >
          <img className="me-1"src={activeLink == "home" ? Home1 : Home} alt="" />
          Home
        </Link>

        <Link
          to="/admin/employee"
          style={{textDecoration:"none", color:"black"}}
          className={
            activeLink === "employee"
              ? "top-nav-link-active cursor-pointer"
              : "top-nav-link cursor-pointer"
          }
          onClick={() => setActiveLink("employee")}
        >
          <img  className="me-1" src={activeLink == "employee" ? Employees1 : Employees} alt="" />
          Employee
        </Link>

        <Link
          to="/admin/pricing"
          style={{textDecoration:"none", color:"black"}}
          className={
            activeLink === "pricing"
              ? "top-nav-link-active cursor-pointer"
              : "top-nav-link cursor-pointer"
          }
          onClick={() => setActiveLink("pricing")}
        >
          <img className="me-1" src={activeLink == "pricing" ? pricing1 : pricing} alt="" />
          Pricing
        </Link>
      </div>

      <div className="order-2 order-md-3 dropdown-container d-flex">
        <Image
          src={person}
          alt="User"
          roundedCircle
          className="ms-3"
          style={{ cursor: "pointer" }}
        />
      </div>
    </nav>
  );
};

export default TopNav;

