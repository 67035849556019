import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import women from "../../images/Frame 1000011575.png";
import AnlookLogo from "../../images/Anlook 256 x 256 1.png";
import NoIndex from "../NoIndex";
import "./AdminDashboard.css";

const LoginPage = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [MobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [Otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [Step, setStep] = useState(1);
  const [OTPField, setOTPField] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Lerror, setLerror] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [User, setUser] = useState("no user");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function validateInputs() {
    if (MobileNumber.length != 10) {
      toast.error("Invalid Mobile number");
    }else if (password.length === 0) {
      toast.error("Please enter your password");
    } else if (Otp.length < 6) {
      toast.error("OTP must be at least 6 digits");
    } else {LoginCall();}
  }

  const LoginCall = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const BankData = {
        mobile: MobileNumber,
        password: password,
        step: Step,
        otp: Otp.join(""),
        latitude: latitude,
        longitude: longitude,
      };

      const response = await axios.post(
        "https://anlookuat.remittpe.com/api/Admin/login",
        BankData,
        config
      );
      if (
        response.data.responseCode === "00" &&
        response.data.responseMessage === "Login Successfully"
      ) {
        setUser(response.data.response);
        sessionStorage.setItem(
          "AdminUser",
          JSON.stringify(response.data.response)
        );
        // window.location.href = "http://localhost:3000/admin/home";
        window.location.href = "https://www.anlook.com/admin/home";

      } else if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);

        setUser(response.data.response);
        setStep(2);
        setOTPField(true);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("kewal error");
      console.error("Error updating Admin login:", error);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...Otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleResendOTP = () => {
    toast.info("Resending OTP...");
    // Implement the logic to resend OTP here
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setLerror(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  return (
    <div className="anlook-container">
      <NoIndex />
      <ToastContainer />
      <div className="anlook-card">
        <div className="anlook-form-section">
          <div className="anlook-form-content">
            <img src={AnlookLogo} className="anlook-logo" alt="Anlook Logo" />
            {Step === 1 ? (
              <>
                <h2 className="anlook-welcome-text">
                  Welcome to Anlook <strong>Admin</strong>
                </h2>
                <h1 className="anlook-title">
                  Get started with your phone number
                </h1>
                <form className="anlook-form">
                  <div className="anlook-input-group">
                    <select className="anlook-country-code">
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="tel"
                      className="anlook-input"
                      placeholder="Phone number"
                      value={MobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                  <div className="anlook-input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="anlook-input"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="anlook-show-password-container">
                    <input
                      type="checkbox"
                      id="showPassword"
                      checked={showPassword}
                      onChange={togglePasswordVisibility}
                      className="anlook-checkbox"
                    />
                    <label
                      htmlFor="showPassword"
                      className="anlook-show-password-label"
                    >
                      Show Password
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={()=>{validateInputs()}}
                    className="anlook-continue-button"
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="anlook-forget-password-button"
                  >
                    Forget password
                  </button>
                </form>
              </>
            ) : (
              <>
                <h2 className="anlook-welcome-text">
                  Welcome to Anlook <strong>Admin</strong>
                </h2>
                <h1 className="anlook-title">
                  Secure Your Access with a Quick OTP Verification!
                </h1>
                <form
                  className="anlook-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    LoginCall();
                  }}
                >
                  <div className="anlook-otp-input-container">
                    {Otp.map((data, index) => (
                      <input
                        className="anlook-otp-input"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleOtpChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    ))}
                  </div>
                  <button type="submit" className="anlook-continue-button">
                    Continue
                  </button>
                  <button
                    type="button"
                    onClick={handleResendOTP}
                    className="anlook-resend-button"
                  >
                    Re-send OTP
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="anlook-image-section">
        <img
          src={women}
          alt="Business woman using smartphone"
          className="anlook-image"
        />
      </div>
    </div>
  );
};

export default LoginPage;