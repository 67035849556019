import React from "react";
import { Helmet } from "react-helmet";
import LandingNav from "../Landing_Nav";
import group9 from "../../../images/LandingPageImage/web_hero_section_1x.webp";
import callImage from "../../../images/LandingPageImage/cta_img_1x.webp";

import { ToastContainer } from "react-toastify";
import signup from "../../../images/signup.svg";

import Integrations from "../../../images/Integrations.svg";
import Highscalibility from "../../../images/Highscalibility.svg";

import Chat22 from "../../../images/Chat22.svg";

import TickShield from "../../../images/TickShield.svg";

import CrossChannel from "../../../images/CrossChannel.svg";

import scroll from "../../../images/scroll.svg";

import team from "../../../images/team.svg";

import tick from "../../../images/tick.svg";
import LandingFooter from "../Landing_Footer";

import ChatBot from "./Chat_Bot";
import SecondSectionFeatures from "./Second_Features";
import TabSection from "./Tab_Section";
import BlogSection from "./Blog_section";
import FAQSection from "../FAQ_Section";

const Home = ({ onLoginClick }) => {

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntity: {
      "@type": "Home",
      name: "Anlook Landing Page",
      description:
        "Explore Anlook's WhatsApp automation features, integrations, and scalable solutions for businesses.",
      url: "https://www.anlook.com/",
      potentialAction: {
        "@type": "Action",
        name: "Talk to an Expert",
        target: "https://www.anlook.com/contact",
      },
    },
  };


  return (
    <div className=" ">
      <Helmet>
        <title>Anlook - WhatsApp Automation for Businesses</title>
        <link rel="preload" href={group9.src} as="image" />
        <meta
          name="description"
          content="Explore Anlook's WhatsApp automation features, integrations, and scalable solutions for businesses."
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <ChatBot />
      <LandingNav onLoginClick={onLoginClick} />
      <div className="gradientBackground padding-5-rem py-3">
        {/* First Section */}
        <div className="row col-12 py-lg-5 my-lg-5">
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <div>
              <div className="text-2-2 pt-3 ">
                <h1> A captivating platform for <span className="text-green-color">effortless marketing!</span> </h1>

              </div>
              <div className="pb-1 text-text-2 pt-4">
                We offer comprehensive solutions that can unlock the full
                potential of your WhatsApp & SMS business
              </div>
              <div className="py-1 text-text-2">
                • Unlimited user outreach with broadcasted promotional offers
              </div>

              <div className="py-1 text-text-2">
                • Use catalogs on WhatsApp to seamlessly market your products
                and services
              </div>

              <div className="py-1 text-text-2">
                • Automate messages effortlessly through integrations
              </div>

              <div className="py-1 text-text-2">
                • Provide exceptional customer support with multi-agent live
                chat capabilities
              </div>

              <div className="py-1 text-text-2">
                • Enhance engagement with 24/7 support and chatbot solutions
              </div>

              <div className="pt-4">
                <button
                  style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                  className="  btn  p-2 px-4 me-3 text-white"
                  onClick={onLoginClick}
                >
                 Get Started
                  <img loading="lazy" src={signup} className="ps-2" alt="signup" />
                </button>
                {/* <button
                  style={{
                    backgroundColor: "rgba(1, 195, 125, 0.05)",
                    color: "rgba(1, 195, 125, 1)",
                  }}
                  className="  btn  p-2 px-5 "
                  onClick={onLoginClick}
                >
                  Login
                </button> */}
              </div>
            </div>
          </div>
          <div className="  col-12  m-0 p-0 col-lg-6 d-flex justify-content-end align-items-center">
            <img loading="lazy"
              src={group9}
              alt="Anlook enhances customer trust"
              className="image-cover"
              
              priority
            />
          </div>
        </div>
      </div>

      {/* Second Section */}

      <SecondSectionFeatures />

      {/* Third Section */}
      <div className="d-flex justify-content-center bg-light-brown  py-5  ">
        <div className="row col-12 shadow bg-white border-redius-20 responsive-container">
          <div className="col-12 col-lg-7">
            <div className="p-4">
              <div className="text-5-2">Maximize your advertising ROI,</div>
              <div className="text-5-2">
                Transform clicks into captivating conversations!
              </div>

              <div className="py-2">
                Unleash Quality Leads, Nurture, and Interact through WhatsApp
                after Clicks!
              </div>

              <div className="pt-4">
                <button
                  className=" shadow btn bg-green-filter  p-2 px-4 me-5 text-white d-flex align-items-center"
                 
                >
                   {/* <Link to="/contact"> */}
                   Talk to an Expert
                   <img loading="lazy" src={signup} className="ps-2" alt="signup" />

                  {/* </Link> */}
             
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-12 d-flex justify-content-center align-items-center">
            <img loading="lazy" src={callImage} alt="group9" className="image-cover" />
          </div>
        </div>
      </div>

      {/* Forth Section */}
      <div className="d-flex justify-content-center bg-white padding-5-rem py-5 ">
        <div>
          <div className="text-center  text-2-2">
            Harmonize the experience, foster connections,{" "}
          </div>

          <div className="text-center py-2 text-2-2">
            and optimize with precision.
          </div>

          <div className="row col-12 py-0 py-lg-5 my-2 my-lg-5 d-flex justify-content-center">
            {/* 1 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2 ">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy" src={Chat22} alt="Chat22" height={30} className="w-100" />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Consolidated Conversational Profiles
                  </div>
                  <p className="text-center pt-2">
                    Gain a comprehensive overview of customer behavior,
                    accessing identity resolution, engagement data, and
                    marketing journeys seamlessly.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2" >
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={TickShield}
                        alt="TickShield"
                        height={30}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Maximize Performance
                  </div>
                  <p className="text-center pt-2">
                    Gain actionable insights into your engagement, conversions,
                    and campaign effectiveness.
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={CrossChannel}
                        alt="CrossChannel"
                        height={30}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Cross-Channel Coordination
                  </div>
                  <p className="text-center pt-2">
                    Expand engagement across WhatsApp, RCS, and SMS using
                    pre-approved templates for seamless scalability.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy" src={scroll} alt="scroll" height={30} className="w-100" />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Code-Free Journey Builder
                  </div>
                  <p className="text-center pt-2">
                    Craft conversational journeys across channels effortlessly,
                    with no coding required.
                  </p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={Highscalibility}
                        alt="Highscalibility"
                        height={30}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">High Scalability</div>
                  <p className="text-center pt-2">
                    Effortlessly send campaigns to numerous customers at once
                    and manage millions of events daily.
                  </p>
                </div>
              </div>
            </div>
            {/* 6 */}
            <div className="col-lg-4 col-12 ">
              <div className="  d-flex justify-content-center p-3 border-15-only border-green-color my-2">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <div className="bg-green p-2 border-redius-5 my-2 ">
                      <img loading="lazy"
                        src={Integrations}
                        alt="Integrations"
                        height={30}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="text-3-2 text-center ">
                    Integrated Connectivity
                  </div>
                  <p className="text-center pt-2">
                    Effortlessly link with mobile apps, support tools, and
                    online stores to access both historical and real-time data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fifth Section */}
      <div className="d-flex justify-content-center bg-light-brown  pb-5">
        <div className="row col-12">
          <div className="col-12 col-lg-6 bg-dark-green">
            <div className="padding-5-rem py-5">
              <div className="text-2-2 text-white pb-4 ">
                Reclaiming Abandoned Carts
              </div>
              <p>
                Did you know nearly 70% of users abandon their carts before
                completing a purchase? Don't let those potential sales slip
                away. Utilise automated reminders and exclusive offers to entice
                them back to complete their purchase.
              </p>
              <div className="pt-4">
                <button
                  className="bg-white text-success shadow btn  p-2 px-4 me-5 d-flex align-items-center"
                  // onClick={onLoginClick}
                >
                  Talk to an Expert
                  <img loading="lazy" src={signup} className="ps-2" alt="signup" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 bg-brown">

            <div className="padding-5-rem py-5">

              <div className="text-2-2  pb-4">Strategies for Cart Recovery</div>

              <p>

                Recover abandoned carts with automated email campaigns, special
                discounts, and personalised product recommendations. Use
                retargeting ads on social media and other websites to remind
                customers of their unfinished purchases.

              </p>

              <div className="pt-4">

                <button
                
                className="bg-white text-success shadow btn  p-2 px-4 me-5 d-flex align-items-center"
                
                // onClick={onLoginClick}
                
                >
                  Talk to an Expert
                  <img loading="lazy" src={signup} className="ps-2" alt="signup" />
                
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Six Section */}
      <TabSection />
      {/* Seven Section */}
      <div className="bg-pink d-flex justify-content-center  padding-5-rem py-5 ">
        <div>
          <div className="text-center text-dark text-4-2 ">
            Brands using our Digital Customer Service{" "}
          </div>

          <div className="text-center text-dark text-4-2 py-2 pb-5 ">
            Platform have seen
          </div>
          <div className="row col-12">
            <div className="col-6 col-lg-3">
              <div className="text-center text-dark text-4-2">40%</div>

              <div className="text-center py-2   text-dark">
              Increase in abandoned cart recovery

              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">50%</div>

              <div className="text-center py-2   text-dark">
              Increase in user acquisition

              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">60%</div>

              <div className="text-center py-2  text-dark ">
              Increase in user retention

              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center  text-4-2 text-dark">50%</div>

              <div className="text-center py-2  text-dark ">
              Increase in Brand awareness

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Eight Section */}
      <div className=" d-flex justify-content-center  padding-5-rem py-5 ">
        <div>
          <div className="text-center text-2-2 pb-5">
            More Reasons to Join Us{" "}
          </div>
          <div className="row col-12">
            <div className="col-12 col-lg-4">
              <div
                className=" p-3 border-1-px py-5 border-15-only border-green-color"
              >
                <div className=" d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center pb-2">
                      {" "}
                      <img loading="lazy" src={Chat22} alt="Chat22" height={40} className="pe-3" />
                    </div>
                    <p className="text-3-2 text-center ">
                      Bulk WhatsApp messages
                    </p>
                  </div>
                </div>
                <div className=" text-center">
                  Empower brands to thrive through bulk WhatsApp messages and
                  emails, boosting product visibility with extensive marketing
                  campaigns.
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 my-3 my-lg-0">
              <div
                className=" p-3 py-5 border-15-only border-green-color border-1-px"
              >
                <div className=" ">
                  <div className="d-flex justify-content-center pb-2">
                    {" "}
                    <img loading="lazy" src={team} alt="team" height={40} className="pe-3" />
                  </div>
                  <p className="text-3-2 text-center ">Team Chat inbox</p>
                </div>
                <div className=" text-center">
                  Collaborate with your team effortlessly through a unified chat
                  inbox. Streamline communication and seamlessly share
                  information across your team.
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div
                className=" p-3   border-1-px py-5 border-15-only border-green-color"
              >
                <div>
                  <div className="d-flex justify-content-center pb-2">
                    {" "}
                    <img loading="lazy" src={tick} alt="tick" height={40} className="pe-3" />
                  </div>
                  <p className="text-3-2 text-center ">
                    Green tick verification
                  </p>
                </div>
                <div className=" text-center">
                  Earn trust with a WhatsApp green tick verification next to
                  your brand name. This verification tick will enhance trust and
                  credibility among your audience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      
      {/* Nine Section */}
      
      <BlogSection />

      {/* Ten Section  */}
      
      <FAQSection />
      
      {/* Eleven Section Footer */}
      
      <LandingFooter />

      </div>

);

};
export default Home;