import {useState,useEffect} from 'react';
import axios from 'axios';
import ContectAdd from "./Contact_Add";
import eye from "../../images/View.svg";
import trash from "../../images/Delete.svg";
import Edit from "../../images/Edit.svg";
import { toast, ToastContainer } from "react-toastify";
import NoIndex from "../NoIndex";
import { Form, FormControl } from "react-bootstrap";

const ContactBook=({ userID })=>{
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audience, setAudience] = useState([]);
  const [selectedAudienceGroup, setSelectedAudienceGroup] = useState(null);
  const [filter, setFilter] = useState("contactbook");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://anlookuat.remittpe.com/api/Campaign/getContacts",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      if (result.data !== null) {
        setContacts(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const allAudienceApi = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };

      const requestBody = {
        userid: userID,
      };

      const response = await axios.post("https://anlookuat.remittpe.com/api/LeadManagement/getAudienceGroup", requestBody, config);

      if (response.data.responseCode === "00") {
        setAudience(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching audience:", error);
    }
  };

  useEffect(() => {
    allAudienceApi();
  }, []);

  // Filter function to apply searchTerm
  const filterData = (data) => {
    return data.filter(item => {
      const name = item.name || item.contact_name || "";
      const number = item.mobile || item.contact_number || "";
      return name.toLowerCase().includes(searchTerm.toLowerCase()) ||
             number.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };
  const displayedContacts = selectedAudienceGroup ? filterData(selectedAudienceGroup.members) : filterData(contacts);
    return(
        <>
        <div className="p-3">
        <>
        <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
      <div>
        <div
          className="p-4 bg-white "
          style={{
            height: "100vh",
            fontFamily: "Nunito,sans-serif",
          }}
        >
          <div className=" px-4  justify-content-center">
            {filter==="addnew" &&
            <>
         <div className='mb-2 m-0 p-0'>
    <button 
    onClick={()=>{
      setFilter("contactbook");
    }}
    className='blue-button'>Contact Book</button>
  </div>
          <div className='px-2'>
             <ContectAdd/>
             
             </div>
            
            </>}
           
          {filter==="contactbook" &&

          <>
            <div className=''>
          <Form className="d-flex align-items-center justify-content-between pb-4  ">
  <Form.Group className="d-flex align-items-center">
    <Form.Label className="mb-0 me-3 CreateCampaign" >
      {selectedAudienceGroup ? `Members of ${selectedAudienceGroup.audience_group_name}` : 'Contact Book'}
    </Form.Label>
  </Form.Group>
  <Form.Group className="d-flex align-items-center">
    <Form.Select
      className="me-3"
      style={{ width: '250px', borderRadius: '5px', borderColor: '#ddd' }}
      onChange={(e) => {
        const selectedId = e.target.value;
        const selectedGroup = audience.find((group) => group.id === selectedId);
        setSelectedAudienceGroup(selectedGroup);
      }}
    >
      <option value="">All Contact List</option>
      {audience.map((group, index) => (
        <option key={index} value={group.id}>
          {group.audience_group_name}
        </option>
      ))}
    </Form.Select>

    <FormControl
      type="text"
      placeholder="Search"
      className="bg-light"
      style={{
        width: '250px',
        borderRadius: '5px',
        borderColor: '#ddd',
        padding: '0.375rem 0.75rem',
      }}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <div className='ms-3'>
    <button 
    onClick={()=>{
      setFilter("addnew");
    }}
    className='blue-button'>Add Contact</button>
  </div>
  </Form.Group>

  
          </Form>
            {loading ? (
              <div className=" m-5 p-5 d-flex justify-content-center">
                <div className="loader "></div>
              </div>
            ) : (
              <>
              <div className='p-2 border-gray border-15-only' >
              <table className="table table-hover">
                <thead style={{ borderRadius: "10px" }}>
                  <tr className="text-center greenColour py-4" style={{ borderBottom: "1px solid #ccc" }}>
                    <th className="p-3" style={{ color: "#fff", backgroundColor: "rgba(1, 195, 125, 1)", borderRadius: "7px 0px 0px 7px" }}>S.No.</th>
                    <th className="p-3" style={{ color: "#fff", backgroundColor: "rgba(1, 195, 125, 1)" }}>Contact Name</th>
                    <th className="p-3" style={{ color: "#fff", backgroundColor: "rgba(1, 195, 125, 1)" }}>Contact Number</th>
                    <th className="p-3" style={{ color: "#fff", backgroundColor: "rgba(1, 195, 125, 1)", borderRadius: "0px 7px 7px 0px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedContacts.map((item, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #DDD", padding: "5px 0px" }}>
                      <td className="p-3 text-center">{index + 1}</td>
                      <td className="p-3 text-center">{item.name || item.contact_name || "N/A"}</td>
                      <td className="p-3 text-center">{item.mobile || item.contact_number || "N/A"}</td>
                      <td className="p-2 text-center">
                        <div className="d-flex justify-content-around">
                          <div className="cursor-pointer" title="Edit">
                            <img src={Edit} alt="Edit" />
                          </div>
                          <div className="cursor-pointer" title="Delete">
                            <img src={trash} alt="Delete" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
                <>
                  <div
                    class="modal fade"
                    id="exampleModal1"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >

                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1
                            class="modal-title fs-5 text-success"
                            id="exampleModalLabel"
                          >
                            {/* {ModalData} */}
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          Are you sure you want to delete this item? This action
                          cannot be undone.
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >

                            Cancel
                          </button>
                          <button
                            id="liveAlertBtn"
                            type="button"
                            class="btn btn-danger"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal2"
                            data-bs-dismiss="modal"
                            // onClick={() => handleDelete(ModalData)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //////////// */}
                  {/* <div className="py-1">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onNextPage={() => handleClick("next")}
                      onPrevPage={() => handleClick("prev")}
                      onPageClick={(e) => handlePageClick(e)}
                    />
                  </div> */}
                </>
              </>
            )}
            </div> 
          
          </>

}
        
          </div>
        </div>
      </div>
      {/* {pagination} */}
     
    </>
        </div>
        </>
    );
}

export default ContactBook;